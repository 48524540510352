import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { IPermissionModel } from "@app/features/Profile/domain/models/IProfileModel";
import { RootState } from "@app/config/store";
import { ROLE } from "@app/constants/enums";

export type RestrictedContextProps = {
  isAllowedTo: (roles: ROLE[]) => boolean;
};

export const RestrictedContext = React.createContext(
  {} as RestrictedContextProps
);

type RestrictedProviderProps = {
  children: ReactNode;
};

const RestrictedProvider = ({ children }: RestrictedProviderProps) => {
  const [permissions, setPermissions] = useState<IPermissionModel[]>([]);
  const { currentUser } = useSelector((state: RootState) => state.profile);
  const { currentClasse } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const isAllowedTo = (roles: ROLE[]) => {
    const hasPermission = permissions.find(
      (permission) =>
        (permission.classeId === currentClasse?.id ||
          permission.companyId === currentCompany?.id) &&
        roles.includes(permission.role)
    );
    if (hasPermission) return true;
    else return false;
  };

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser.permissions);
    }
  }, [currentUser]);

  const data = { isAllowedTo };

  return (
    <RestrictedContext.Provider value={data}>
      {children}
    </RestrictedContext.Provider>
  );
};

export default RestrictedProvider;
