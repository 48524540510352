import React from "react";

import {
  BrowserRouter,
  Route,
  Navigate,
  Routes,
  Outlet,
} from "react-router-dom";

import PageNotFound from "@app/components/pages/PageNotFound";

import Login from "@app/features/Login/view";
import SignUp from "@app/features/SignUp/view";
import ForgotPassword from "@app/features/ForgotPassword/view/ForgotPassword";
import ChangePassword from "@app/features/ForgotPassword/view/ChangePassword";
import VerificationCode from "@app/features/VerificationCode/view";

import CreateCompany from "@app/features/Company/view/Create";
import EditCompany from "@app/features/Company/view/Edit";
import ListCompanies from "@app/features/Company/view/List";

import ListResources from "@app/features/Resource/views/List";
import CreateResources from "@app/features/Resource/views/Create";
import EditResources from "@app/features/Resource/views/Edit";

import Dashboard from "@app/features/Dashboard/view";

import ListClasses from "@app/features/Classe/view/List";
import CreateClasse from "@app/features/Classe/view/Create";
import EditClasse from "@app/features/Classe/view/Edit";

import ListModule from "@app/features/Module/view/List";
import CreateModule from "@app/features/Module/view/Create";

import CreateLesson from "@app/features/Lesson/view/Create";
import EditLesson from "@app/features/Lesson/view/Edit";

import ListUsers from "@app/features/User/view/List";
import CreateUser from "@app/features/User/view/Create";
import EditUser from "@app/features/User/view/Edit";

import ListStudents from "@app/features/Student/view/List";
import CreateStudent from "@app/features/Student/view/Create";
import EditStudent from "@app/features/Student/view/Edit";

import EditProfile from "@app/features/Profile/view/Edit";

import Admin from "@app/components/templates/Admin";
import Configs from "@app/components/templates/Configs";

import Storage from "@app/config/storage";

const PrivateRoute = () => {
  const isAuthenticated = Storage.get("@SHARPII:accessToken");

  return isAuthenticated ? <Outlet /> : <Navigate replace to="/login" />;
};

const PublicRoute = () => {
  const isAuthenticated = Storage.get("@SHARPII:accessToken");

  return isAuthenticated ? <Navigate replace to="/admin" /> : <Outlet />;
};

const RoutesConfig = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/admin" element={<PrivateRoute />}>
        <Route path="/admin" element={<Admin />}>
          {/* Admin */}
          <Route path="/admin/dashboard" element={<Dashboard />} />

          <Route path="/admin/resources" element={<ListResources />} />
          <Route path="/admin/resources/create" element={<CreateResources />} />
          <Route path="/admin/resources/edit/:id" element={<EditResources />} />

          <Route path="/admin/companies" element={<ListCompanies />} />
          <Route path="/admin/companies/create" element={<CreateCompany />} />
          <Route path="/admin/companies/edit/:id" element={<EditCompany />} />

          <Route path="/admin/classes" element={<ListClasses />} />
          <Route path="/admin/classes/create" element={<CreateClasse />} />
          <Route path="/admin/classes/edit/:id" element={<EditClasse />} />

          <Route path="/admin/modules" element={<ListModule />} />
          <Route path="/admin/modules/create" element={<CreateModule />} />

          <Route
            path="/admin/modules/:id/lessons/create"
            element={<CreateLesson />}
          />
          <Route path="/admin/lessons/edit/:id" element={<EditLesson />} />

          <Route path="/admin/users" element={<ListUsers />} />
          <Route path="/admin/users/create" element={<CreateUser />} />
          <Route path="/admin/users/edit/:id" element={<EditUser />} />

          <Route path="/admin/students" element={<ListStudents />} />
          <Route path="/admin/students/create" element={<CreateStudent />} />
          <Route path="/admin/students/view/:id" element={<EditStudent />} />

          <Route path="/admin/profile" element={<EditProfile />} />

          <Route path="/admin/configs" element={<Configs />}>
            <Route path="/admin/configs/classes/:id" element={<EditClasse />} />
          </Route>
        </Route>
      </Route>
      <Route path="/" element={<PublicRoute />}>
        <Route index element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/verification-code" element={<VerificationCode />} />
      </Route>
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  </BrowserRouter>
);

export default RoutesConfig;
