import {
  ChangeEvent,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";

import { Box, Button, Divider, Grid, Paper, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { useLanguageRepository } from "@app/features/Language/data/languageRepository";
import { useFormResourceViewModel } from "@app/features/Resource/views/Form/formResourcesViewModel";

import languageService from "@app/services/language";

import { regex } from "@app/constants";
import { FiPlus, FiTrash } from "react-icons/fi";
import { useResourceRepository } from "../../data/resourceRepository";
import resourceService from "@app/services/resource";
import showNotification from "@app/components/molecules/Toast";

export type TYoutubeResourceForm = {
  name: string;
  url: string;
};

export type YoutubeResourcesFormRef = {
  validate: () => Promise<{ resources: TYoutubeResourceForm[] }>;
};

type ResourcesFormProps = {
  ref?: RefObject<YoutubeResourcesFormRef>;
  edit?: boolean;
  dataForm?: TYoutubeResourceForm;
  maxItems?: number;
};

const YoutubeResourcesForm = forwardRef<unknown, ResourcesFormProps>(
  ({ edit, dataForm, maxItems }: ResourcesFormProps, ref) => {
    const {
      register,
      formState: { errors },
      setValue,
      reset,
      watch,
      trigger,
      getValues,
    } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          resources: yup.array().of(
            yup.object().shape({
              name: yup.string().required("Coloque um nome para o video"),
              url: yup.string().url("Coloque uma url válida"),
            })
          ),
        })
      ),
      defaultValues: {
        resources: [] as TYoutubeResourceForm[],
      },
      mode: "onSubmit",
    });
    const [resources] = watch(["resources"]);

    const languageRepository = useLanguageRepository(languageService);
    const resourceRepository = useResourceRepository(resourceService);

    const { getYoutubeInfo } = useFormResourceViewModel(
      languageRepository,
      resourceRepository
    );

    const handleGetYoutubeId = useCallback(
      (index: number) => {
        var match = resources?.[index].url.match(regex.youtubeUrl);
        if (match && match[2].length === 11) {
          return match[2];
        }
      },
      [resources]
    );

    const handleAddVideo = useCallback(() => {
      if (maxItems && resources.length >= maxItems) {
        showNotification(
          `Só é possivél adicionar ${maxItems} video`,
          "WARNING"
        );
      } else {
        resources.push({ name: "", url: "" });
        setValue("resources", resources);
      }
    }, [resources, setValue, maxItems]);

    const handleRemoveVideo = useCallback(
      (index: number) => {
        resources.splice(index, 1);
        setValue("resources", resources);
      },
      [resources, setValue]
    );

    const handleChangeVideoUrl = useCallback(
      async (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
      ) => {
        const value = event.target.value;
        setValue(`resources.${index}.url`, value);

        const info = await getYoutubeInfo(value);
        if (info) {
          setValue(`resources.${index}.name`, info?.title);
        }
      },
      [setValue, getYoutubeInfo]
    );

    const validate = useCallback(async () => {
      const value = await trigger();
      if (value) {
        return getValues();
      }
    }, [trigger, getValues]);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    useEffect(() => {
      if (!edit) {
        setValue("resources", []);
      }
    }, [edit, setValue]);

    useEffect(() => {
      if (dataForm) {
        const { name, url } = dataForm;

        reset({
          resources: [{ name, url }],
        });
      }
    }, [dataForm, reset]);

    return (
      <>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            justifyContent="center"
            flexDirection="column"
            spacing={2}
          >
            {resources.map((resource, index, array) => (
              <Paper sx={{ p: 1, mb: 2 }}>
                <Grid
                  key={index}
                  container
                  item
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  {resource.url && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <iframe
                        width="350"
                        height="155"
                        src={`https://www.youtube.com/embed/${handleGetYoutubeId(
                          index
                        )}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{
                          borderRadius: 5,
                          borderWidth: 0,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      id="url"
                      label="Url de video youtube"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      required
                      value={resources[index].url}
                      defaultValue={resources[index].url}
                      onChange={(evt) => handleChangeVideoUrl(evt, index)}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.resources?.[index]?.url}
                      helperText={
                        !!errors.resources?.[index]?.url &&
                        errors.resources?.[index]?.url?.message
                      }
                    />
                  </Grid>
                  {resource.url && (
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="name"
                        label="Nome do video"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        error={!!errors.resources?.[index]?.name}
                        helperText={
                          !!errors.resources?.[index]?.name &&
                          errors.resources?.[index]?.name?.message
                        }
                        {...register(`resources.${index}.name`)}
                      />
                    </Grid>
                  )}
                </Grid>
                <Button
                  variant="text"
                  color="error"
                  startIcon={<FiTrash />}
                  onClick={() => handleRemoveVideo(index)}
                >
                  Remover Video
                </Button>
                {array.length > 1 && index < array.length - 1 && (
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                )}
              </Paper>
            ))}
          </Grid>
          {!edit && (
            <Button
              variant="text"
              startIcon={<FiPlus />}
              onClick={handleAddVideo}
            >
              Adicionar Video
            </Button>
          )}
        </Box>
      </>
    );
  }
);

export default YoutubeResourcesForm;
