export const regex = {
  youtubeUrl: /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/,
};

export type TAssetPath =
  | "RESOURCE"
  | "CLASSE"
  | "COMPANY"
  | "PROFILE"
  | "LESSON"
  | "QUESTION";
