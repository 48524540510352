import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6700c1",
    },
    secondary: {
      main: "#2096f3",
    },
  },
  // typography: {
  //   fontFamily: ["Montserrat", "sans-serif"].join(","),
  //   body1: {
  //     fontWeight: 500
  //   },
  //   subtitle1: {
  //     fontWeight: 600,
  //     fontSize: 24
  //   },
  //   h4: {
  //     fontWeight: 800,
  //     fontSize: 30
  //   },
  //   caption: {
  //     fontWeight: 600,
  //     fontSize: 16
  //   },
  //   body2: {
  //     fontWeight: 500,
  //     fontSize: 14
  //   }
  // }
});

export default theme;
