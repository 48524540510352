import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import LoadingLock from "@app/components/molecules/LoadingLock";
import Copyright from "@app/components/molecules/CopyRight";

import { authService } from "@app/services/authentication";
import {
  TLoginViewModel,
  useLoginViewModel,
} from "@app/features/Login/view/loginViewModel";
import useLoginRepository from "@app/features/Login/data/loginRepository";

import useStyles from "./styles";
import { images } from "@app/assets";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email("Error").required("Error"),
        password: yup.string().required("Error"),
      })
    ),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onSubmit",
  });

  const loginRepository = useLoginRepository(authService);

  const handleShowPassword = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );

  const { login, isLoading } = useLoginViewModel(loginRepository);

  const onSubmit = (data: TLoginViewModel) => {
    login(data);
  };

  const classes = useStyles();

  return (
    <Grid container component="main" sx={classes?.root}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <LoadingLock isLoading={isLoading} />
        <Box sx={classes.paper}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form
            style={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={"Email"}
              autoComplete="email"
              autoFocus
              error={!!errors.email}
              helperText={!!errors.email && errors.email.message}
              {...register("email")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={"Senha"}
              type={showPassword ? "text" : "password"}
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
              error={!!errors.password}
              helperText={!!errors.password && errors.password.message}
              {...register("password")}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              {"Fazer Login"}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  {"Esqueci minha senha"}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Não tem conta? Crie sua conta agora"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </Box>
      </Grid>
      <Grid item xs={false} sm={4} md={7} sx={classes.imagePattern}>
        <Avatar src={images.logoBackground} sx={classes.image} />
      </Grid>
    </Grid>
  );
};

export default Login;
