import React, { useState, useEffect, useMemo, useCallback } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { useSelector } from "react-redux";
import {
  FiChevronDown,
  FiChevronUp,
  FiPlus,
  FiPieChart,
  FiUsers,
  FiUser,
  FiArchive,
  FiSettings,
} from "react-icons/fi";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";

import { useCompanyRepository } from "@app/features/Company/data/companyRepository";
import companyService from "@app/services/company";
import profileService from "@app/services/profile";
import { useListCompanyViewModel } from "@app/features/Company/view/List/listCompanyViewModel";

import AppToolBar from "@app/components/organisms/AppToolBar";
import { Caption } from "@app/components/atoms/Text";

import FormChangeTemporaryPasswordDialog from "@app/features/User/view/ChangeForcePassword";
import { OptionsDrawerType } from "@app/constants/drawerOptions";

import { RootState, dispatchStore } from "@app/config/store";

import { setCurrentClasseAction } from "@app/features/Classe/data/classeActions";
import { useProfileRepository } from "@app/features/Profile/data/profileRepository";
import { useEditProfileViewModel } from "@app/features/Profile/view/Edit/editProfileViewModel";
import { ROLE, USER_STATUS } from "@app/constants/enums";
import useRestricted from "@app/hooks/useRestricted";
import appInfo from "../../../../package.json";
import useStyles from "./styles";
import AxiosInterceptorProvider from "@app/contexts/axios-interceptor/AxiosInterceptorProvider";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const { isAllowedTo } = useRestricted();
  const theme = useTheme();
  const location = useLocation();

  const [showDrawer, setShowDrawer] = useState(true);
  const [open, setOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState(-1);
  const [selectedCollapse, setSelectedCollapse] = useState("");
  const [selectedOptionSubRoute, setSelectedOptionSubRoute] = useState(-1);

  const companyRepository = useCompanyRepository(companyService);
  const profileRepository = useProfileRepository(profileService);
  const { getListCompanies } = useListCompanyViewModel(companyRepository);
  const { getProfile } = useEditProfileViewModel(profileRepository);

  const { classes, currentClasse } = useSelector(
    (state: RootState) => state.classe
  );

  const { companies, currentCompany } = useSelector(
    (state: RootState) => state.company
  );

  const { currentUser } = useSelector((state: RootState) => state.profile);

  const navigate = useNavigate();

  const DRAWER_OPTIONS: OptionsDrawerType[] = useMemo(() => {
    return [
      {
        name: "Dashboard",
        icon: <FiPieChart />,
        pathRoute: "/admin/dashboard",
        permissions: [ROLE.OWNER, ROLE.ADMIN, ROLE.TEACHER],
      },
      {
        name: "Módulos",
        icon: <FiArchive />,
        pathRoute: "/admin/modules",
        permissions: [ROLE.OWNER, ROLE.ADMIN, ROLE.TEACHER],
      },
      {
        name: "Colaboradores",
        icon: <FiUser />,
        pathRoute: "/admin/users",
        permissions: [ROLE.OWNER, ROLE.ADMIN],
      },
      {
        name: "Alunos",
        icon: <FiUsers />,
        pathRoute: "/admin/students",
        permissions: [ROLE.OWNER, ROLE.ADMIN, ROLE.TEACHER],
      },
      {
        name: "Configurações",
        icon: <FiSettings />,
        pathRoute: `/admin/configs/classes/${currentClasse?.id}`,
        permissions: [ROLE.OWNER, ROLE.ADMIN],
      },
    ];
  }, [currentClasse?.id]);

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelectOption = useCallback(
    (index: number, drawerItem: OptionsDrawerType) => {
      setSelectedOptionSubRoute(-1);
      setSelectedOption(index);
      if (drawerItem.pathRoute) {
        navigate(drawerItem.pathRoute);
      } else {
        if (selectedCollapse === drawerItem.name) {
          setSelectedCollapse("");
        } else setSelectedCollapse(drawerItem.name);
      }
    },
    [navigate, selectedCollapse]
  );

  const handleSelectOptionSubRoute = useCallback(
    (index: number, subRoute?: string) => {
      setSelectedOptionSubRoute(index);
      setSelectedOption(-1);
      if (subRoute) {
        navigate(subRoute);
      }
    },
    [navigate]
  );

  const getCollapseState = useCallback(
    (drawerItem: OptionsDrawerType) => {
      if (selectedCollapse === drawerItem.name) return <FiChevronUp />;
      else return <FiChevronDown />;
    },
    [selectedCollapse]
  );

  const handleChangeCurrentClasse = useCallback(
    (event: SelectChangeEvent) => {
      const value = Number(event.target.value);
      const classe = classes.find((c) => c.id === value);
      if (classe) {
        dispatchStore(setCurrentClasseAction(classe));
      }
    },
    [classes]
  );

  const handleClickAddClasse = useCallback(() => {
    navigate("/admin/classes/create");
  }, [navigate]);

  // MARK: - effects

  useEffect(() => {
    if (
      location.pathname === "/admin/companies" ||
      location.pathname.includes("/admin/companies/edit") ||
      location.pathname === "/admin/companies/create" ||
      location.pathname === "/admin"
    ) {
      setShowDrawer(false);
    } else {
      setShowDrawer(true);
    }
  }, [location]);

  useEffect(() => {
    if (!currentCompany && !location.pathname.includes("/admin/companies")) {
      navigate("/admin/companies");
    }
  }, [currentCompany, location, navigate]);

  useEffect(() => {
    if (location.pathname.includes("/admin/companies")) {
      getListCompanies();
    }
  }, [getListCompanies, location.pathname]);

  useEffect(() => {
    if (!currentUser) {
      getProfile();
    }
  }, [getProfile, currentUser]);

  // MARK: - memos

  const openToolBarDrawer = useMemo(
    () =>
      open &&
      location.pathname !== "/admin/companies" &&
      !location.pathname.includes("/admin/companies/edit") &&
      location.pathname !== "/admin/companies/create",
    [location.pathname, open]
  );

  const primaryItem = useMemo(
    () => ({
      color: theme.palette.primary.main,
      size: "25px",
    }),
    [theme.palette.primary.main]
  );

  const styles = useStyles();

  return (
    <AxiosInterceptorProvider>
      <Box sx={{ display: "flex" }}>
        <FormChangeTemporaryPasswordDialog
          open={currentUser?.status === USER_STATUS.FORCE_CHANGE_PASSWORD}
        />
        <CssBaseline />
        <AppToolBar
          open={openToolBarDrawer}
          profilePhoto={currentUser?.photo}
          appToolbarOptions={[
            {
              title: "Empresas",
              path: "/admin/companies",
              key: "COMPANY",
              show:
                location.pathname !== "/admin/companies" &&
                companies.length > 1,
            },
            {
              title: "Minhas Turmas",
              path: "/admin/classes",
              key: "CLASSE",
              show: location.pathname !== "/admin/companies",
            },
            {
              title: "Biblioteca",
              path: "/admin/resources",
              key: "FILES",
              show: location.pathname !== "/admin/companies",
            },
          ]}
          handleDrawerOpen={handleDrawerOpen}
          shouldRenderDrawer={showDrawer}
        />
        {showDrawer && (
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {open && (
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  secondaryAction={
                    isAllowedTo([ROLE.OWNER, ROLE.ADMIN]) ? (
                      <IconButton
                        edge="end"
                        aria-label="add-classe"
                        onClick={handleClickAddClasse}
                      >
                        <FiPlus />
                      </IconButton>
                    ) : (
                      false
                    )
                  }
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Turmas
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={String(currentClasse?.id ?? "-1")}
                        label="Turmas"
                        onChange={handleChangeCurrentClasse}
                      >
                        <MenuItem value="-1">{"Selecione uma turma"}</MenuItem>
                        {classes
                          .filter((c) => c.userClasseStatus)
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </ListItemButton>
                  <Divider />
                </ListItem>
              )}
              {DRAWER_OPTIONS.filter((item) =>
                isAllowedTo(item.permissions)
              ).map((drawerItem, index) => (
                <div key={drawerItem.name}>
                  <ListItem
                    key={drawerItem.pathRoute}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      selected={
                        index === selectedOption &&
                        location.pathname.includes(drawerItem.pathRoute || "")
                      }
                      disabled={!currentClasse}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => handleSelectOption(index, drawerItem)}
                    >
                      <>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <IconContext.Provider value={primaryItem}>
                            {drawerItem.icon}
                          </IconContext.Provider>
                        </ListItemIcon>

                        <ListItemText
                          primary={drawerItem.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                        {!!drawerItem.subList?.length &&
                          getCollapseState(drawerItem)}
                      </>
                    </ListItemButton>
                  </ListItem>

                  {drawerItem.subList?.map((subItem, indexSubRoute) => (
                    <Collapse
                      key={subItem.pathRoute}
                      in={selectedCollapse === drawerItem.name}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent:
                              selectedCollapse === drawerItem.name
                                ? "initial"
                                : "center",
                            px: 2.5,
                          }}
                          selected={
                            selectedCollapse === drawerItem.name &&
                            indexSubRoute === selectedOptionSubRoute
                          }
                          onClick={() =>
                            handleSelectOptionSubRoute(
                              indexSubRoute,
                              subItem.pathRoute
                            )
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr:
                                selectedCollapse === drawerItem.name
                                  ? 3
                                  : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <IconContext.Provider value={primaryItem}>
                              {subItem.icon}
                            </IconContext.Provider>
                          </ListItemIcon>
                          <ListItemText primary={subItem.name} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))}
                </div>
              ))}
              {open && (
                <Box
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    width: 240,
                  }}
                >
                  <Box
                    sx={{ display: "flex", flex: 1, justifyContent: "center" }}
                  >
                    <Caption>Sharpii: {appInfo.version}</Caption>
                  </Box>
                </Box>
              )}
            </List>
          </Drawer>
        )}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Container maxWidth="xl" style={styles.container}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </AxiosInterceptorProvider>
  );
}
