import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";

import UploadAsset from "@app/components/molecules/UploadAsset";
import { TUploadPayload } from "@app/components/atoms/Upload";

export type TProfileForm = {
  name: string;
  email: string;
  document?: string;
  photo?: string;
};

type UserFormProps = {
  onValidateSuccess: (data: TProfileForm) => void;
  footerActions: ReactNode;
  dataForm?: TProfileForm;
};

const ProfileForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
}: UserFormProps) => {
  const [imageProfile, setImageProfile] = useState(dataForm?.photo);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        photo: yup.string().optional(),
        name: yup.string().required("Preencha o campo nome."),
        email: yup
          .string()
          .required("Preencha o campo email.")
          .email("Email inválido."),
        document: yup.string().required("Preencha o campo documento."),
      })
    ),
    defaultValues: {
      photo: "",
      name: "",
      email: "",
      document: "",
    },
    mode: "onSubmit",
  });

  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      setValue("photo", data.fileKey);
      setImageProfile(data.url);
    },
    [setValue]
  );

  const handleRemoveUpload = useCallback(() => {
    setValue("photo", "");
    setImageProfile(undefined);
  }, [setValue]);

  const onSubmit = (data: TProfileForm) => {
    onValidateSuccess(data);
  };

  const handleChangeDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("document", event.currentTarget.value);
  };

  useEffect(() => {
    reset(dataForm);
    setImageProfile(dataForm?.photo);
  }, [dataForm, reset]);

  const [document] = watch(["document"]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            container
            xs={12}
            sm={12}
            sx={{ marginTop: 5 }}
            justifyContent="center"
            alignItems="center"
          >
            <UploadAsset
              accept={{ image: true }}
              destination="PROFILE"
              label="Escolha uma foto de perfil"
              url={imageProfile}
              error={!!errors.photo}
              errorMessage={errors.photo?.message}
              onRemove={handleRemoveUpload}
              onUploadSuccess={handleUploadSuccess}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="name"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              label="Nome"
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="email"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Email"
              {...register("email")}
              error={!!errors.email}
              helperText={!!errors.email && errors.email.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              mask="999.999.999-99"
              value={document}
              onChange={handleChangeDocument}
            >
              <TextField
                id="document"
                fullWidth
                margin="normal"
                variant="outlined"
                label="Documento"
                InputLabelProps={{ shrink: true }}
                error={!!errors.document}
                helperText={!!errors.document && errors.document.message}
              />
            </InputMask>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default ProfileForm;
