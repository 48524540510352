import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";
import { TCreateLessonViewModel } from "@app/features/Lesson/view/Create/createLessonViewModel";

const createLessonUseCase = async (
  repository: Pick<ILessonRepository, "createLesson">,
  data: TCreateLessonViewModel
) => {
  await repository.createLesson(data);
};

export default createLessonUseCase;
