import {
  AMOUNT_GENERATE_QUESTIONS,
  CLASSE_VISIBILITY,
  MEDIA_PAGE_TYPE,
  MEDIA_QUESTION_TYPE,
  QUESTION_TYPE,
  RESOURCE_TYPE,
  ROLE,
  USER_CLASSE_STATUS,
} from "../enums";

export const ROWS_PER_PAGE_OPTIONS = {
  MIN: 10,
  DEFAULT: 20,
  MAX: 30,
};

export const MEDIA_PAGE_TYPE_OPTIONS = [
  { label: "Aula sem media", value: MEDIA_PAGE_TYPE.NONE },
  { label: "Youtube video", value: MEDIA_PAGE_TYPE.EMBED },
  { label: "Arquivo de aúdio", value: MEDIA_PAGE_TYPE.AUDIO },
];

export const MEDIA_QUESTION_TYPE_OPTIONS = [
  { label: "Pergunta sem recurso", value: MEDIA_QUESTION_TYPE.NONE },
  { label: "Vídeo do youtube", value: MEDIA_QUESTION_TYPE.EMBED },
  { label: "Imagem", value: MEDIA_QUESTION_TYPE.IMAGE },
  { label: "Aúdio", value: MEDIA_QUESTION_TYPE.AUDIO },
];

export const QUESTION_TYPE_OPTIONS = [
  { label: "Texto", value: QUESTION_TYPE.TEXT },
  { label: "Multipla escolha", value: QUESTION_TYPE.MULTIPLE_CHOICE },
  { label: "Ordenação de frase/palavra", value: QUESTION_TYPE.ORDERING },
  { label: "Pronúncia", value: QUESTION_TYPE.SPEAKING },
];
export const PERMISSION_TYPE_OPTIONS = [
  { label: ROLE.OWNER, value: 1 },
  { label: ROLE.ADMIN, value: 2 },
  { label: ROLE.TEACHER, value: 3 },
  { label: ROLE.STUDENT, value: 4 },
];

export const USER_CLASSE_STATUS_OPTIONS = [
  { label: "Ativo", value: USER_CLASSE_STATUS.ACTIVE },
  { label: "Inativo", value: USER_CLASSE_STATUS.INACTIVE },
  { label: "Convidado", value: USER_CLASSE_STATUS.INVITED },
];

export const BOOLEAN_OPTIONS = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

export const CLASSE_VISIBILITY_OPTIONS = [
  { label: "Privada", value: CLASSE_VISIBILITY.PRIVATE },
  { label: "Pública", value: CLASSE_VISIBILITY.PUBLIC },
];

export const RESOURCE_TYPES_OPTIONS = [
  { label: "Aúdio", value: RESOURCE_TYPE.AUDIO },
  { label: "Arquivo", value: RESOURCE_TYPE.DOC },
  { label: "Video do youtube", value: RESOURCE_TYPE.EMBED },
  { label: "Imagem", value: RESOURCE_TYPE.IMAGE },
];

export const LANGUAGE_OPTIONS = [
  { label: "Aúdio", value: RESOURCE_TYPE.AUDIO },
  { label: "Arquivo", value: RESOURCE_TYPE.DOC },
  { label: "Video do youtube", value: RESOURCE_TYPE.EMBED },
  { label: "Imagem", value: RESOURCE_TYPE.IMAGE },
];

export const AMOUNT_GENERATE_QUESTIONS_OPTIONS = [
  { label: "10", value: AMOUNT_GENERATE_QUESTIONS.TEN },
  { label: "15", value: AMOUNT_GENERATE_QUESTIONS.FIFTEEN },
  { label: "20", value: AMOUNT_GENERATE_QUESTIONS.TWENTY },
];
