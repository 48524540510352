import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import FooterActions from "@app/components/molecules/FooterActions";
import TableHeadSelection, {
  HeadCell,
} from "@app/components/molecules/TableHeadSelection";

import IUserModel from "@app/features/User/domain/models/IUserModel";
import { TUserToInviteViewModel } from "@app/features/Classe/view/Create/createClassViewModel";

import { PERMISSION_TYPE_OPTIONS } from "@app/constants/optionsSelect";
import { ROLE } from "@app/constants/enums";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    label: "Nome",
  },
  {
    id: "email",
    numeric: true,
    label: "Email",
  },
  {
    id: "permission",
    numeric: true,
    label: "Selecione a permissão do colaborador para esta turma",
  },
];

type ListAllUsersFromCompanyProps = {
  data: IUserModel[];
  onBack: () => void;
  onPressSave: (users: TUserToInviteViewModel[]) => void;
};

const ListAllUsersFromCompany = ({
  data,
  onBack,
  onPressSave,
}: ListAllUsersFromCompanyProps) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [usersToInvite, setUsersToInvite] = useState<IUserModel[]>([]);

  const { currentUser } = useSelector((state: RootState) => state.profile);

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = data.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      const ownerUser = data
        .filter((user) => user.role.name === ROLE.OWNER)
        .map((u) => u.id);
      setSelected(ownerUser);
    },
    [data]
  );

  const handleChangePermission = useCallback(
    (event: SelectChangeEvent<number>, index: number) => {
      setUsersToInvite((users) => {
        let roleId = Number(event.target.value);
        users[index].role.id = roleId;
        users[index].role.name = PERMISSION_TYPE_OPTIONS[roleId - 1].label;
        return [...users];
      });
    },
    []
  );

  const handlePressSave = useCallback(() => {
    const users = usersToInvite
      .filter((u) => selected.includes(u.id))
      .map((u): TUserToInviteViewModel => {
        const { role, id } = u;
        return {
          userId: id,
          roleName: role.name,
        };
      });
    onPressSave(users);
  }, [usersToInvite, selected, onPressSave]);

  const handleClick = useCallback(
    (id: number) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    },
    [selected]
  );

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  useEffect(() => {
    setUsersToInvite(data);
    const ownerUser = data.find((i) => i.role.name === ROLE.OWNER);
    if (ownerUser) {
      setSelected((current) => [...current, ownerUser.id]);
    }
    if (currentUser) {
      setSelected((current) => [...current, currentUser.id]);
    }
  }, [setSelected, data, currentUser]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Colaboradores
          </Typography>
        </Toolbar>
        <TableContainer sx={{ maxHeight: "62vh" }}>
          <Table aria-labelledby="tableTitle">
            <TableHeadSelection
              headCells={headCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
            />
            <TableBody>
              {usersToInvite.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const isOwner = row.role.name === ROLE.OWNER;
                const isCurrentUser = row.id === currentUser?.id;

                const availablePermissions = PERMISSION_TYPE_OPTIONS.filter(
                  (i) => {
                    if (row.role.name !== ROLE.OWNER) {
                      return i.label !== ROLE.OWNER && i.label !== ROLE.STUDENT;
                    }
                    return i;
                  }
                );

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={() => handleClick(row.id)}
                        color="primary"
                        disabled={isOwner || isCurrentUser}
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">
                      <FormControl variant="standard" size="small">
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={row.role.id}
                          label="Permissão"
                          disabled={isOwner}
                          onChange={(evt) => handleChangePermission(evt, index)}
                        >
                          {availablePermissions.map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <FooterActions
        cancelLabel="Voltar"
        onCancel={onBack}
        onSave={handlePressSave}
      />
    </Box>
  );
};

export default ListAllUsersFromCompany;
