import { IProfileRepository } from "@app/features/Profile/data/profileRepository";
import IProfileModel from "@app/features/Profile/domain/models/IProfileModel";
import IProfileEntity from "@app/features/Profile/data/IProfileEntity";
import {
  setCurrentUserAction,
  setLoadingCurrentUserAction,
} from "@app/features/Profile/data/profileActions";
import { dispatchStore } from "@app/config/store";

const getProfileUseCase = async (
  repository: Pick<IProfileRepository, "getProfile">
): Promise<void> => {
  dispatchStore(setLoadingCurrentUserAction(true));
  const userEntity = await repository.getProfile();

  const dataDomain = mapToDomain(userEntity);
  dispatchStore(setCurrentUserAction(dataDomain));
};

const mapToDomain = (data: IProfileEntity): IProfileModel => {
  const {
    document,
    email,
    id,
    name,
    photo,
    isFirstLogin,
    status,
    permissions,
  } = data;

  return {
    document,
    email,
    id,
    name,
    photo,
    isFirstLogin,
    status,
    permissions,
  };
};

export default getProfileUseCase;
