import { IDashboardRepository } from "@app/features/Dashboard/data/dashboardRepository";
import IDashboardEntity from "@app/features/Dashboard/data/IDashboardEntity";
import IDashboardModel from "@app/features/Dashboard/domain/models/IDashboardModel";

const getDashboardUseCase = async (
  repository: Pick<IDashboardRepository, "getDashboard">,
  classeId: number
) => {
  const data = await repository.getDashboard(classeId);
  const domain = mapToDomain(data);
  return domain;
};

const mapToDomain = (entity: IDashboardEntity): IDashboardModel => {
  const { classeAccessCount, newUserInMonth, studentsCount } = entity;

  return { classeAccessCount, newUserInMonth, studentsCount };
};

export default getDashboardUseCase;
