type EnviromentTypes = "production" | "staging" | "debug";

interface Enviroment {
  name: EnviromentTypes;
  baseURL: string;
}

type Enviroments = {
  production: Enviroment;
  staging: Enviroment;
  debug: Enviroment;
};

type DOT_ENV = {
  REACT_APP_API_PRODUCTION: string;
  REACT_APP_API_STAGING: string;
  REACT_APP_ENV: string;
  REACT_APP_UNSPLASH_ACCESS_KEY: string;
};

const secretKeys = process.env as unknown as DOT_ENV;

const enviromentOptions: Enviroments = {
  production: {
    name: "production",
    baseURL: "https://api.sharpii.com/api",
  },
  staging: {
    name: "staging",
    baseURL: "https://api.staging.sharpii.com/api",
  },
  debug: {
    name: "debug",
    baseURL: "http://localhost:3333/api",
  },
};

const setupEnviroment = () => {
  const { production, staging, debug } = enviromentOptions;
  const { REACT_APP_ENV } = secretKeys;

  switch (REACT_APP_ENV) {
    case "production":
      return production;

    case "staging":
      return staging;

    default:
      return debug;
  }
};

export default setupEnviroment();
