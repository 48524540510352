import { useCallback } from "react";

import ILanguageEntity from "@app/features/Language/data/ILanguageEntity";

import { ILanguageService } from "@app/services/language";

export interface ILanguageRepository {
  getAvailableLanguages(): Promise<ILanguageEntity[]>;
}

const useLanguageRepository = (
  languageService: ILanguageService
): ILanguageRepository => {
  const getAvailableLanguages = useCallback(async (): Promise<
    ILanguageEntity[]
  > => {
    const data = await languageService.getAvailableLanguages();
    return data;
  }, [languageService]);

  return {
    getAvailableLanguages,
  };
};

export { useLanguageRepository };
