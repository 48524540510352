import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

const createModuleUseCase = async (
  repository: Pick<ILessonRepository, "removeLesson">,
  classeId: number,
  lessonId: number
) => {
  await repository.removeLesson(classeId, lessonId);
};

export default createModuleUseCase;
