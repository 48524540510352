import React from "react";
import useRestricted from "@app/hooks/useRestricted";
import { ROLE } from "@app/constants/enums";

type RestrictedProps = {
  to: ROLE[];
  classeId?: number;
  companyId?: number;
  children: JSX.Element;
};

const Restricted = ({ to, children }: RestrictedProps) => {
  const { isAllowedTo } = useRestricted();

  if (isAllowedTo(to)) {
    return <>{children}</>;
  }

  return null;
};

export default Restricted;
