import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

import Upload, {
  UploadRef,
  TUploadPayload,
} from "@app/components/atoms/Upload";
import { TAcceptedFileType } from "@app/components/atoms/FileInput";
import { TAssetPath } from "@app/constants";
import AvatarForm from "@app/components/atoms/AvatarForm";

type UploadAssetProps = {
  label: string;
  accept: TAcceptedFileType;
  destination: TAssetPath;
  url?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  extension?: string;
  onRemove: () => void;
  onUploadSuccess: (data: TUploadPayload) => void;
};

const UploadAsset = ({
  label,
  accept,
  destination,
  url,
  disabled,
  error,
  errorMessage,
  extension,
  onUploadSuccess,
  onRemove,
}: UploadAssetProps) => {
  const uploadRef = useRef<UploadRef>(null);

  const [extensionValue, setExtensionValue] = useState(extension);

  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      onUploadSuccess(data);
    },
    [onUploadSuccess]
  );

  const handleClearField = useCallback(async () => {
    if (url) {
      await uploadRef.current?.clearField(url);
    }
    onRemove();
  }, [onRemove, url]);

  const checkExtensionAudio = useCallback(() => {
    return (
      extensionValue === "wa" ||
      extensionValue === "mp4" ||
      extensionValue === "mp3" ||
      extensionValue === "mpeg" ||
      accept.audio === true
    );
  }, [extensionValue, accept]);

  const checkExtensionImage = useCallback(() => {
    return (
      extensionValue === "png" ||
      extensionValue === "jpg" ||
      extensionValue === "jpeg" ||
      accept.image === true
    );
  }, [extensionValue, accept]);

  useEffect(() => {
    setExtensionValue(extension);
  }, [extension]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {!!url && checkExtensionAudio() && (
        <Box sx={{ mb: 2 }}>
          <audio controls>
            <source src={url} />
            Your browser does not support the audio element.
          </audio>
        </Box>
      )}
      {!!url && checkExtensionImage() && <AvatarForm imageSrc={url} />}
      <Box sx={{ display: !url || accept.all === true ? "initial" : "none" }}>
        <Upload
          ref={uploadRef}
          disabled={disabled}
          destination={destination}
          accept={accept}
          error={error}
          errorMessage={errorMessage}
          label={label}
          onUploadSuccess={handleUploadSuccess}
        />
      </Box>
      {url && (
        <IconButton onClick={handleClearField}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default UploadAsset;
