import { useCallback, useState } from "react";

import createCompanyUseCase from "@app/features/Company/domain/use-cases/createCompanyUseCase";

import { ICompanyRepository } from "@app/features/Company/data/companyRepository";
import { TCreateClasseViewModel } from "@app/features/Classe/view/Create/createClassViewModel";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { useNavigate } from "react-router-dom";

export type TCreateCompanyViewModel = {
  companyImage: string;
  companyName: string;
  tradingName: string;
  document: string;
  country: string;
  state: string;
  city: string;
  address: string;
  addressNumber: string;
  zipcode: string;
  phone: string;
  classe: TCreateClasseViewModel;
};

const useCreateCompanyViewModel = (repository: ICompanyRepository) => {
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const createCompany = useCallback(
    async (data: TCreateCompanyViewModel) => {
      try {
        setLoading(true);

        await createCompanyUseCase(
          {
            createCompany: repository.createCompany,
          },
          data
        );
        setLoading(false);
        showNotification("Instituição criada com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.createCompany, navigate]
  );

  return { isLoading, createCompany };
};

export { useCreateCompanyViewModel };
