import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

const deleteClasseUseCase = async (
  repository: Pick<IClasseRepository, "deleteClasse">,
  classeId: number
): Promise<void> => {
  await repository.deleteClasse(classeId);
};

export default deleteClasseUseCase;
