import React, { ReactNode } from "react";

import StandardHeader, {
  StandardHeaderProps,
} from "@app/components/molecules/StandardHeader";

type StandardTemplateProps = {
  children: ReactNode;
} & StandardHeaderProps;

const StandardTemplate = ({
  children,
  title,
  primaryButton,
  helperText,
  secondaryButton,
  showBackButton,
  buttonOptions,
}: StandardTemplateProps) => (
  <>
    <StandardHeader
      title={title}
      helperText={helperText}
      primaryButton={{
        ...primaryButton,
      }}
      secondaryButton={{ ...secondaryButton }}
      buttonOptions={buttonOptions}
      showBackButton={showBackButton}
    />
    {children}
  </>
);

export default StandardTemplate;
