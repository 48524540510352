import React, {
  useEffect,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Grid, TextField } from "@mui/material";

export type GenericFormRef = {
  validate: () => Promise<TGenericForm>;
};

export type TGenericForm = {
  answer: string;
};

type GenericFormProps = {
  ref?: RefObject<GenericFormRef>;
  dataForm?: TGenericForm;
  edit?: boolean;
};

const GenericForm = forwardRef<unknown, GenericFormProps>(
  ({ dataForm, edit }: GenericFormProps, ref) => {
    const {
      register,
      formState: { errors },
      reset,
      trigger,
      getValues,
    } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          answer: yup.string().required("Preencha o campo resposta."),
        })
      ),
      defaultValues: {
        answer: "",
      },
    });

    const validate = useCallback(async () => {
      const value = await trigger();
      if (value) {
        return getValues();
      }
    }, [trigger, getValues]);

    useEffect(() => {
      // if (edit) {
      reset(dataForm);
      // }
    }, [dataForm, reset, edit]);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    return (
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            id="answer"
            label={"Resposta"}
            fullWidth
            margin="normal"
            variant="standard"
            required
            onClick={(evt) => evt.stopPropagation()}
            InputLabelProps={{ shrink: true }}
            error={!!errors.answer}
            helperText={
              (!!errors.answer && errors.answer.message) ||
              "Para aceitar mais de uma resposta separe as palavras/frases usando ';'.Exexmplo: car;bike;sunglasses"
            }
            {...register("answer")}
          />
        </Grid>
      </Grid>
    );
  }
);

export default GenericForm;
