import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UploadAsset from "@app/components/molecules/UploadAsset";

import Grid from "@mui/material/Grid";
import { Box, Paper } from "@mui/material";
import { TUploadPayload } from "@app/components/atoms/Upload";

export type TEditResourceForm = {
  name: string;
  url: string;
  fileKey: string;
  size: number;
};

type CompanyFormProps = {
  footerActions: ReactNode;
  onValidateSuccess: (data: TEditResourceForm) => void;
  dataForm?: TEditResourceForm;
};

const EditResourceForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
}: CompanyFormProps) => {
  const [assetUrl, setAssetUrl] = useState<string>();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Faça o upload de um arquivo"),
        url: yup.string().required("Faça o upload de um arquivo"),
        size: yup.number().required("Faça o upload de um arquivo"),
        fileKey: yup.string().required("Faça o upload de um arquivo"),
      })
    ),
    defaultValues: {
      name: "",
      url: "",
      fileKey: "",
      size: NaN,
    },
    mode: "onSubmit",
  });

  const [name] = watch(["name"]);

  const onSubmit = useCallback(
    (data: TEditResourceForm) => {
      onValidateSuccess(data);
    },
    [onValidateSuccess]
  );

  const handleRemove = useCallback(() => {
    reset({
      fileKey: undefined,
      name: undefined,
      size: undefined,
      url: undefined,
    });
    setAssetUrl(undefined);
  }, [reset]);

  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      setValue("size", data.size);
      setValue("url", data.url);
      setValue("name", data.name);
      setValue("fileKey", data.fileKey);
      setAssetUrl(data.url);
    },
    [setValue]
  );

  useEffect(() => {
    reset(dataForm);
    setAssetUrl(dataForm?.url);
  }, [dataForm, reset]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <UploadAsset
              label={name || "Substituir arquivo"}
              accept={{ all: true }}
              disabled={!!assetUrl}
              url={assetUrl}
              onRemove={handleRemove}
              onUploadSuccess={handleUploadSuccess}
              destination="RESOURCE"
              error={!!errors.url}
              errorMessage={errors.url?.message}
            />
          </Grid>
        </Grid>
        <Box>{footerActions}</Box>
      </Paper>
    </form>
  );
};

export default EditResourceForm;
