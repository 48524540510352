import { Theme } from "@mui/material/styles";
import themeInstance from "@app/config/theme";
import { images } from "@app/assets";

const styles = (theme: Theme = themeInstance) => ({
  root: {
    height: "100vh",
  },
  image: {
    width: "50vh",
    height: "50vh",
  },
  imagePattern: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${images.pattern})`,
    backgroundRepeat: "repeat",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default styles;
