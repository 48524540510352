import { toast, ToastOptions } from "react-toastify";
import { TNOTIFICATION_TYPE } from "@app/constants/notification";

const showNotification = (
  message: string,
  type: TNOTIFICATION_TYPE,
  hideAfterMiliseconds?: number
) => {
  const notificationConfig: ToastOptions = {
    position: "top-right",
    autoClose: hideAfterMiliseconds ?? 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  switch (type) {
    case "SUCCESS":
      toast.success(message, notificationConfig);
      break;

    case "ERROR":
      toast.error(message, notificationConfig);
      break;

    case "WARNING":
      toast.warning(message, notificationConfig);
      break;

    default:
      toast.info(message, notificationConfig);
      break;
  }
};

export default showNotification;
