import { IUserRepository } from "@app/features/User/data/userRepository";
import { TEditUserViewModel } from "@app/features/User/view/Edit/editUserViewModel";

const updateUserStatusUseCase = async (
  repository: Pick<IUserRepository, "updateUserStatus">,
  data: TEditUserViewModel
): Promise<void> => {
  await repository.updateUserStatus(data);
};

export default updateUserStatusUseCase;
