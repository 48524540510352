import api from "@app/config/api";

import { TEditProfileRemoteData } from "@app/services/profile/remote-types/TEditProfileRemoteData";
import IProfileEntity from "@app/features/Profile/data/IProfileEntity";

import API_SERVICES from "../constants";

export interface IProfileService {
  getProfileService: () => Promise<IProfileEntity>;
  editProfileService: (data: TEditProfileRemoteData) => Promise<IProfileEntity>;
}

const getProfileService = async (): Promise<IProfileEntity> =>
  api.get(API_SERVICES.USER_SERVICES.GET_PROFILE).then((res) => res.data);

const editProfileService = async (
  data: TEditProfileRemoteData
): Promise<IProfileEntity> =>
  api
    .put(API_SERVICES.USER_SERVICES.EDIT_PROFILE, data)
    .then((res) => res.data);

const profileService: IProfileService = {
  getProfileService,
  editProfileService,
};

export default profileService;
