import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material";
import { store } from "@app/config/store";

import RestrictedProvider from "@app/contexts/restricted/RestrictedContext";
import App from "@app/App";
import theme from "@app/config/theme";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <RestrictedProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RestrictedProvider>
  </Provider>
);
