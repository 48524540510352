import { useCallback, useState } from "react";

import getResourceUseCase from "@app/features/Resource/domain/use-cases/getResourceUseCase";
import editResourceUseCase from "@app/features/Resource/domain/use-cases/editResourceUseCase";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import { useNavigate } from "react-router-dom";

export type TEditRosourceViewModel = {
  id: number;
  name: string;
  url: string;
  fileKey?: string;
  size?: number;
};

const useEditResourceViewModel = (repository: IResourceRepository) => {
  const [resource, setResources] = useState<IResourceModel>();
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const editResource = useCallback(
    async (data: TEditRosourceViewModel) => {
      try {
        setLoading(true);

        await editResourceUseCase(
          {
            editResource: repository.editResource,
          },
          data
        );
        setLoading(false);
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editResource, navigate]
  );

  const getResource = useCallback(
    async (resourceId: number) => {
      try {
        setLoading(true);

        const data = await getResourceUseCase(
          {
            getResource: repository.getResource,
          },
          resourceId
        );
        setResources(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getResource]
  );

  return { isLoading, resource, getResource, editResource };
};

export { useEditResourceViewModel };
