import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { QRCode } from "react-qrcode-logo";
import { v4 as uuidv4 } from "uuid";

import SelectUseForm from "@app/components/organisms/SelectUseForm";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Card, IconButton, MenuItem, Stack, Tooltip } from "@mui/material";

import { useLanguageRepository } from "@app/features/Language/data/languageRepository";
import { useFormClasseViewModel } from "@app/features/Classe/view/Form/formClasseViewModel";
import languageService from "@app/services/language";

import { CLASSE_VISIBILITY } from "@app/constants/enums";
import { CLASSE_VISIBILITY_OPTIONS } from "@app/constants/optionsSelect";
import {
  FiClipboard,
  FiDownload,
  FiHelpCircle,
  FiRefreshCw,
} from "react-icons/fi";
import UploadAsset from "@app/components/molecules/UploadAsset";
import { TUploadPayload } from "@app/components/atoms/Upload";

export type TFormClasse = {
  name: string;
  description: string;
  image: string;
  visibility: CLASSE_VISIBILITY;
  languageId: number;
  entryCode?: string;
  primaryColor?: string;
};

type ClasseFormProps = {
  onValidateSuccess: (data: TFormClasse) => void;
  footerActions: ReactNode;
  dataForm?: TFormClasse;
};

const ClasseForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
}: ClasseFormProps) => {
  const [image, setImage] = useState(dataForm?.image);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("").max(50, ""),
        description: yup.string().optional(),
        image: yup.string().nullable(),
        languageId: yup.string().required(""),
        entryCode: yup.string().optional(),
        primaryColor: yup.string().optional(),
        visibility: yup
          .mixed()
          .oneOf([CLASSE_VISIBILITY.PRIVATE, CLASSE_VISIBILITY.PUBLIC]),
      })
    ),
    defaultValues: {
      name: "",
      description: "",
      image: "",
      entryCode: "",
      languageId: NaN,
      primaryColor: "",
      visibility: CLASSE_VISIBILITY.PRIVATE,
    },
    mode: "onSubmit",
  });

  const languageRepository = useLanguageRepository(languageService);
  const { getAvailableLanguages, languages } =
    useFormClasseViewModel(languageRepository);

  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      setValue("image", data.fileKey);
      setImage(data.url);
    },
    [setValue]
  );

  const handleRemoveUpload = useCallback(() => {
    setValue("image", "");
    setImage(undefined);
  }, [setValue]);

  const handleDownloadCode = useCallback(() => {
    const canvas: any = document.getElementById("qr_code_classe");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${getValues("name")}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [getValues]);

  const handleGenerateNewCode = useCallback(() => {
    setValue("entryCode", uuidv4());
  }, [setValue]);

  const handleSetValueClipboard = useCallback(() => {
    navigator.clipboard.writeText(getValues("entryCode"));
  }, [getValues]);

  const [visibility, entryCode] = watch(["visibility", "entryCode"]);

  useEffect(() => {
    getAvailableLanguages();
  }, [getAvailableLanguages]);

  useEffect(() => {
    if (visibility === CLASSE_VISIBILITY.PUBLIC && !entryCode) {
      handleGenerateNewCode();
    }
  }, [visibility, handleGenerateNewCode, entryCode]);

  const onSubmit = useCallback(
    (data: TFormClasse) => {
      onValidateSuccess(data);
    },
    [onValidateSuccess]
  );

  useEffect(() => {
    reset(dataForm);
    setImage(dataForm?.image);
  }, [dataForm, reset]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid
          container
          xs={visibility === CLASSE_VISIBILITY.PRIVATE ? 12 : 6}
          sm={visibility === CLASSE_VISIBILITY.PRIVATE ? 12 : 6}
          sx={{ marginTop: 5 }}
          justifyContent="center"
          alignItems="center"
        >
          <UploadAsset
            accept={{ image: true }}
            destination="CLASSE"
            label="Escolha uma imagem para sua turma"
            url={image}
            error={!!errors.image}
            errorMessage={errors.image?.message}
            onRemove={handleRemoveUpload}
            onUploadSuccess={handleUploadSuccess}
          />
        </Grid>
        {visibility === CLASSE_VISIBILITY.PUBLIC && (
          <Grid
            container
            xs={6}
            sm={6}
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="column" alignItems="center" sx={{ mb: 1 }}>
              <Card>
                <QRCode
                  id="qr_code_classe"
                  size={120}
                  qrStyle="dots"
                  ecLevel="H"
                  value={entryCode}
                />
              </Card>
              <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
                <Tooltip title="Download QRCode">
                  <IconButton color="primary" onClick={handleDownloadCode}>
                    <FiDownload />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Gerar novo QRCode">
                  <IconButton color="primary" onClick={handleGenerateNewCode}>
                    <FiRefreshCw />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copiar código da turma">
                  <IconButton color="primary" onClick={handleSetValueClipboard}>
                    <FiClipboard />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12} sm={4}>
          <TextField
            id="name"
            label={"Nome da turma"}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            InputLabelProps={{ shrink: true }}
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
            {...register("name")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectUseForm
            error={!!errors.languageId}
            helperText={errors.languageId?.message}
            control={control}
            label="Idioma da turma"
            defaultValue={String(getValues("languageId"))}
            {...register("languageId")}
          >
            <MenuItem value="">Selecione o idioma da turma</MenuItem>
            {languages.map((language) => (
              <MenuItem value={language.id} key={language.key}>
                {language.name}
              </MenuItem>
            ))}
          </SelectUseForm>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <SelectUseForm
              error={!!errors.visibility}
              helperText={errors.visibility?.message}
              control={control}
              label="Visibilidade da turma"
              defaultValue={String(getValues("visibility"))}
              {...register("visibility")}
            >
              <MenuItem value="">Selecione a visibilidade da turma</MenuItem>
              {CLASSE_VISIBILITY_OPTIONS.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </SelectUseForm>
            <Tooltip title="Turmas públicas permitem que os estudantes possam ingressar apenas escaneando o QRCode ou digitando o código no app. Já ingresso em turmas privadas apenas colaboradores podem adicionar os alunos">
              <IconButton color="primary">
                <FiHelpCircle />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        {visibility === CLASSE_VISIBILITY.PRIVATE && (
          <Grid item xs={12} sm={12}>
            <TextField
              id="brandColor"
              type={"color"}
              fullWidth
              margin="normal"
              variant="outlined"
              label="Cor primaria do app"
              {...register("primaryColor")}
              error={!!errors.primaryColor}
              helperText={
                (!!errors.primaryColor && errors.primaryColor.message) ||
                "A cor primária da turma pode ser alterada a qualquer momento."
              }
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <TextField
            id="description"
            label={"Pequena descrição sobre a turma"}
            fullWidth
            InputProps={{
              rows: 4,
              multiline: true,
              inputComponent: "textarea",
            }}
            rows={4}
            multiline
            variant="outlined"
            margin="normal"
            InputLabelProps={{ shrink: true }}
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
            {...register("description")}
          />
        </Grid>
      </Grid>
      {footerActions}
    </form>
  );
};

export default ClasseForm;
