import { useCallback, useState } from "react";

import getLessonUseCase from "@app/features/Lesson/domain/use-cases/getLessonUseCase";
import editLessonUseCase from "@app/features/Lesson/domain/use-cases/editLessonUseCase";
import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

import ILessonModel from "@app/features/Lesson/domain/models/ILessonModel";
import IQuestionModel from "@app/features/Question/domain/models/IQuestionModel";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { useNavigate } from "react-router-dom";
import { RELEASE_DATE_TYPE } from "@app/constants/enums";

export type TEditLessonViewModel = {
  id: number;
  image?: string;
  name?: string;
  description?: string;
  resourceMedia?: IResourceModel;
  complementaryMaterials?: IResourceModel[];
  publish?: boolean;
  minScore?: boolean;
  minScoreValue?: number;
  canRetake?: boolean;
  showResult?: boolean;
  randomQuestions?: boolean;
  randomAnswers?: boolean;
  releaseDateType?: RELEASE_DATE_TYPE;
  releaseAfterDay?: number;
  releaseDate?: string;
  reoder?: {
    source: number;
    destination: number;
  };
};

const useEditLessonViewModel = (repository: ILessonRepository) => {
  const [lesson, setLesson] = useState<ILessonModel>();
  const [questions, setQuestions] = useState<IQuestionModel[]>([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getLesson = useCallback(
    async (lessonId: number) => {
      try {
        setLoading(true);

        const data = await getLessonUseCase(
          {
            getLesson: repository.getLesson,
          },
          lessonId
        );
        setLoading(false);
        setLesson(data.lesson);
        setQuestions(data.questions);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getLesson]
  );

  const editLesson = useCallback(
    async (
      classeId: number,
      data: TEditLessonViewModel,
      shouldNavigateBack: boolean = true
    ) => {
      try {
        setLoading(true);

        await editLessonUseCase(
          {
            editLesson: repository.editLesson,
          },
          data
        );
        setLoading(false);
        showNotification("Aula alterada com sucesso", "SUCCESS");
        if (shouldNavigateBack) {
          navigate(-1);
        }
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editLesson, navigate]
  );

  return { isLoading, lesson, questions, getLesson, editLesson };
};

export { useEditLessonViewModel };
