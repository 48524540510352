import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IUserRepository } from "@app/features/User/data/userRepository";
import { changePasswordUseCase } from "@app/features/ForgotPassword/domain/useCases/changePasswordUseCase";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

export type TChangePasswordViewModel = {
  password: string;
  token: string;
};

const useChangePasswordViewModel = (repository: IUserRepository) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const changePassword = useCallback(
    async (data: TChangePasswordViewModel) => {
      try {
        setLoading(true);
        await changePasswordUseCase(
          { changePassword: repository.changePassword },
          data
        );
        setLoading(false);
        navigate("/login");
        showNotification("Sua senha foi alterada com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.changePassword, navigate]
  );

  return {
    changePassword,
    loading,
  };
};

export { useChangePasswordViewModel };
