import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";

export const GET_CLASSES = "GET_CLASSES";
export const LOADING_CLASSES = "LOADING_CLASSES";
export const SET_CURRENT_CLASSE = "SET_CURRENT_CLASSE";

export interface IClasseStore {
  classes: IClasseModel[];
  loading: boolean;
  currentClasse: IClasseModel | null;
}

interface AllClassesAction {
  type: typeof GET_CLASSES;
  payload: IClasseModel[];
}

interface LoadingAllClassesAction {
  type: typeof LOADING_CLASSES;
  payload: boolean;
}

interface SetCurrentClassesAction {
  type: typeof SET_CURRENT_CLASSE;
  payload: IClasseModel;
}

export type ClasseActionTypes =
  | AllClassesAction
  | LoadingAllClassesAction
  | SetCurrentClassesAction;
