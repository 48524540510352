import { ICompanyRepository } from "@app/features/Company/data/companyRepository";

import IUserEntity from "@app/features/User/data/IUserEntity";
import IUserModel from "@app/features/User/domain/models/IUserModel";

const listUsersFromCompanyUseCase = async (
  repository: Pick<ICompanyRepository, "listAllUsersFromCompany">,
  companyId: number
) => {
  const users = await repository.listAllUsersFromCompany(companyId);
  const dataDomain = mapUsersToDomain(users);

  return dataDomain;
};

const mapUsersToDomain = (data: IUserEntity[]): IUserModel[] => {
  const dataDomain = data.map((item): IUserModel => {
    const { email, id, name, role, status, userStatusClasse, photo } = item;

    return {
      email,
      id,
      name,
      role,
      status,
      userStatusClasse,
      photo,
    };
  });

  return dataDomain;
};

export default listUsersFromCompanyUseCase;
