import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import IYoutubeInfoModel from "@app/features/Resource/domain/models/IYoutubeInfoModel";
import IYoutubeInfoEntity from "@app/features/Resource/data/IYoutubeInfoEntity";

const getYoutubeInfoUseCase = async (
  repository: Pick<IResourceRepository, "getYoutubeInfo">,
  videourl: string
): Promise<IYoutubeInfoModel> => {
  const resourcesEntity = await repository.getYoutubeInfo(videourl);

  const dataDomain = mapToDomain(resourcesEntity);

  return dataDomain;
};

const mapToDomain = (resource: IYoutubeInfoEntity): IYoutubeInfoModel => {
  const {
    author_name,
    author_url,
    height,
    html,
    provider_name,
    provider_url,
    thumbnail_height,
    thumbnail_url,
    thumbnail_width,
    title,
    type,
    url,
    version,
    width,
  } = resource;

  return {
    authorName: author_name,
    authorUrl: author_url,
    height,
    html,
    providerName: provider_name,
    providerUrl: provider_url,
    thumbnailHeight: thumbnail_height,
    thumbnailUrl: thumbnail_url,
    thumbnailWidth: thumbnail_width,
    title,
    type,
    url,
    version,
    width,
  };
};

export default getYoutubeInfoUseCase;
