import IModulesEntity from "@app/features/Module/data/IModuleEntity";
import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";

import { TCreateModuleRemoteData } from "@app/services/module/remote-types/TCreateModuleRemoteData";
import { TEditModuleRemoteData } from "@app/services/module/remote-types/TEditModuleRemoteData";

import api from "@app/config/api";
import API_SERVICES from "@app/services/constants";

export interface IModuleService {
  createModuleService: (data: TCreateModuleRemoteData) => Promise<void>;
  getModulesService: (classeId: number) => Promise<IModulesEntity[]>;
  getLessonsFromModule: (moduleId: number) => Promise<ILessonEntity[]>;
  editModuleService: (data: TEditModuleRemoteData) => Promise<void>;
  deleteModuleService: (data: TEditModuleRemoteData) => Promise<void>;
}

const createModuleService = async (
  data: TCreateModuleRemoteData
): Promise<void> =>
  api.post(API_SERVICES.MODULE_SERVICES.CREATE, data).then((res) => res.data);

const getModulesService = async (classeId: number): Promise<IModulesEntity[]> =>
  api
    .get(API_SERVICES.MODULE_SERVICES.LIST, { headers: { classeId } })
    .then((res) => res.data);

const editModuleService = async (data: TEditModuleRemoteData): Promise<void> =>
  api
    .put(API_SERVICES.MODULE_SERVICES.EDIT(data.id), data)
    .then((res) => res.data);

const getLessonsFromModule = async (
  moduleId: number
): Promise<ILessonEntity[]> =>
  api
    .get(API_SERVICES.MODULE_SERVICES.GET_LESSONS_FROM_MODULE(moduleId))
    .then((res) => res.data);

const deleteModuleService = async (
  data: TEditModuleRemoteData
): Promise<void> =>
  api
    .delete(API_SERVICES.MODULE_SERVICES.DELETE(data.id))
    .then((res) => res.data);

const moduleService: IModuleService = {
  createModuleService,
  getModulesService,
  editModuleService,
  getLessonsFromModule,
  deleteModuleService,
};

export default moduleService;
