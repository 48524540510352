import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import IResourceEntity from "@app/features/Resource/data/IResourceEntity";

const getResourceUseCase = async (
  repository: Pick<IResourceRepository, "getResource">,
  resourceId: number
): Promise<IResourceModel> => {
  const resourcesEntity = await repository.getResource(resourceId);

  const dataDomain = mapToDomain(resourcesEntity);

  return dataDomain;
};

const mapToDomain = (resource: IResourceEntity): IResourceModel => {
  const { fileKey, id, name, size, type, url, extension, languageId } =
    resource;

  return {
    fileKey,
    id,
    name,
    size,
    type,
    url,
    extension,
    languageId,
  };
};

export default getResourceUseCase;
