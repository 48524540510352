import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import editClasseUseCase from "@app/features/Classe/domain/use-cases/editClasseUseCase";
import getClasseUseCase from "@app/features/Classe/domain/use-cases/getClasseUseCase";
import listClassesUseCase from "@app/features/Classe/domain/use-cases/listClassesUseCase";

import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

export type TEditClasseViewModel = {
  id: number;
  name?: string;
  description?: string;
  image?: string;
  languageId?: number;
  primaryColor?: string;
};

const useEditClassViewModel = (repository: IClasseRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [classe, setClasse] = useState<IClasseModel>();

  const navigate = useNavigate();

  const editClasse = useCallback(
    async (data: TEditClasseViewModel, companyId: number) => {
      try {
        setLoading(true);
        await editClasseUseCase(
          {
            editClasse: repository.editClasse,
          },
          data
        );
        await listClassesUseCase(
          { listClasses: repository.listClasses },
          companyId
        );
        setLoading(false);
        showNotification("Turma altarada com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editClasse, repository.listClasses, navigate]
  );

  const getClasse = useCallback(
    async (classeId: number) => {
      try {
        setLoading(true);
        const data = await getClasseUseCase(
          {
            getClasse: repository.getClasse,
          },
          classeId
        );
        setClasse(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getClasse]
  );

  return { classe, isLoading, editClasse, getClasse };
};

export { useEditClassViewModel };
