import React, {
  ChangeEvent,
  RefObject,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";

export type FileInputRef = {
  clearField: () => void;
  openWindow: () => void;
  uploadFile: (fileData: File) => void;
};

export type TAcceptedFileType = {
  image?: boolean;
  audio?: boolean;
  file?: boolean;
  all?: boolean;
};

type FileUploadProps = {
  ref?: RefObject<FileInputRef>;
  multiple: boolean;
  accept?: TAcceptedFileType;
  onSelectFiles: (files: FileList) => void;
};

const FileInput = forwardRef<unknown, FileUploadProps>(
  (
    { multiple, accept = { all: true }, onSelectFiles }: FileUploadProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const getFileTypes = () => {
      let result = "";

      if (accept?.all) {
        return "image/jpeg, image/png, image/jpg, application/pdf, audio/mpeg, audio/mp3";
      }
      if (accept?.image) {
        result = "image/jpeg, image/png, image/jpg,";
      }
      if (accept?.audio) {
        result += "audio/mp3, audio/mpeg,";
      }
      if (accept?.file) {
        result += "application/pdf";
      }

      return result;
    };

    const clearField = useCallback(() => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }, []);

    const openWindow = useCallback(() => {
      inputRef.current?.click();
    }, []);

    const handleFileChange = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        const filesData = e.target.files;
        if (filesData) {
          onSelectFiles(filesData);
        }
      },
      [onSelectFiles]
    );

    useImperativeHandle(ref, () => ({ clearField, openWindow }), [
      clearField,
      openWindow,
    ]);

    return (
      <>
        <div
          className="upload__image-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
          }}
        >
          <input
            ref={inputRef}
            type="file"
            accept={getFileTypes()}
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple={multiple}
          />
        </div>
      </>
    );
  }
);

export default FileInput;
