import React, { ReactNode, useCallback, useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { MenuItem, Paper } from "@mui/material";
import { Box } from "@mui/system";

import { useRoleRepository } from "@app/features/Role/data/roleRepository";
import { useFormUserViewModel } from "@app/features/User/view/Form/formUserViewModel";
import roleService from "@app/services/role";

import SelectUseForm from "@app/components/organisms/SelectUseForm";
import { ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";
import { USER_CLASSE_STATUS_OPTIONS } from "@app/constants/optionsSelect";
import AvatarForm from "@app/components/atoms/AvatarForm";

export type TUserForm = {
  name: string;
  email: string;
  document?: string;
  photo?: string;
  roleName: ROLE;
  userClasseStatus: USER_CLASSE_STATUS;
};

type UserFormProps = {
  onValidateSuccess: (data: TUserForm) => void;
  footerActions: ReactNode;
  dataForm?: TUserForm;
  edit?: boolean;
};

const UserForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
  edit,
}: UserFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Preencha o campo nome"),
        email: yup
          .string()
          .required("Preencha o campo email")
          .email("Email inválido"),
        roleName: yup
          .mixed()
          .oneOf([ROLE.OWNER, ROLE.ADMIN, ROLE.STUDENT, ROLE.TEACHER])
          .required("Selecione o papel do usuário"),
        userClasseStatus: yup.mixed().when({
          is: edit,
          then: yup
            .mixed()
            .oneOf([
              USER_CLASSE_STATUS.ACTIVE,
              USER_CLASSE_STATUS.INACTIVE,
              USER_CLASSE_STATUS.INVITED,
            ]),
          otherwise: yup.mixed().optional(),
        }),
      })
    ),
    defaultValues: {
      name: "",
      email: "",
      roleName: "" as ROLE,
      userClasseStatus: "" as USER_CLASSE_STATUS,
    },
    mode: "onSubmit",
  });

  // MARK: - viewModels

  const roleRepository = useRoleRepository(roleService);
  const { getRoles, roles } = useFormUserViewModel(roleRepository);

  // MARK: - useCallback

  const onSubmit = useCallback(
    (data: TUserForm) => {
      onValidateSuccess(data);
    },
    [onValidateSuccess]
  );

  // MARK: - memos

  const rolesFiltered = useMemo(() => {
    if (edit && dataForm?.roleName === ROLE.OWNER) {
      return roles.filter((r) => r.name !== ROLE.STUDENT);
    } else {
      return roles.filter(
        (r) => r.name !== ROLE.OWNER && r.name !== ROLE.STUDENT
      );
    }
  }, [roles, edit, dataForm?.roleName]);

  const userStatusFiltered = useMemo(
    () =>
      USER_CLASSE_STATUS_OPTIONS.filter(
        (uc) => uc.value !== USER_CLASSE_STATUS.INVITED
      ),
    []
  );

  // MARK: - useEffect

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    reset(dataForm);
  }, [dataForm, reset]);

  // MARK: - view

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          {edit && (
            <Grid
              container
              xs={12}
              sm={12}
              sx={{ marginTop: 5 }}
              justifyContent="center"
              alignItems="center"
            >
              <AvatarForm imageSrc={dataForm?.photo} />
            </Grid>
          )}
          <Grid item xs={12} sm={edit ? 6 : 4}>
            <TextField
              id="name"
              type="text"
              fullWidth
              margin="normal"
              disabled={
                edit &&
                getValues("userClasseStatus") === USER_CLASSE_STATUS.ACTIVE
              }
              variant="outlined"
              label="Nome"
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} sm={edit ? 6 : 4}>
            <TextField
              id="email"
              fullWidth
              margin="normal"
              variant="outlined"
              disabled={
                edit &&
                getValues("userClasseStatus") !== USER_CLASSE_STATUS.INVITED
              }
              InputLabelProps={{ shrink: true }}
              label="Email"
              {...register("email")}
              error={!!errors.email}
              helperText={!!errors.email && errors.email.message}
            />
          </Grid>
          <Grid item xs={12} sm={edit ? 6 : 4}>
            <SelectUseForm
              error={!!errors.roleName}
              helperText={errors.roleName?.message}
              control={control}
              disabled={getValues("roleName") === ROLE.OWNER}
              label="Papel do usuário"
              defaultValue={getValues("roleName")}
              {...register("roleName")}
            >
              <MenuItem value="">Selecione o papel do usuário</MenuItem>
              {rolesFiltered.map((role) => (
                <MenuItem value={role.name} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </SelectUseForm>
          </Grid>
          {edit && (
            <Grid item xs={12} sm={6}>
              <SelectUseForm
                disabled={
                  getValues("userClasseStatus") ===
                    USER_CLASSE_STATUS.INVITED ||
                  getValues("roleName") === ROLE.OWNER
                }
                error={!!errors.userClasseStatus}
                helperText={errors.userClasseStatus?.message}
                control={control}
                label="Status do usuário"
                defaultValue={String(getValues("userClasseStatus"))}
                {...register("userClasseStatus")}
              >
                <MenuItem value="">Selecione o status do usuário</MenuItem>
                {userStatusFiltered.map((status) => (
                  <MenuItem value={status.value} key={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </SelectUseForm>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default UserForm;
