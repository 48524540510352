import React, {
  useEffect,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useCallback,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Button, Grid, IconButton, Radio, TextField } from "@mui/material";
import { FiList, FiPlus, FiTrash } from "react-icons/fi";
import theme from "@app/config/theme";

export type MultipleChoiceFormRef = {
  validate: () => Promise<TMultipleChoiceForm>;
};

export type TAnswersForm = {
  answer: string;
  correct: boolean;
};

export type TMultipleChoiceForm = {
  answers: TAnswersForm[];
};

type MultipleChoiceFormProps = {
  ref?: RefObject<MultipleChoiceFormRef>;
  dataForm?: TMultipleChoiceForm;
  edit?: boolean;
};

const MultipleChoiceForm = forwardRef<unknown, MultipleChoiceFormProps>(
  ({ dataForm, edit }: MultipleChoiceFormProps, ref) => {
    const {
      register,
      formState: { errors },
      watch,
      getValues,
      trigger,
      setValue,
      reset,
    } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          answers: yup
            .array()
            .of(
              yup.object().shape({
                answer: yup.string().required("Preencha o campo resposta."),
                correct: yup.boolean(),
              })
            )
            .min(2)
            .max(4),
        })
      ),
      defaultValues: {
        answers: [
          {
            answer: "",
            correct: true,
          },
          {
            answer: "",
            correct: false,
          },
        ] as TAnswersForm[],
      },
      mode: "onSubmit",
    });

    const [answers] = watch(["answers"]);

    const handleAddAnswer = (
      evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      evt.stopPropagation();
      const answer: TAnswersForm = {
        answer: "",
        correct: answers?.length ? false : true,
      };
      if (!answers) {
        setValue("answers", [answer]);
      } else {
        answers.push(answer);
        setValue("answers", answers);
      }
    };

    const handleRemoveAnswer = useCallback(
      (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
        evt.stopPropagation();
        answers.splice(index, 1);
        setValue("answers", answers);
      },
      [answers, setValue]
    );

    const validate = useCallback(async () => {
      const value = await trigger();
      if (value) {
        return getValues();
      }
    }, [trigger, getValues]);

    const handleChangeCorrectAnswer = (index: number) => {
      const updated = answers.map((answer, answerIndex) => {
        if (index === answerIndex) {
          answer.correct = true;
        } else {
          answer.correct = false;
        }
        return answer;
      });
      setValue("answers", updated);
    };

    useEffect(() => {
      // if (edit) {
      reset(dataForm);
      // }
    }, [dataForm, reset, edit]);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    return (
      <Grid container spacing={2}>
        {answers?.map((answer, index) => (
          <Grid item sm={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: 50,
                  width: 50,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 25,
                  mr: 2,
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="medium"
                  sx={{
                    mt: 2,
                  }}
                >
                  <FiList />
                </IconButton>
              </Box>
              <TextField
                id="answer"
                label={"Resposta"}
                fullWidth
                margin="normal"
                variant="standard"
                value={getValues(`answers.${index}.answer`)}
                required
                onClick={(evt) => evt.stopPropagation()}
                InputLabelProps={{ shrink: true }}
                error={!!errors.answers?.[index]?.answer}
                helperText={errors.answers?.[index]?.answer?.message}
                {...register(`answers.${index}.answer`)}
              />
              <Radio
                sx={{
                  ml: 2,
                  mt: 2,
                }}
                onClick={(evt) => evt.stopPropagation()}
                checked={answers[index].correct}
                onChange={() => handleChangeCorrectAnswer(index)}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <IconButton
                sx={{
                  mt: 2,
                }}
                aria-label="delete"
                size="medium"
                onClick={(evt) => handleRemoveAnswer(evt, index)}
              >
                <FiTrash color={theme.palette.error.main} />
              </IconButton>
            </Box>
          </Grid>
        ))}
        {(!answers || answers?.length < 4) && (
          <Grid item sm={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Button
                variant="text"
                color="secondary"
                startIcon={<FiPlus />}
                onClick={handleAddAnswer}
                size="small"
              >
                Adicionar a resposta
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default MultipleChoiceForm;
