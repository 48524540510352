import { IUserRepository } from "@app/features/User/data/userRepository";
import IUserModel from "@app/features/User/domain/models/IUserModel";
import IUserEntity from "@app/features/User/data/IUserEntity";
import { store } from "@app/config/store";

const getUserUseCase = async (
  repository: Pick<IUserRepository, "getUser">,
  userId: number
): Promise<IUserModel> => {
  const { currentClasse } = store.getState().classe;

  let classeId = 0;

  if (currentClasse) {
    classeId = currentClasse.id;
  }

  const userEntity = await repository.getUser(userId, classeId);
  const dataDomain = mapToDomain(userEntity);

  return dataDomain;
};

const mapToDomain = (data: IUserEntity): IUserModel => {
  const {
    email,
    id,
    name,
    photo,
    role,
    status,
    userStatusClasse,
    birthDate,
    phone,
    expireAt,
  } = data;

  return {
    email,
    id,
    name,
    role,
    photo,
    status,
    userStatusClasse,
    birthDate,
    phone,
    expireAt,
  };
};

export default getUserUseCase;
