import { ICompanyRepository } from "@app/features/Company/data/companyRepository";
import { TEditCompanyViewModel } from "@app/features/Company/view/Edit/editCompanyViewModel";

const editCompanyUseCase = async (
  repository: Pick<ICompanyRepository, "editCompany">,
  data: TEditCompanyViewModel
) => {
  const {
    companyId,
    address,
    addressNumber,
    city,
    companyName,
    country,
    document,
    companyImage,
    phone,
    state,
    tradingName,
    zipcode,
  } = data;

  await repository.editCompany({
    id: companyId,
    address,
    addressNumber,
    city,
    companyName,
    country,
    document,
    companyImage,
    phone,
    state,
    tradingName,
    zipcode,
  });
};

export default editCompanyUseCase;
