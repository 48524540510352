import React, { useCallback, useEffect, useRef, useState } from "react";

import { FiFile, FiImage, FiMusic, FiYoutube, FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Chip, Stack } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import { useSelector } from "react-redux";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import resourceService, {
  TListResourcesSearchParams,
} from "@app/services/resource";

import { useListResourcesViewModel } from "@app/features/Resource/views/List/listResourcesViewModel";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";

import LoadingLock from "@app/components/molecules/LoadingLock";
import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";
import StandardTemplate from "@app/components/templates/Standard";
import DrawerFilter, {
  IDrawerFilterRef,
  TFilterPayload,
} from "@app/components/organisms/DrawerFilter";

import { RootState } from "@app/config/store";
import { IPageModel } from "@app/constants/interfaces";
import {
  RESOURCE_TYPES_OPTIONS,
  ROWS_PER_PAGE_OPTIONS,
} from "@app/constants/optionsSelect";
import Util from "@app/util";
import { RESOURCE_TYPE } from "@app/constants/enums";
import theme from "@app/config/theme";
import { useLanguageRepository } from "@app/features/Language/data/languageRepository";
import languageService from "@app/services/language";

const ListResources = () => {
  const navigate = useNavigate();
  const draweFilterRef = useRef<IDrawerFilterRef>();

  const resourceRespository = useResourceRepository(resourceService);
  const languageRepository = useLanguageRepository(languageService);

  const {
    deleteResource,
    listResources,
    isLoading,
    resources,
    getAvailableLanguages,
  } = useListResourcesViewModel(resourceRespository, languageRepository);

  const { currentCompany } = useSelector((state: RootState) => state.company);

  const [filters, setFilters] = useState<TFilterPayload[]>([]);
  const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
    page: 0,
    amount: ROWS_PER_PAGE_OPTIONS.MIN,
  });

  const handleClickPrimaryButton = useCallback(() => {
    navigate("/admin/resources/create");
  }, [navigate]);

  const handleEditResource = useCallback(
    (data: IResourceModel) => {
      navigate(`/admin/resources/edit/${data.id}`);
    },
    [navigate]
  );

  const handleSetFilter = useCallback((values: TFilterPayload[]) => {
    setFilters([...values]);
  }, []);

  const removeFilter = useCallback(
    (filter: TFilterPayload) => {
      let newFilters = filters.filter((f) => f.id !== filter.id);
      setFilters([...newFilters]);
    },
    [filters]
  );

  const handleGetNewPage = useCallback((newPage: number, amount: number) => {
    setPaginationConfig({ amount, page: newPage });
  }, []);

  const getDocIcon = useCallback((data: IResourceModel) => {
    switch (data.type) {
      case RESOURCE_TYPE.AUDIO:
        return <FiMusic color={theme.palette.primary.main} size={20} />;

      case RESOURCE_TYPE.DOC:
        return <FiFile color={theme.palette.primary.main} size={20} />;
      case RESOURCE_TYPE.EMBED:
        return <FiYoutube color={theme.palette.primary.main} size={20} />;
      case RESOURCE_TYPE.IMAGE:
        return <FiImage color={theme.palette.primary.main} size={20} />;
    }
  }, []);

  const handleDeleteResource = useCallback(
    (data: IResourceModel) => {
      if (currentCompany) {
        deleteResource(data.id, currentCompany.id, paginationConfig);
      }
    },
    [deleteResource, currentCompany, paginationConfig]
  );

  useEffect(() => {
    let filtersData: TListResourcesSearchParams = {};

    filters.forEach((f) => {
      if (f.value) {
        filtersData = { [f.id]: f.value };
      }
    });

    if (currentCompany) {
      listResources(currentCompany.id, paginationConfig, filtersData);
    }
  }, [listResources, currentCompany, filters, paginationConfig]);

  useEffect(() => {
    getAvailableLanguages();
  }, [getAvailableLanguages]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      {!!filters.length && (
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ mb: 3 }}
        >
          {filters.map((filter) => (
            <Chip
              variant="outlined"
              label={`${filter.label}: ${filter.value}`}
              onDelete={() => removeFilter(filter)}
            />
          ))}
        </Stack>
      )}

      <StandardTemplate
        title="Biblioteca"
        primaryButton={{
          textButton: "Upload de recurso",
          isVisible: true,
          iconButton: <FiUpload />,
          onClickButton: handleClickPrimaryButton,
        }}
        secondaryButton={{
          textButton: "Filtro",
          isVisible: true,
          iconButton: <FilterAlt />,
          onClickButton: draweFilterRef.current?.openDrawer,
        }}
      >
        <StickyHeaderTable
          columns={[
            {
              id: "type",
              label: "",
              component: "CUSTOM",
              align: "left",
              customComponent: (data) => getDocIcon(data as IResourceModel),
            },
            {
              id: "name",
              label: "Nome",
              align: "left",
            },
            {
              id: "size",
              label: "Tamanho",
              align: "left",
              format: (value) => {
                return Util.formatBytes(value);
              },
            },
            {
              id: "type",
              label: "Tipo do recurso",
              align: "left",
            },
          ]}
          dialogMessage={{
            title: "Remover recurso?",
            description:
              "Tem certeza que deseja remover este recurso? Esse recurso será removido de todas as aulas e perguntas vinculados a ele.",
          }}
          pagination
          count={resources.count}
          identifierColumnKey="id"
          onPageChange={handleGetNewPage}
          onDelete={handleDeleteResource}
          onEdit={handleEditResource}
          rows={resources.data}
        />
      </StandardTemplate>
      <DrawerFilter
        ref={draweFilterRef}
        onFilter={handleSetFilter}
        fields={[
          {
            id: "name",
            label: "Nome",
            type: "TEXT",
          },
          {
            id: "type",
            label: "Tipo de recurso",
            type: "SELECT",
            options: RESOURCE_TYPES_OPTIONS,
          },
          // {
          //   id: "language",
          //   label: "Idioma do recurso",
          //   type: "SELECT",
          //   options: languages,
          // },
        ]}
      />
    </>
  );
};

export default ListResources;
