import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { FiPlus } from "react-icons/fi";

import { useListClassesViewModel } from "@app/features/Classe/view/List/listClassesViewModel";
import { useClasseRepository } from "@app/features/Classe/data/classeRespository";
import classeService from "@app/services/classe";
import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";

import { setCurrentClasseAction } from "@app/features/Classe/data/classeActions";

import Standard from "@app/components/templates/Standard";
import CardClasse from "@app/components/organisms/CardClasse";
import LoadingLock from "@app/components/molecules/LoadingLock";
import InviteDialog from "@app/components/molecules/InviteDialog";

import { RootState, dispatchStore } from "@app/config/store";
import { INVITE_STATUS, ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";
import useRestricted from "@app/hooks/useRestricted";

const ListClasses = () => {
  const navigate = useNavigate();
  const { isAllowedTo } = useRestricted();

  const [classeInvite, setClasseInvite] = useState<IClasseModel | null>();
  const [showInviteDialog, setShowOpenInvite] = useState(false);
  const classeRepository = useClasseRepository(classeService);
  const { listClasses, deleteClasse, handleInviteClasse, isLoading } =
    useListClassesViewModel(classeRepository);

  const { classes } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const setCurrentClasse = useCallback(
    (classe: IClasseModel) => {
      dispatchStore(setCurrentClasseAction(classe));
      navigate("/admin/dashboard");
    },
    [navigate]
  );

  const handleClickPrimary = useCallback(
    (classe: IClasseModel) => {
      if (classe.userClasseStatus === USER_CLASSE_STATUS.INVITED) {
        setShowOpenInvite(true);
        setClasseInvite(classe);
      } else {
        setCurrentClasse(classe);
      }
    },
    [setCurrentClasse]
  );

  const handleEditClasse = useCallback(
    (classe: IClasseModel) => {
      navigate(`/admin/classes/edit/${classe.id}`);
    },
    [navigate]
  );

  const navigateToCreateClasse = useCallback(() => {
    if (!currentCompany) {
      navigate("/admin/company/create");
    } else {
      navigate("/admin/classes/create");
    }
  }, [navigate, currentCompany]);

  const handleResultInvite = useCallback(
    async (data: IClasseModel, status: INVITE_STATUS) => {
      if (currentCompany?.id) {
        await handleInviteClasse(
          { classeId: data.id, inviteStatus: status },
          currentCompany.id
        );
        setShowOpenInvite(false);
        setClasseInvite(null);
      }
    },
    [handleInviteClasse, currentCompany]
  );

  const handleDeleteClasse = useCallback(
    (classe: IClasseModel) => {
      if (currentCompany) {
        const { id } = currentCompany;
        deleteClasse(classe.id, id);
      }
    },
    [deleteClasse, currentCompany]
  );

  const handleCloseInvite = useCallback(() => {
    setShowOpenInvite(false);
  }, []);

  useEffect(() => {
    if (currentCompany) {
      listClasses(currentCompany.id);
    }
  }, [listClasses, currentCompany]);

  return (
    <>
      <Standard
        title="Turmas"
        primaryButton={{
          textButton: "Novo",
          iconButton: <FiPlus />,
          isVisible: !!currentCompany && isAllowedTo([ROLE.OWNER, ROLE.ADMIN]),
          onClickButton: navigateToCreateClasse,
        }}
      >
        {showInviteDialog && (
          <InviteDialog
            open={showInviteDialog}
            classe={classeInvite}
            onClose={handleCloseInvite}
            onResult={handleResultInvite}
          />
        )}
        {isLoading && <LoadingLock isLoading={isLoading} />}
        <Grid container spacing={1}>
          {classes.map((classe) => (
            <Grid key={classe.id} item xs={12} sm={3}>
              <CardClasse
                data={classe}
                onDeleteButton={handleDeleteClasse}
                onClickPrimaryButton={handleClickPrimary}
                onClickSecondaryButton={handleEditClasse}
              />
            </Grid>
          ))}
        </Grid>
      </Standard>
    </>
  );
};

export default ListClasses;
