import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { TEditRosourceViewModel } from "@app/features/Resource/views/Edit/editResourceViewModel";

const editResourceUseCase = async (
  repository: Pick<IResourceRepository, "editResource">,
  data: TEditRosourceViewModel
) => {
  await repository.editResource(data);
};

export default editResourceUseCase;
