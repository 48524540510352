import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

const removeUsersFromClasseUseCase = async (
  repository: Pick<IClasseRepository, "removeUsersFromClasse">,
  classeId: number,
  userId: number
): Promise<void> => {
  await repository.removeUsersFromClasse(classeId, userId);
};

export default removeUsersFromClasseUseCase;
