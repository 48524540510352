import {
  combineReducers,
  CombinedState,
  createStore,
  applyMiddleware,
  Action,
  Dispatch,
} from "redux";
import thunk from "redux-thunk";

import companyReducer from "@app/features/Company/data/companyReducer";
import classeReducer from "@app/features/Classe/data/classeReducer";
import profileReducer from "@app/features/Profile/data/profileReducer";

import { IClasseStore } from "@app/features/Classe/data/classeActionsType";
import { ICompanyStore } from "@app/features/Company/data/companyActionsType";
import { IProfileStore } from "@app/features/Profile/data/profileActionsType";

export type RootState = {
  classe: IClasseStore;
  company: ICompanyStore;
  profile: IProfileStore;
};

const appReducer = combineReducers<RootState>({
  classe: classeReducer,
  company: companyReducer,
  profile: profileReducer,
});

const rootReducer = (
  state: RootState | undefined,
  action: Action
): CombinedState<RootState> => {
  if (action.type === "RESET_STATE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = createStore(rootReducer, {}, applyMiddleware(thunk));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchStore = store.dispatch as Dispatch<any>;

export default rootReducer;
