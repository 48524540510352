import { ClasseActionTypes, IClasseStore } from "./classeActionsType";

const INITIAL_STATE: IClasseStore = {
  classes: [],
  loading: true,
  currentClasse: null,
};

const classeReducer = (
  state = INITIAL_STATE,
  action: ClasseActionTypes
): IClasseStore => {
  switch (action.type) {
    case "GET_CLASSES":
      return { ...state, classes: action.payload, loading: false };

    case "LOADING_CLASSES":
      return { ...state, loading: action.payload };

    case "SET_CURRENT_CLASSE":
      return { ...state, currentClasse: action.payload };

    default:
      return state;
  }
};

export default classeReducer;
