import { useCallback } from "react";

import IProfileEntity from "@app/features/Profile/data/IProfileEntity";

import { TEditProfileRemoteData } from "@app/services/profile/remote-types/TEditProfileRemoteData";

import { IProfileService } from "@app/services/profile";

export interface IProfileRepository {
  getProfile(): Promise<IProfileEntity>;
  editProfile(data: TEditProfileRemoteData): Promise<void>;
}

const useProfileRepository = (
  profileService: IProfileService
): IProfileRepository => {
  const getProfile = useCallback(async (): Promise<IProfileEntity> => {
    const data = await profileService.getProfileService();
    return data;
  }, [profileService]);

  const editProfile = useCallback(
    async (data: TEditProfileRemoteData): Promise<void> => {
      await profileService.editProfileService(data);
    },
    [profileService]
  );

  return {
    getProfile,
    editProfile,
  };
};

export { useProfileRepository };
