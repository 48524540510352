import { IUserRepository } from "@app/features/User/data/userRepository";
import { TVerificationDataViewModel } from "@app/features/VerificationCode/view/verificationCodeViewModel";

const resendVerificationCodeUseCase = async (
  repository: Pick<IUserRepository, "resendVerificationCode">,
  data: TVerificationDataViewModel
) => {
  const { email, emailType } = data;

  await repository.resendVerificationCode({ email, emailType });
};

export { resendVerificationCodeUseCase };
