import React from "react";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    Direitos Reservados{" "}
    <Link color="inherit" href="https://www.sharpii.com">
      Sharpii
    </Link>
    &nbsp;{new Date().getFullYear()}
  </Typography>
);

export default React.memo(Copyright);
