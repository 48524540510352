import ILanguageEntity from "@app/features/Language/data/ILanguageEntity";
import api from "@app/config/api";
import API_SERVICES from "../constants";

export interface ILanguageService {
  getAvailableLanguages: () => Promise<ILanguageEntity[]>;
}

const getAvailableLanguages = async (): Promise<ILanguageEntity[]> =>
  api.get(API_SERVICES.LANGUAGES_SERVICES.LIST).then((res) => res.data);

const languageService: ILanguageService = {
  getAvailableLanguages,
};

export default languageService;
