import React, { useCallback, useRef } from "react";

import ReactQuill from "react-quill";
import { DeltaStatic, Sources } from "quill";
import "react-quill/dist/quill.snow.css";

import CustomToolbar, { formats } from "./CustomToolbar";

type TextEditorProps = {
  onChange: (value: string) => void;
  value?: string;
};

const TextEditor = ({ onChange, value }: TextEditorProps) => {
  const quillRef = useRef<ReactQuill>(null);

  //   const markToBeRead = useCallback(() => {
  //     const editor = quillRef.current?.getEditor();

  //     if (editor) {
  //       const selection = editor.getSelection();

  //       if (selection) {
  //         const { index, length } = selection;

  //         const selectedText = editor?.getText().substring(index, index + length);

  //         if (length > 0) {
  //           const newText = `<<${selectedText}>>`;

  //           editor.deleteText(index, length, "user");
  //           editor.insertText(index, newText, "user");

  //           const newIndex = index + newText.length;
  //           editor.setSelection(newIndex, 0, "user");
  //         }
  //       }
  //     }
  //   }, []);

  const handleChange = useCallback(
    (
      value: string,
      delta: DeltaStatic,
      source: Sources,
      editor: ReactQuill.UnprivilegedEditor
    ) => {
      onChange(value);
    },
    [onChange]
  );

  const modules = {
    toolbar: {
      container: "#toolbar",
      //   handlers: { mark: markToBeRead },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };

  return (
    <>
      <CustomToolbar />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        modules={modules}
        formats={formats}
        preserveWhitespace
        onChange={handleChange}
        style={{ height: "30vh" }}
      />
    </>
  );
};

export default TextEditor;
