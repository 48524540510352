import { AxiosResponse } from "axios";

import { APPLICATION_ERROR_CODE } from "@app/constants/apiErrors";
import I18n from "@app/languages";
import showNotification from "@app/components/molecules/Toast";

export type ApplicationDataError = {
  error: {
    code: APPLICATION_ERROR_CODE;
    description: string;
  };
};

export type ApplicationError = {
  response: AxiosResponse<ApplicationDataError>;
};

const handleError = (error: unknown) => {
  const title = "Ops!!!";
  let errorMessage = {
    title,
    description: "Error generico",
  };

  if (isApplicationError(error)) {
    const dataError = error as ApplicationError;

    errorMessage = {
      ...errorMessage,
      description: I18n.t(`errors.${dataError.response.data.error.code}`),
    };
  }

  showNotification(errorMessage.description, "ERROR");
};

const isApplicationError = (error: unknown): boolean => {
  const dataError = error as ApplicationError;
  if (dataError?.response) {
    const { data } = dataError?.response;

    return (
      !!(data as ApplicationDataError)?.error?.description &&
      !!(data as ApplicationDataError)?.error?.code
    );
  }
  return false;
};

const handleApplicationError = {
  isApplicationError,
  handleError,
};

export default handleApplicationError;
