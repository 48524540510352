import { useCallback, useState } from "react";

import { IUserRepository } from "@app/features/User/data/userRepository";
import { IProfileRepository } from "@app/features/Profile/data/profileRepository";

import { changePasswordUseCase } from "@app/features/ForgotPassword/domain/useCases/changePasswordUseCase";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import getProfileUseCase from "@app/features/Profile/domain/use-cases/getProfileUseCase";

export type TChangePasswordViewModel = {
  password: string;
  token: string;
};

const useChangeForcePasswordViewModel = (
  userRepository: IUserRepository,
  profileRepository: IProfileRepository
) => {
  const [loading, setLoading] = useState(false);

  const changePassword = useCallback(
    async (data: TChangePasswordViewModel) => {
      try {
        setLoading(true);
        await changePasswordUseCase(
          { changePassword: userRepository.changePassword },
          data
        );
        await getProfileUseCase({ getProfile: profileRepository.getProfile });
        setLoading(false);
        showNotification("Sua senha foi alterada com sucesso", "SUCCESS");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [userRepository.changePassword, profileRepository.getProfile]
  );

  return {
    changePassword,
    loading,
  };
};

export { useChangeForcePasswordViewModel };
