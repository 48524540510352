import { ICompanyRepository } from "@app/features/Company/data/companyRepository";

import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";
import ICompanyEntity from "@app/features/Company/data/ICompanyEntity";
import {
  setCompaniesAction,
  setCurrentCompanyAction,
} from "@app/features/Company/data/companyActions";
import { dispatchStore } from "@app/config/store";

const getCompaniesUseCase = async (
  repository: Pick<ICompanyRepository, "getCompanies">,
  shouldNavigateToClasses: () => void
) => {
  const companiesEntity = await repository.getCompanies();
  const companiesModel = mapToDomain(companiesEntity);
  dispatchStore(setCompaniesAction(companiesModel));

  if (companiesModel.length === 1) {
    const company = companiesModel.find(Boolean);

    if (company) {
      dispatchStore(setCurrentCompanyAction(company));
    }
    shouldNavigateToClasses();
  }
};

const mapToDomain = (enitities: ICompanyEntity[]): ICompanyModel[] => {
  const domain = enitities.map((company) => {
    const {
      address,
      addressNumber,
      city,
      companyImage,
      companyName,
      country,
      document,
      id,
      phone,
      state,
      tradingName,
      zipcode,
      user,
    } = company;

    return {
      id,
      address,
      addressNumber,
      city,
      companyName,
      country,
      document,
      phone,
      state,
      tradingName,
      companyImage,
      zipcode,
      user,
    };
  });

  return domain;
};

export default getCompaniesUseCase;
