import { IQuestionRepository } from "@app/features/Question/data/questionRepository";

const removeQuestionUseCase = async (
  repository: Pick<IQuestionRepository, "removeQuestion">,
  questionId: number
) => {
  await repository.removeQuestion(questionId);
};

export default removeQuestionUseCase;
