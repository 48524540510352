import React, { ReactNode, useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useCompanyRepository } from "@app/features/Company/data/companyRepository";
import { useCreateCompanyViewModel } from "@app/features/Company/view/Create/createCompanyViewModel";

import companyServices from "@app/services/company";

import CompanyForm, { TCompanyForm } from "@app/features/Company/view/Form";
import ClasseForm, { TFormClasse } from "@app/features/Classe/view/Form";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";
import StepperTemplate from "@app/components/templates/Stepper";

const CreateCompany = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [companyData, setCompanyData] = useState<TCompanyForm>();

  const navigate = useNavigate();

  const companyRepository = useCompanyRepository(companyServices);
  const { createCompany, isLoading } =
    useCreateCompanyViewModel(companyRepository);

  const handlePreviousStep = () => {
    if (currentStep === 0) {
      navigate(-1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCompanyData = useCallback((firstStepData: TCompanyForm) => {
    setCompanyData(firstStepData);
    setCurrentStep((currentValue) => currentValue + 1);
  }, []);

  const handleClasseData = useCallback(
    (firstClasseData: TFormClasse) => {
      if (companyData && firstClasseData) {
        createCompany({ ...companyData, classe: firstClasseData });
      }
    },
    [companyData, createCompany]
  );

  const getFooterActions = (): ReactNode => {
    switch (currentStep) {
      case 0:
        return (
          <FooterActions onCancel={handlePreviousStep} saveLabel="Próximo" />
        );

      default:
        return (
          <FooterActions onCancel={handlePreviousStep} cancelLabel="Anterior" />
        );
    }
  };

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      <StepperTemplate
        currentStep={currentStep}
        stepsLabels={["Dados Gerais", "Crie sua primeira turma"]}
        stepsScenes={[
          <CompanyForm
            onValidateSuccess={handleCompanyData}
            footerActions={getFooterActions()}
          />,
          <ClasseForm
            onValidateSuccess={handleClasseData}
            footerActions={getFooterActions()}
          />,
        ]}
      />
    </>
  );
};

export default CreateCompany;
