import { useCallback, useState } from "react";

import getUserUseCase from "@app/features/User/domain/use-cases/getUserUseCase";
import updateUserStatusUseCase from "@app/features/User/domain/use-cases/updateUserStatusUseCase";
import IUserModel from "@app/features/User/domain/models/IUserModel";

import { IUserRepository } from "@app/features/User/data/userRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { ROLE, USER_CLASSE_STATUS } from "@app/constants/enums";
import { useNavigate } from "react-router-dom";

export type TEditUserViewModel = {
  userId: number;
  userStatusClasse: USER_CLASSE_STATUS;
  roleName: ROLE;
  companyId: number;
  classeId: number;
};

const useEditUserViewModel = (repository: IUserRepository) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState<IUserModel>();

  const editUserStatus = useCallback(
    async (data: TEditUserViewModel) => {
      try {
        setLoading(true);

        await updateUserStatusUseCase(
          {
            updateUserStatus: repository.updateUserStatus,
          },
          data
        );
        setLoading(false);
        showNotification("Dados alterados com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.updateUserStatus, navigate]
  );

  const getUser = useCallback(
    async (userId: number) => {
      try {
        setLoading(true);

        const result = await getUserUseCase(
          {
            getUser: repository.getUser,
          },
          userId
        );
        setUser(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getUser]
  );

  return { user, isLoading, getUser, editUserStatus };
};

export { useEditUserViewModel };
