import React, { FC, useCallback, useState } from "react";

import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import CONFIG_OPTIONS from "@app/constants/configOptions";

import StandardTemplate from "@app/components/templates/Standard";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";

import { RootState } from "@app/config/store";
import useRestricted from "@app/hooks/useRestricted";
import useStyles from "./styles";

const ConfigsTemplate: FC = () => {
  const { isAllowedTo } = useRestricted();
  const [selectedOption, setSelectedOption] = useState(0);
  const navigate = useNavigate();
  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const classes = useStyles();

  const handleClick = useCallback(
    (index: number) => {
      const data = CONFIG_OPTIONS[index];
      if (currentClasse) {
        if (typeof data.pathRoute === "string") {
          navigate(`/admin/configs/${data.pathRoute}`, {});
        } else {
          const { id } = currentClasse;
          navigate(`/admin/configs/${data.pathRoute(id)}`, {});
        }
      }
      setSelectedOption(index);
    },
    [navigate, currentClasse]
  );

  return (
    <StandardTemplate>
      <Paper sx={classes.root} elevation={3}>
        <List component="nav" sx={classes.list}>
          {CONFIG_OPTIONS.filter((item) => isAllowedTo(item.permissions)).map(
            (configRoute, index) => (
              <ListItem
                button
                key={configRoute.name}
                selected={selectedOption === index}
                onClick={() => handleClick(index)}
              >
                <ListItemText primary={configRoute.name} />
              </ListItem>
            )
          )}
        </List>
        <Divider orientation="vertical" flexItem />
        <main style={classes.content}>
          <Outlet />
        </main>
      </Paper>
    </StandardTemplate>
  );
};

export default ConfigsTemplate;
