import React, { useCallback } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider,
  Grid,
  Chip,
} from "@mui/material";
import { FiChevronDown } from "react-icons/fi";

import StickyHeaderTable from "@app/components/organisms/StickyHeaderTable";

import { IStudentLessonResultModel } from "@app/features/Student/domain/models/IStudentProgressResultModel";
import Subtitle from "@app/components/atoms/Text/Subtitle";

export type onChangeModuleName = {
  moduleId: number;
  value: string;
  onSuccessCallback?: () => void;
};

type ModuleAccordionProps = {
  data: IStudentLessonResultModel;
  onPress: (studentPageId: number) => void;
};

const StudentLessonAccordion = ({ data, onPress }: ModuleAccordionProps) => {
  const handleNavigateResult = useCallback(
    (studentPageId: number) => {
      onPress(studentPageId);
    },
    [onPress]
  );

  return (
    <Accordion
      sx={{
        "& .Mui-focusVisible": { backgroundColor: "#FFF" },
      }}
    >
      <AccordionSummary
        expandIcon={
          <IconButton size="medium" color="secondary">
            <FiChevronDown />
          </IconButton>
        }
        focusRipple={true}
        focusVisibleClassName="none"
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          "& .MuiAccordionSummary-root": { backgroundColor: "#FFF" },
          "& .Mui-focusVisible": { backgroundColor: "#FFF" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Subtitle>{`Aula: ${data.name}`}</Subtitle>
          </Grid>
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <StickyHeaderTable
          identifierColumnKey="studentPageId"
          count={data.trys?.length || 0}
          onEdit={(data) => handleNavigateResult(data.studentPageId)}
          columns={[
            {
              id: "tryNumber",
              label: "Tentativa #",
              component: "CUSTOM",
              align: "center",
              customComponent: (data) => (
                <Chip
                  label={`Tentativa: #${data.tryNumber}`}
                  color="primary"
                  variant="filled"
                />
              ),
            },
            {
              id: "status",
              label: "Status da aula",
              align: "left",
            },
            {
              id: "date",
              label: "Data da tentativa",
              align: "center",
              component: "TEXT",
              format: (date) => {
                const objectDate = new Date(date);

                let dateFormated = objectDate.toLocaleDateString("pt", {
                  hour: "numeric",
                  minute: "numeric",
                });
                return dateFormated;
              },
            },
          ]}
          rows={data.trys || []}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default StudentLessonAccordion;
