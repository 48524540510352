import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import createClasseUseCase from "@app/features/Classe/domain/use-cases/createClasseUseCase";
import listUsersFromCompanyUseCase from "@app/features/Company/domain/use-cases/listUsersFromCompanyUseCase";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import { ICompanyRepository } from "@app/features/Company/data/companyRepository";

import IUserModel from "@app/features/User/domain/models/IUserModel";

import showNotification from "@app/components/molecules/Toast";
import handleApplicationError from "@app/handlers/handleApiError";
import { CLASSE_VISIBILITY } from "@app/constants/enums";

export type TCreateClasseViewModel = {
  name: string;
  description: string;
  image: string;
  languageId: number;
  visibility: CLASSE_VISIBILITY;
  entryCode?: string;
  companyId?: number;
  primaryColor?: string;
  usersToInvite?: TUserToInviteViewModel[];
};

export type TUserToInviteViewModel = {
  userId: number;
  roleName: string;
};

const useCreateClassViewModel = (
  classeRepository: IClasseRepository,
  companyRepository: ICompanyRepository
) => {
  const [isLoading, setLoading] = useState(false);
  const [usersFromCompany, setUsersFromCompany] = useState<IUserModel[]>([]);

  const navigate = useNavigate();

  const createClasse = useCallback(
    async (data: TCreateClasseViewModel) => {
      try {
        setLoading(true);
        await createClasseUseCase(
          {
            createClasse: classeRepository.createClasse,
          },
          data
        );
        setLoading(false);
        showNotification("Turma criada com sucesso", "SUCCESS");
        navigate("/admin/classes");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [classeRepository.createClasse, navigate]
  );

  const getAllUsersFromCompany = useCallback(
    async (companyId: number) => {
      try {
        setLoading(true);
        const result = await listUsersFromCompanyUseCase(
          {
            listAllUsersFromCompany: companyRepository.listAllUsersFromCompany,
          },
          companyId
        );
        setLoading(false);
        setUsersFromCompany(result);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [companyRepository.listAllUsersFromCompany]
  );

  return { isLoading, usersFromCompany, createClasse, getAllUsersFromCompany };
};

export { useCreateClassViewModel };
