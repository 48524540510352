import React from "react";
import { useSelector } from "react-redux";

import { useModuleRepository } from "@app/features/Module/data/moduleRepository";
import { useCreateModuleViewModel } from "@app/features/Module/view/Create/createModuleViewModel";
import moduleService from "@app/services/module";

import StandardTemplate from "@app/components/templates/Standard";
import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

import ModuleForm, { TModuleForm } from "@app/features/Module/view/Form";

import { RootState } from "@app/config/store";

const CreateModule = () => {
  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const moduleRepository = useModuleRepository(moduleService);
  const { createModule, isLoading } =
    useCreateModuleViewModel(moduleRepository);

  const handleSave = (data: TModuleForm) => {
    if (currentClasse) {
      createModule({ classeId: currentClasse.id, ...data });
    }
  };

  return (
    <StandardTemplate title="Novo módulo" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <ModuleForm
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default CreateModule;
