import React, { useEffect } from "react";

import { Grid, Paper, CardContent, Typography, Card } from "@mui/material";
import CountUp from "react-countup";
// import { LineChart, BarChart } from "@mui/x-charts";
import { useDashboardViewModel } from "@app/features/Dashboard/view/dashboardViewModel";
import { useDashboardRepository } from "@app/features/Dashboard/data/dashboardRepository";
import dashboardService from "@app/services/dashboard";
import EmptyState from "@app/components/organisms/EmptyState";

import { lotties } from "@app/assets";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";
import LoadingLock from "@app/components/molecules/LoadingLock";
// const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
// const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
// const xLabels = [
//   "Page A",
//   "Page B",
//   "Page C",
//   "Page D",
//   "Page E",
//   "Page F",
//   "Page G",
// ];

const Dashboard = () => {
  const dashboardRepository = useDashboardRepository(dashboardService);
  const { getDashboard, dashboardData, isLoading } =
    useDashboardViewModel(dashboardRepository);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  useEffect(() => {
    if (currentClasse) {
      getDashboard(currentClasse.id);
    }
  }, [currentClasse, getDashboard]);

  return (
    <Paper sx={{ p: 2 }}>
      <LoadingLock isLoading={isLoading} />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Novos alunos no mês
              </Typography>
              <Typography variant="h5" component="div">
                <CountUp
                  prefix="+"
                  end={dashboardData?.newUserInMonth || 0}
                  suffix=" alunos"
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Total de alunos
              </Typography>
              <Typography variant="h5" component="div">
                <CountUp
                  end={dashboardData?.studentsCount || 0}
                  suffix=" alunos"
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Acessos à turma no mês
              </Typography>
              <Typography variant="h5" component="div">
                <CountUp
                  prefix="+"
                  end={dashboardData?.classeAccessCount || 0}
                  suffix=" alunos"
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <EmptyState
            lottieAnimation={lotties.dashboard}
            message="Estamos finalizando a contrução do nosso dashboard, fica atento em breve você poderá ver mais informações valiosas por aqui💜."
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                tste
              </Typography>
              <LineChart
                height={300}
                series={[
                  { data: pData, label: "pv" },
                  { data: uData, label: "uv" },
                ]}
                xAxis={[{ scaleType: "point", data: xLabels }]}
              />
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Word of the Day
              </Typography>
              <Typography variant="h5" component="div">
                tste
              </Typography>
              <BarChart
                height={300}
                xAxis={[
                  {
                    scaleType: "band",
                    data: ["group A", "group B", "group C"],
                  },
                ]}
                series={[{ data: [4, 3, 5] }]}
              />
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </Paper>
  );
};

export default Dashboard;
