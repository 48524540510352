import React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import CopyRight from "@app/components/molecules/CopyRight";

import { useUserRepository } from "@app/features/User/data/userRepository";
import { useForgotPasswordViewModel } from "@app/features/ForgotPassword/view/ForgotPassword/forgotPasswordViewModel";
import userService from "@app/services/user";

import useStyles from "./styles";

const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required("EMail é obrigaótrio"),
      })
    ),
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onSubmit",
  });

  const userRepository = useUserRepository(userService);
  const { loading, sendEmail } = useForgotPasswordViewModel(userRepository);

  const onSubmit = (data: { email: string }) => {
    sendEmail(data.email);
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form style={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={"Email"}
            autoComplete="email"
            autoFocus
            error={!!errors.email}
            helperText={!!errors.email && errors.email.message}
            {...register("email")}
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Verificar email
            </Button>
          )}
        </form>
      </Box>
      <Box mt={8}>
        <CopyRight />
      </Box>
    </Container>
  );
};

export default SignIn;
