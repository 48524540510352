import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import editCompanyUseCase from "@app/features/Company/domain/use-cases/editCompanyUseCase";
import getCompanyUseCase from "@app/features/Company/domain/use-cases/getCompanyUseCase";
import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";

import { ICompanyRepository } from "@app/features/Company/data/companyRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";

export type TEditCompanyViewModel = {
  companyId: number;
  companyImage: string;
  companyName: string;
  tradingName: string;
  document: string;
  country: string;
  state: string;
  city: string;
  address: string;
  addressNumber: string;
  zipcode: string;
  phone: string;
};

const useEditCompanyViewModel = (repository: ICompanyRepository) => {
  const [company, setCompany] = useState<ICompanyModel>();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getCompany = useCallback(
    async (companyId: number) => {
      try {
        setLoading(true);

        const data = await getCompanyUseCase(
          {
            getCompany: repository.getCompany,
          },
          companyId
        );
        setCompany(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getCompany]
  );

  const editCompany = useCallback(
    async (data: TEditCompanyViewModel) => {
      try {
        setLoading(true);

        await editCompanyUseCase(
          {
            editCompany: repository.editCompany,
          },
          data
        );
        setLoading(false);
        showNotification(
          "Dados da instituição alterada com sucesso",
          "SUCCESS"
        );
        // await getCompany(data.companyId);
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editCompany, navigate]
  );

  return { isLoading, company, editCompany, getCompany };
};

export { useEditCompanyViewModel };
