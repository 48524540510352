import { dispatchStore } from "@app/config/store";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import IClasseEntity from "@app/features/Classe/data/IClasseEntity";
import IClassesModel from "@app/features/Classe/domain/models/IClasseModel";

import {
  setClassesAction,
  setLoadingClassesAction,
} from "@app/features/Classe/data/classeActions";

const listClassesUseCase = async (
  repository: Pick<IClasseRepository, "listClasses">,
  companyId: number
) => {
  dispatchStore(setLoadingClassesAction(true));

  const listClassesEntity = await repository.listClasses(companyId);
  const dataDomain = mapClassesEntity(listClassesEntity);
  dispatchStore(setClassesAction(dataDomain));
  dispatchStore(setLoadingClassesAction(false));
};

const mapClassesEntity = (data: IClasseEntity[]): IClassesModel[] => {
  const dataDomain = data.map((item): IClassesModel => {
    const {
      description,
      visibility,
      entryCode,
      id,
      image,
      languageId,
      name,
      status,
      language,
      primaryColor,
      userClasseStatus,
      role,
    } = item;

    return {
      description,
      visibility,
      entryCode,
      id,
      image,
      languageId,
      name,
      status,
      language,
      primaryColor,
      userClasseStatus,
      role,
    };
  });

  return dataDomain;
};

export default listClassesUseCase;
