import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import IResourceEntity from "@app/features/Resource/data/IResourceEntity";

import { TListUsersFromClasseSearchParams } from "@app/services/classe";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

const listResourcesUseCase = async (
  repository: Pick<IResourceRepository, "listResources">,
  companyId: number,
  pageConfig: IPageModel,
  filterParams?: TListUsersFromClasseSearchParams
): Promise<IPaginationModel<IResourceModel>> => {
  const resourcesEntity = await repository.listResources(
    companyId,
    pageConfig,
    filterParams
  );

  const dataDomain = mapToDomain(resourcesEntity.data);

  return { data: dataDomain, count: resourcesEntity.count };
};

const mapToDomain = (resources: IResourceEntity[]): IResourceModel[] => {
  const domain = resources.map((resource): IResourceModel => {
    const { fileKey, id, name, size, type, url, extension, languageId } =
      resource;

    return {
      fileKey,
      id,
      name,
      size,
      type,
      url,
      extension,
      languageId,
    };
  });

  return domain;
};

export default listResourcesUseCase;
