import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import useStyles from "./styles";

type TLoadingLockProps = {
  isLoading: boolean;
};

const LoadingLock = ({ isLoading }: TLoadingLockProps) => {
  const classes = useStyles();

  return (
    <Backdrop sx={classes.backdrop} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingLock;
