import { useState } from "react";

import { useNavigate } from "react-router-dom";

import showNotification from "@app/components/molecules/Toast";

import { resendVerificationCodeUseCase } from "@app/features/VerificationCode/domain/useCases/resendVerificationCodeUseCase";
import { verificationCodeUseCase } from "@app/features/VerificationCode/domain/useCases/verificationCodeUseCase";

import handleApplicationError from "@app/handlers/handleApiError";
import { IUserRepository } from "@app/features/User/data/userRepository";
import { EMAIL_TYPES } from "@app/constants/enums";

export type TVerificationDataViewModel = {
  email: string;
  verificationCode: number;
  emailType: EMAIL_TYPES;
};

const useVerificationCodeViewModel = (userRepository: IUserRepository) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();

  const resendVerificationCode = async (data: TVerificationDataViewModel) => {
    setIsLoading(true);

    try {
      await resendVerificationCodeUseCase(
        {
          resendVerificationCode: userRepository.resendVerificationCode,
        },
        data
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleApplicationError.handleError(error);
    }
  };

  const verificateCode = async (
    data: TVerificationDataViewModel,
    navigateTo?: string
  ) => {
    setIsLoading(true);

    try {
      await verificationCodeUseCase(
        {
          verificationCode: userRepository.verificationCode,
        },
        data
      );
      setShowSuccess(true);
      setIsLoading(false);
      showNotification(
        data.emailType === EMAIL_TYPES.FORGOT_PASSWORD
          ? "Conta verificada com sucesso. Você será redirecionado para uma tela onde poderá alterar sua senha."
          : "Sua conta foi ativada com sucesso, agora você já pode fazer login.",
        "SUCCESS"
      );
      setTimeout(() => {
        if (navigateTo) navigate(navigateTo);
        else navigate(-1);
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      handleApplicationError.handleError(error);
    }
  };

  return {
    isLoading,
    showSuccess,
    resendVerificationCode,
    verificateCode,
  };
};

export { useVerificationCodeViewModel };
