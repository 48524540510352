import { IUserRepository } from "@app/features/User/data/userRepository";

const forgotPasswordUseCase = async (
  repository: Pick<IUserRepository, "forgotPassword">,
  email: string
) => {
  await repository.forgotPassword(email);
};

export { forgotPasswordUseCase };
