import { IQuestionRepository } from "@app/features/Question/data/questionRepository";
import IQuestionEntity from "@app/features/Question/data/IQuestionEntity";
import { TQuestion } from "@app/features/Question/view/Form";
import IQuestionModel from "@app/features/Question/domain/models/IQuestionModel";
import { QUESTION_TYPE } from "@app/constants/enums";

const addQuestionUseCase = async (
  repository: Pick<IQuestionRepository, "addQuestion">,
  data: TQuestion
) => {
  const result = await repository.addQuestion(data);
  const domain = mapToModel(result);

  return domain;
};

const mapToModel = (entity: IQuestionEntity): IQuestionModel => {
  const { description, id, questionType, resourceMedia } = entity;

  switch (questionType) {
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return {
        description,
        id,
        questionType,
        resourceMedia,
        data: {
          answers: [],
        },
      };
    case QUESTION_TYPE.ORDERING:
    case QUESTION_TYPE.SPEAKING:
    case QUESTION_TYPE.TEXT:
      return {
        description,
        id,
        questionType,
        resourceMedia,
        data: { answer: "" },
      };
  }
};

export default addQuestionUseCase;
