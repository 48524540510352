import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import Storage from "@app/config/storage";
import { useNavigate } from "react-router-dom";
import { store } from "@app/config/store";
import { images } from "@app/assets";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

type TAvatarOption = {
  label: string;
  value: string;
  path: string;
};

type TAppToolbarOption = {
  title: string;
  key: "CLASSE" | "COMPANY" | "FILES";
  path: string;
  show: boolean;
};

const settings: TAvatarOption[] = [
  { label: "Meus Dados", value: "PROFILE", path: "/admin/profile" },
  { label: "Sair", value: "LOGOUT", path: "/login" },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type AppToolBarProps = {
  open: boolean;
  profilePhoto: string | undefined;
  handleDrawerOpen: () => void;
  shouldRenderDrawer: boolean;
  appToolbarOptions: TAppToolbarOption[];
};

const AppToolBar = ({
  open,
  appToolbarOptions,
  shouldRenderDrawer,
  handleDrawerOpen,
  profilePhoto,
}: AppToolBarProps) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleChooseMenu = (setting: TAvatarOption) => {
    if (setting.value === "LOGOUT") {
      Storage.clear();
      store.dispatch({ type: "RESET_STATE", payload: null });
    }
    navigate(setting.path);
    handleCloseUserMenu();
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" open={open} style={{ height: 65 }}>
      <Toolbar>
        {shouldRenderDrawer && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <img
          alt="logo"
          src={images.logoWhite}
          style={{ width: 150, height: 150 }}
        />
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {appToolbarOptions
            .filter((o) => o.show)
            .map((page) => (
              <Button
                key={page.title}
                onClick={() => handleNavigate(page.path)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.title}
              </Button>
            ))}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src={profilePhoto} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting.value}
                onClick={() => handleChooseMenu(setting)}
              >
                <Typography textAlign="center">{setting.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppToolBar;
