import { useCallback, useState } from "react";

import getAvailableLanguagesUseCase from "@app/features/Language/domain/use-cases/getAvailableLanguagesUseCase";

import { ILanguageRepository } from "@app/features/Language/data/languageRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import ILanguageModel from "@app/features/Language/domain/models/ILanguageModel";

const useFormClasseViewModel = (repository: ILanguageRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [languages, setLanguages] = useState<ILanguageModel[]>([]);

  const getAvailableLanguages = useCallback(async () => {
    try {
      setLoading(true);

      const data = await getAvailableLanguagesUseCase({
        getAvailableLanguages: repository.getAvailableLanguages,
      });
      setLanguages(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApplicationError.handleError(error);
    }
  }, [repository.getAvailableLanguages]);

  return { isLoading, languages, getAvailableLanguages };
};

export { useFormClasseViewModel };
