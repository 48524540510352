import { useCallback, useState } from "react";

import getRolesUseCase from "@app/features/Role/domain/use-cases/getRolesUseCase";

import { IRoleRepository } from "@app/features/Role/data/roleRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import IRoleModel from "@app/features/Role/domain/models/IRoleModel";

const useFormUserViewModel = (repository: IRoleRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [roles, setRoles] = useState<IRoleModel[]>([]);

  const getRoles = useCallback(async () => {
    try {
      setLoading(true);

      const data = await getRolesUseCase({
        getRoles: repository.getRoles,
      });
      setRoles(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApplicationError.handleError(error);
    }
  }, [repository.getRoles]);

  return { isLoading, roles, getRoles };
};

export { useFormUserViewModel };
