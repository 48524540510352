import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CircularProgress } from "@mui/material";
import OtpInput from "react18-input-otp";

import { useVerificationCodeViewModel } from "@app/features/VerificationCode/view/verificationCodeViewModel";

import CountDown from "@app/components/organisms/CountDown";
import CopyRight from "@app/components/molecules/CopyRight";
import { useUserRepository } from "@app/features/User/data/userRepository";
import userService from "@app/services/user";

import { EMAIL_TYPES } from "@app/constants/enums";

import showNotification from "@app/components/molecules/Toast";
import Lottie from "lottie-react";
import { lotties } from "@app/assets";
import useStyles from "./styles";

type LocationState = {
  email: string;
  emailType: EMAIL_TYPES;
  title: string;
  navigateTo?: string;
};

const VerificationCode = () => {
  const location = useLocation();
  const state = location.state as LocationState;

  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState("");
  const [resendCodeButton, setResendCodeButton] = useState(true);

  const userRepository = useUserRepository(userService);

  const { resendVerificationCode, verificateCode, showSuccess, isLoading } =
    useVerificationCodeViewModel(userRepository);

  const handleChange = (otp: string) => {
    setVerificationCode(otp);
  };

  const handleFinishCountDown = () => {
    setResendCodeButton(false);
  };

  const handleSubmit = () => {
    verificateCode(
      {
        email: state.email,
        emailType: state.emailType,
        verificationCode: Number(verificationCode),
      },
      state.navigateTo
    );
  };

  const handleClickResendButton = () => {
    setResendCodeButton(true);
    resendVerificationCode({
      email: state.email,
      emailType: state.emailType,
      verificationCode: Number(verificationCode),
    });
  };

  useEffect(() => {
    if (!state?.email || !state?.emailType) {
      showNotification("Acesso negado", "ERROR");
      navigate(-1);
    }
  }, [state, navigate]);

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {state?.title && (
          <Typography component="h1" align="center" variant="h5">
            {state?.title}
          </Typography>
        )}
        <Typography component="h1" variant="h5" />
        <Card sx={classes.root} variant="outlined">
          {showSuccess ? (
            <Lottie
              animationData={lotties.success}
              loop={true}
              style={{ width: 400, height: 200 }}
            />
          ) : (
            <>
              <Typography component="h1" align="center" variant="h5">
                Um código foi enviado para seu email
              </Typography>

              <OtpInput
                value={verificationCode}
                onChange={handleChange}
                numInputs={6}
                isInputNum
                inputStyle={classes.inputStyle}
                separator={<span>-</span>}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {resendCodeButton && (
                  <CountDown
                    initialValue={90}
                    onFinish={handleFinishCountDown}
                  />
                )}
                <Button
                  color="primary"
                  disabled={resendCodeButton}
                  onClick={handleClickResendButton}
                >
                  Reenviar código
                </Button>
              </Box>
              {isLoading ? (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!(verificationCode.length === 6)}
                  onClick={handleSubmit}
                  sx={classes.submit}
                >
                  Verificar código
                </Button>
              )}
            </>
          )}
        </Card>
      </Box>

      <Box mt={8}>
        <CopyRight />
      </Box>
    </Container>
  );
};

export default VerificationCode;
