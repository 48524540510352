import { useCallback, useState } from "react";

import listResourcesUseCase from "@app/features/Resource/domain/use-cases/listResourcesUseCase";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import { INVITE_STATUS } from "@app/constants/enums";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { TListResourcesSearchParams } from "@app/services/resource";

export type TInviteClasseViewModel = {
  classeId: number;
  inviteStatus: INVITE_STATUS;
};

const useListResourcesFormViewModel = (
  resourceRepository: IResourceRepository
) => {
  const [resources, setResources] = useState<IPaginationModel<IResourceModel>>({
    count: 0,
    data: [],
  });
  const [isLoading, setLoading] = useState(false);

  const listResources = useCallback(
    async (
      companyId: number,
      pageConfig: IPageModel,
      filterParams?: TListResourcesSearchParams
    ) => {
      try {
        setLoading(true);
        const data = await listResourcesUseCase(
          {
            listResources: resourceRepository.listResources,
          },
          companyId,
          pageConfig,
          filterParams
        );
        setResources(data);
        setLoading(false);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.listResources]
  );

  return { isLoading, resources, listResources };
};

export { useListResourcesFormViewModel };
