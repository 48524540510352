import { useCallback, useState } from "react";
import { IUserRepository } from "@app/features/User/data/userRepository";
import { forgotPasswordUseCase } from "@app/features/ForgotPassword/domain/useCases/forgotPasswordUseCase";
import handleApplicationError from "@app/handlers/handleApiError";
import { useNavigate } from "react-router-dom";
import showNotification from "@app/components/molecules/Toast";

const useForgotPasswordViewModel = (repository: IUserRepository) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const sendEmail = useCallback(
    async (email: string) => {
      try {
        setLoading(true);
        await forgotPasswordUseCase(
          { forgotPassword: repository.forgotPassword },
          email
        );
        setLoading(false);
        showNotification(
          "Um Email foi enviado com as instruções para alteração de senha",
          "SUCCESS"
        );
        navigate("/login");
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.forgotPassword, navigate]
  );

  return {
    sendEmail,
    loading,
  };
};

export { useForgotPasswordViewModel };
