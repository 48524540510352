import React, {
  RefObject,
  createRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import Drawer, { IDrawerRef } from "@app/components/molecules/Drawer";
import { Headline } from "@app/components/atoms/Text";

export type IDrawerFilterRef = {
  openDrawer: () => void;
  closeDrawer: () => void;
};

type Field = {
  id: string;
  type: "TEXT" | "SELECT";
  label: string;
  options?: { label: any; value: any }[];
};

export type TFilterPayload = {
  id: string;
  label: string;
  value: any;
};

type DrawerFilterProps = {
  ref?: IDrawerFilterRef;
  fields: Field[];
  onFilter: (values: TFilterPayload[]) => void;
};

const DrawerFilter = forwardRef<unknown, DrawerFilterProps>(
  ({ fields, onFilter }: DrawerFilterProps, ref) => {
    const drawerRef = useRef<IDrawerRef>();

    const fieldsRef = useRef<RefObject<TextFieldProps>[]>(
      fields.map(() => createRef<TextFieldProps>())
    );

    const openDrawer = useCallback(() => {
      drawerRef.current?.openDrawer();
    }, []);

    const closeDrawer = useCallback(() => {
      drawerRef.current?.closeDrawer();
    }, []);

    useImperativeHandle(ref, () => ({ openDrawer, closeDrawer }), [
      openDrawer,
      closeDrawer,
    ]);

    const handleFilter = () => {
      const filterValues: TFilterPayload[] = [];

      fieldsRef.current.forEach((ref, index) => {
        if (ref.current && ref.current.value) {
          const value: TFilterPayload = {
            id: fields[index].id,
            label: fields[index].label,
            value: ref.current.value,
          };

          filterValues.push(value);
        }
      });

      onFilter(filterValues);
      closeDrawer();
    };

    const getFields = () => {
      return fields.map((field, index) => {
        switch (field.type) {
          case "TEXT":
            return (
              <TextField
                inputRef={fieldsRef.current[index]}
                id={field.id}
                label={field.label}
                fullWidth
                margin="normal"
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
              />
            );
          default:
            return (
              <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
                <InputLabel>{field.label}</InputLabel>
                <Select
                  inputRef={fieldsRef.current[index]}
                  id={field.id}
                  label={field.label}
                >
                  {field.options?.map((option) => (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
        }
      });
    };

    return (
      <Drawer ref={drawerRef} drawerPosition="right">
        <Box sx={{ pt: 12, pl: 2, pr: 2, width: "50vh", pb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={handleFilter}>
                  Filtrar
                </Button>
                <Button variant="text" onClick={closeDrawer}>
                  Cancelar
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="center">
              <Headline>Filtro</Headline>
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="center">
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12}>
              {getFields()}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    );
  }
);

export default DrawerFilter;
