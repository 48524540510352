import { useCallback, useState } from "react";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import listUsersFromClasseUseCase from "@app/features/Classe/domain/use-cases/listUsersFromClasseUseCase";
import removeUserFromClasseUseCase from "@app/features/Classe/domain/use-cases/removeUserFromClasseUseCase";

import IUserModel from "@app/features/User/domain/models/IUserModel";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

import { TListUsersFromClasseSearchParams } from "@app/services/classe";

import handleApplicationError from "@app/handlers/handleApiError";
import { ROLE } from "@app/constants/enums";

const useListUsersViewModel = (repository: IClasseRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState<IPaginationModel<IUserModel>>({
    count: 0,
    data: [],
  });

  const listUserFromClasse = useCallback(
    async (
      classeId: number,
      pageConfig: IPageModel,
      filterParams?: TListUsersFromClasseSearchParams
    ) => {
      try {
        setLoading(true);

        const result = await listUsersFromClasseUseCase(
          {
            listUsersFromClasse: repository.listUsersFromClasse,
          },
          classeId,
          [ROLE.ADMIN, ROLE.OWNER, ROLE.TEACHER],
          pageConfig,
          filterParams
        );
        setUsers(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.listUsersFromClasse]
  );

  const removeUserFromClasse = useCallback(
    async (classeId: number, userId: number, pageConfig: IPageModel) => {
      try {
        setLoading(true);

        await removeUserFromClasseUseCase(
          {
            removeUsersFromClasse: repository.removeUsersFromClasse,
          },
          classeId,
          userId
        );
        setLoading(false);
        listUserFromClasse(classeId, pageConfig);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.removeUsersFromClasse, listUserFromClasse]
  );

  return { users, isLoading, listUserFromClasse, removeUserFromClasse };
};

export { useListUsersViewModel };
