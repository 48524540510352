import React, { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import resourceService from "@app/services/resource";
import { useEditResourceViewModel } from "@app/features/Resource/views/Edit/editResourceViewModel";

import EditResourceForm, {
  TEditResourceForm,
} from "@app/features/Resource/views/Form/EditResourceForm";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";
import StandardTemplate from "@app/components/templates/Standard";

const EditResourse = () => {
  const { id } = useParams();

  const resourceRepository = useResourceRepository(resourceService);
  const { getResource, editResource, isLoading, resource } =
    useEditResourceViewModel(resourceRepository);

  const handleValidateEditResourceSuccess = useCallback(
    (data: TEditResourceForm) => {
      editResource({ ...data, id: Number(id) });
    },
    [editResource, id]
  );

  useEffect(() => {
    if (id) {
      getResource(Number(id));
    }
  }, [getResource, id]);

  return (
    <StandardTemplate title="Editar recurso" showBackButton>
      <LoadingLock isLoading={isLoading} />

      <EditResourceForm
        dataForm={resource}
        onValidateSuccess={handleValidateEditResourceSuccess}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default EditResourse;
