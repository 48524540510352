import React, { ReactNode, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";

import Lottie from "lottie-react";
import { FiEdit, FiTrash2, FiMenu } from "react-icons/fi";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import theme from "@app/config/theme";
import { lotties } from "@app/assets";
import { Subtitle } from "@app/components/atoms/Text";
import { IOrderModel } from "@app/constants/interfaces";
import AlertDialog from "@app/components/molecules/AlertDialog";

export interface Column {
  id: string;
  label: string;
  width?: number;
  component?: "TEXT" | "AVATAR" | "CUSTOM";
  customComponent?: (data: { [key: string]: any }) => ReactNode;
  align?: "left" | "center" | "right";
  format?: (value: number) => string;
}

type StickyHeaderTableProps<T> = {
  pagination?: boolean;
  identifierRowIdKey?: string;
  identifierColumnKey: string;
  allowReordering?: boolean;
  columns: Column[];
  emptyMessage?: string;
  showHeader?: boolean;
  rows: T[];
  count: number;
  dialogMessage?: { title: string; description: string };
  onEdit?: (data: T) => void;
  onDelete?: (data: T) => void;
  onPageChange?: (newPage: number, rowsPerPage: number) => void;
  onOrderChange?: (data: T[], item: T, orderEvent: IOrderModel) => void;
};

const StickyHeaderTable = <T extends { [key: string]: any }>({
  columns,
  rows,
  count,
  identifierColumnKey,
  identifierRowIdKey,
  allowReordering,
  pagination,
  dialogMessage,
  emptyMessage = "Nenhum item encontrado.",
  showHeader = true,
  onDelete,
  onEdit,
  onPageChange,
  onOrderChange,
}: StickyHeaderTableProps<T>) => {
  const [data, setData] = useState<T[]>(rows);
  const [page, setPage] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS.MIN);
  const [selectedItem, setSelectedItem] = useState<T>();

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
    if (onPageChange) onPageChange(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newRowsPerPage = Number(event.target.value);
    setRowsPerPage(newRowsPerPage);
    if (onPageChange) onPageChange(0, newRowsPerPage);
    setPage(0);
  };

  const handleDragEnd = (event: DropResult) => {
    if (!event.destination) return;
    const result = Array.from(data);
    const [removed] = result.splice(event.source.index, 1);
    result.splice(event.destination.index, 0, removed);
    setData([...result]);

    if (onOrderChange) {
      onOrderChange(result, removed, {
        source: event.source.index + 1,
        destination: event.destination.index + 1,
      });
    }
  };

  const getCellComponent = (row: { [key: string]: any }, column: Column) => {
    switch (column.component) {
      case "AVATAR":
        return (
          <Avatar alt="Image" src={row[column.id]}>
            {row[column.label]}
          </Avatar>
        );

      case "CUSTOM":
        if (column.customComponent) {
          return column.customComponent(row);
        }
        return row[column.id];

      default:
        const value = row[column.id];
        if (column.format) {
          return column.format(value);
        }
        return row[column.id];
    }
  };

  const handleAgreeDialog = () => {
    if (selectedItem) {
      onDelete?.(selectedItem);
    }
    setSelectedItem(undefined);
    setOpenDeleteDialog(false);
  };

  const handleDisagreeDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedItem(undefined);
  };

  const handleShowDialog = (data: T) => {
    setOpenDeleteDialog(true);
    setSelectedItem(data);
  };

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <AlertDialog
        open={openDeleteDialog}
        onAgree={handleAgreeDialog}
        onDisagree={handleDisagreeDialog}
        title={dialogMessage?.title || "Remover item"}
        description={
          dialogMessage?.description ||
          "Tem certeza que deseja remover este item?"
        }
      />
      <TableContainer sx={{ maxHeight: "62vh" }}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table stickyHeader aria-label="sticky table">
            {showHeader && (
              <TableHead>
                <TableRow>
                  {allowReordering && (
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.common.black,
                        color: theme.palette.common.white,
                      }}
                    />
                  )}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        width: column.width,
                        backgroundColor: theme.palette.common.black,
                        color: theme.palette.common.white,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {!!onEdit && (
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.common.black,
                        color: theme.palette.common.white,
                      }}
                    />
                  )}

                  {!!onDelete && (
                    <TableCell
                      style={{
                        backgroundColor: theme.palette.common.black,
                        color: theme.palette.common.white,
                      }}
                    />
                  )}
                </TableRow>
              </TableHead>
            )}

            <Droppable droppableId="tbody">
              {(provider) => (
                <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                  {data.map((row, index) => (
                    <Draggable
                      key={index}
                      index={index}
                      isDragDisabled={!allowReordering}
                      draggableId={String(index)}
                    >
                      {(providedDraggable) => (
                        <TableRow
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          hover
                          sx={{ backgroundColor: "#FFF" }}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          {allowReordering && (
                            <TableCell
                              align="left"
                              style={{
                                width: 10,
                              }}
                            >
                              <FiMenu />
                            </TableCell>
                          )}
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                width: column.width,
                              }}
                            >
                              {getCellComponent(row, column)}
                            </TableCell>
                          ))}
                          {!!onEdit && (
                            <TableCell align="right">
                              <IconButton
                                color="secondary"
                                component="span"
                                size="small"
                                disabled={
                                  !row[
                                    identifierRowIdKey || identifierColumnKey
                                  ]
                                }
                                onClick={() => onEdit(row)}
                              >
                                <FiEdit />
                              </IconButton>
                            </TableCell>
                          )}
                          {!!onDelete && (
                            <TableCell align="right" width="5%">
                              <IconButton
                                color="primary"
                                component="span"
                                size="small"
                                onClick={() => handleShowDialog(row)}
                              >
                                <FiTrash2 />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </TableContainer>
      {!rows.length && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Lottie
            animationData={lotties.emptyState}
            loop={true}
            style={{
              width: 400,
              height: 200,
            }}
          />
          {emptyMessage && <Subtitle>{emptyMessage}</Subtitle>}
        </Box>
      )}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={Object.values(ROWS_PER_PAGE_OPTIONS)}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default StickyHeaderTable;
