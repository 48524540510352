import { useCallback, useState } from "react";

import listResourcesUseCase from "@app/features/Resource/domain/use-cases/listResourcesUseCase";
import deleteResourceUseCase from "@app/features/Resource/domain/use-cases/deleteResourceUseCase";

import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import { INVITE_STATUS } from "@app/constants/enums";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { TListResourcesSearchParams } from "@app/services/resource";
import showNotification from "@app/components/molecules/Toast";
import getAvailableLanguagesUseCase from "@app/features/Language/domain/use-cases/getAvailableLanguagesUseCase";
import { ILanguageRepository } from "@app/features/Language/data/languageRepository";

export type TInviteClasseViewModel = {
  classeId: number;
  inviteStatus: INVITE_STATUS;
};

const useListResourcesViewModel = (
  resourceRepository: IResourceRepository,
  languageRepository: ILanguageRepository
) => {
  const [resources, setResources] = useState<IPaginationModel<IResourceModel>>({
    count: 0,
    data: [],
  });
  const [isLoading, setLoading] = useState(false);
  const [languages, setLanguages] = useState<
    { label: string; value: string }[]
  >([]);

  const listResources = useCallback(
    async (
      companyId: number,
      pageConfig: IPageModel,
      filterParams?: TListResourcesSearchParams
    ) => {
      try {
        setLoading(true);
        const data = await listResourcesUseCase(
          {
            listResources: resourceRepository.listResources,
          },
          companyId,
          pageConfig,
          filterParams
        );
        setResources(data);
        setLoading(false);
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.listResources]
  );

  const deleteResource = useCallback(
    async (resourceId: number, companyId: number, pageConfig: IPageModel) => {
      try {
        setLoading(true);
        await deleteResourceUseCase(
          {
            deleteResource: resourceRepository.deleteResource,
          },
          resourceId
        );
        showNotification("Recurso removido com sucesso", "SUCCESS");
        listResources(companyId, pageConfig);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.deleteResource, listResources]
  );

  const getAvailableLanguages = useCallback(async () => {
    try {
      setLoading(true);

      const data = await getAvailableLanguagesUseCase({
        getAvailableLanguages: languageRepository.getAvailableLanguages,
      });

      const laguangeOptions = data.map(
        (item): { label: string; value: string } => {
          return {
            label: item.name,
            value: item.key,
          };
        }
      );
      setLanguages(laguangeOptions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApplicationError.handleError(error);
    }
  }, [languageRepository.getAvailableLanguages]);

  return {
    isLoading,
    resources,
    languages,
    listResources,
    deleteResource,
    getAvailableLanguages,
  };
};

export { useListResourcesViewModel };
