import { Action, Dispatch } from "redux";

import {
  LOADING_COMPANY,
  SET_CURRENT_COMPANY,
  COMPANIES,
} from "@app/features/Company/data/companyActionsType";

import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";
import Storage from "@app/config/storage";

export const setCurrentCompanyAction =
  (company: ICompanyModel) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: SET_CURRENT_COMPANY, payload: company });
    Storage.set(JSON.stringify(company), "@SHARPII:currentCompany");
  };

export const setCompaniesAction =
  (companies: ICompanyModel[]) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: COMPANIES, payload: companies });
  };

export const setLoadingCompanyAction =
  (value: boolean) =>
  (dispatch: Dispatch<Action>): Action =>
    dispatch({ type: LOADING_COMPANY, payload: value });
