import React, { ReactNode } from "react";

import BaseText, { BaseTextProps } from "../Base";

type SubtitleProps = {
  children: ReactNode;
} & BaseTextProps;

const Subtitle = ({
  children,
  align,
  noWrap,
  weight,
  color,
}: SubtitleProps) => (
  <BaseText
    size="subtitle1"
    align={align}
    noWrap={noWrap}
    color={color}
    weight={weight}
  >
    {children}
  </BaseText>
);

export default Subtitle;
