import api from "@app/config/api";

import IResourceEntity from "@app/features/Resource/data/IResourceEntity";
import IYoutubeInfoEntity from "@app/features/Resource/data/IYoutubeInfoEntity";

import { TCreateResourceRemoteData } from "@app/services/resource/remote-types/TCreateResourceRemoteData";
import { TEditResourceRemoteData } from "@app/services/resource/remote-types/TEditResourceRemoteData";

import API_SERVICES from "@app/services/constants";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { RESOURCE_TYPE } from "@app/constants/enums";
import axios from "axios";

export type TListResourcesSearchParams = {
  name?: string;
  language?: { key: string };
  type?: RESOURCE_TYPE[];
};

export interface IResourceService {
  listResourcesService: (
    companyId: number,
    pageConfig: IPageModel,
    filterParams?: TListResourcesSearchParams
  ) => Promise<IPaginationModel<IResourceEntity>>;
  createResourcesService: (
    data: TCreateResourceRemoteData[],
    companyId: number
  ) => Promise<IResourceEntity[]>;
  deleteResourceService: (resourceId: number) => Promise<void>;
  getResourceService: (resourceId: number) => Promise<IResourceEntity>;
  getYoutubeInfoService: (youtubeUrl: string) => Promise<IYoutubeInfoEntity>;
  editResourceService: (data: TEditResourceRemoteData) => Promise<void>;
}

const listResourcesService = async (
  companyId: number,
  pageConfig: IPageModel,
  filterParams?: TListResourcesSearchParams
): Promise<IPaginationModel<IResourceEntity>> =>
  api
    .get(API_SERVICES.RESOURCE_SERVICES.LIST_RESOURCES(companyId), {
      params: { ...pageConfig, ...filterParams },
    })
    .then((res) => res.data);

const createResourcesService = async (
  data: TCreateResourceRemoteData[],
  companyId: number
): Promise<IResourceEntity[]> =>
  api
    .post(API_SERVICES.RESOURCE_SERVICES.CREATE_RESOURCES(companyId), data)
    .then((res) => res.data);

const deleteResourceService = async (resourceId: number): Promise<void> =>
  api
    .delete(API_SERVICES.RESOURCE_SERVICES.DELETE_RESOURCE(resourceId))
    .then((res) => res.data);

const getResourceService = async (
  resourceId: number
): Promise<IResourceEntity> =>
  api
    .get(API_SERVICES.RESOURCE_SERVICES.GET_RESOURCE(resourceId))
    .then((res) => res.data);

const editResourceService = async (
  data: TEditResourceRemoteData
): Promise<void> =>
  api
    .put(API_SERVICES.RESOURCE_SERVICES.EDIT_RESOURCE(data.id), data)
    .then((res) => res.data);

const getYoutubeInfoService = async (
  videoId: string
): Promise<IYoutubeInfoEntity> =>
  axios
    .get(API_SERVICES.RESOURCE_SERVICES.GET_YOUTUBE_INFO(videoId))
    .then((res) => res.data);

const resourceService: IResourceService = {
  listResourcesService,
  createResourcesService,
  deleteResourceService,
  getResourceService,
  getYoutubeInfoService,
  editResourceService,
};

export default resourceService;
