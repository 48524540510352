import { useCallback, useState } from "react";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import listStudentsFromClasseUseCase from "@app/features/Student/domain/use-cases/listStudentsFromClasseUseCase";
import removeUserFromClasseUseCase from "@app/features/Student/domain/use-cases/removeUserFromClasseUseCase";

import IUserModel from "@app/features/User/domain/models/IUserModel";
import IPaginationModel, { IPageModel } from "@app/constants/interfaces";

import { TListUsersFromClasseSearchParams } from "@app/services/classe";

import handleApplicationError from "@app/handlers/handleApiError";
import { ROLE } from "@app/constants/enums";

const useListStudentsViewModel = (repository: IClasseRepository) => {
  const [isLoading, setLoading] = useState(false);
  const [students, setStudents] = useState<IPaginationModel<IUserModel>>({
    count: 0,
    data: [],
  });

  const listStudentsFromClasse = useCallback(
    async (
      classeId: number,
      pageConfig: IPageModel,
      filterParams?: TListUsersFromClasseSearchParams
    ) => {
      try {
        setLoading(true);

        const result = await listStudentsFromClasseUseCase(
          {
            listUsersFromClasse: repository.listUsersFromClasse,
          },
          classeId,
          [ROLE.STUDENT],
          pageConfig,
          filterParams
        );
        setStudents(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.listUsersFromClasse]
  );

  const removeStudentsFromClasse = useCallback(
    async (classeId: number, userId: number, pageConfig: IPageModel) => {
      try {
        setLoading(true);

        await removeUserFromClasseUseCase(
          {
            removeUsersFromClasse: repository.removeUsersFromClasse,
          },
          classeId,
          userId
        );
        setLoading(false);
        listStudentsFromClasse(classeId, pageConfig);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.removeUsersFromClasse, listStudentsFromClasse]
  );

  return {
    students,
    isLoading,
    listStudentsFromClasse,
    removeStudentsFromClasse,
  };
};

export { useListStudentsViewModel };
