import React from "react";

import Button from "@mui/material/Button";

import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import theme from "@app/config/theme";

type FooterActionsProps = {
  onCancel?: () => void;
  onSave?: () => void;
  onSaveAndBack?: () => void;
  cancelLabel?: string;
  saveLabel?: string;
  showSaveAndBack?: boolean;
};

const FooterActions = ({
  onCancel,
  onSave,
  onSaveAndBack,
  cancelLabel,
  saveLabel,
  showSaveAndBack,
}: FooterActionsProps) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
    }
  };

  const handleSaveBack = () => {
    if (onSaveAndBack) {
      onSaveAndBack();
      navigate(-1);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{
        position: "sticky",
        bottom: 0,
        display: "flex",
        alignItems: "center",
        padding: 2,
        backgroundColor: "white",
        width: "100%",
        borderColor: theme.palette.grey[100],
        borderWidth: 1,
        borderRadius: 2,
        borderStyle: "solid",
        boxShadow: 5,
        justifyContent: "space-between",
        mt: 2,
      }}
    >
      <Grid item xs={6} sm={6} md={6}>
        <Button onClick={handleCancel} variant="outlined" color="error">
          {cancelLabel}
        </Button>
      </Grid>

      <Grid
        container
        direction="row"
        xs={12}
        sm={6}
        md={6}
        justifyContent="flex-end"
      >
        <Stack direction="row" spacing={2}>
          {(onSaveAndBack || showSaveAndBack) && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSaveBack}
              type="submit"
            >
              Salvar e voltar
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            type="submit"
          >
            {saveLabel}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

FooterActions.defaultProps = {
  onCancel: null,
  onSaveAndBack: null,
  cancelLabel: "Cancelar",
  saveLabel: "Salvar",
  onSave: null,
};

export default React.memo(FooterActions);
