import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";
import * as yup from "yup";
import cep from "cep-promise";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, IconButton, InputAdornment, Paper, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import showNotification from "@app/components/molecules/Toast";
import UploadAsset from "@app/components/molecules/UploadAsset";
import { TUploadPayload } from "@app/components/atoms/Upload";

export type TCompanyForm = {
  companyImage: string;
  companyName: string;
  tradingName: string;
  document: string;
  country: string;
  state: string;
  city: string;
  address: string;
  addressNumber: string;
  zipcode: string;
  phone: string;
};

type CompanyFormProps = {
  footerActions: ReactNode;
  onValidateSuccess: (data: TCompanyForm) => void;
  dataForm?: TCompanyForm;
};

const CompanyForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
}: CompanyFormProps) => {
  const [thumbnail, setThumbnail] = useState(dataForm?.companyImage);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        companyImage: yup.string().required("Escolha uma image"),
        companyName: yup.string().required("Preencha o campo Razão Social"),
        tradingName: yup.string(),
        document: yup.string(),
        country: yup.string().required("Escolha o País"),
        state: yup.string().required("Escolha um estado"),
        city: yup.string().required("Escolha uma cidade"),
        address: yup.string().required("Preencha o campo endereço"),
        addressNumber: yup.string().required("Preencha o campo número"),
        zipcode: yup.string().required("Preencha o campo cep"),
        phone: yup.string().required("Preencha o campo telefone"),
      })
    ),
    defaultValues: {
      companyImage: "",
      companyName: "",
      tradingName: "",
      document: "",
      country: "Brasil",
      state: "",
      city: "",
      address: "",
      addressNumber: "",
      zipcode: "",
      phone: "",
    },
    mode: "onSubmit",
  });
  const handleUploadSuccess = useCallback(
    (data: TUploadPayload) => {
      setValue("companyImage", data.fileKey);
      setThumbnail(data.url);
    },
    [setValue]
  );

  const handleRemoveUpload = useCallback(() => {
    setValue("companyImage", "");
    setThumbnail(undefined);
  }, [setValue]);

  const onSubmit = (data: TCompanyForm) => {
    onValidateSuccess(data);
  };

  const handleChangeZipcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("zipcode", event.currentTarget.value);
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("phone", event.currentTarget.value);
  };

  const handleChangeDocument = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("document", event.currentTarget.value);
  };

  const [zipcode, document, phone] = watch(["zipcode", "document", "phone"]);

  const getDataFromZipcode = useCallback(async () => {
    try {
      const data = await cep(zipcode);
      setValue("address", `${data.street} - ${data.neighborhood}`);
      setValue("city", data.city);
      setValue("state", data.state);
    } catch (error) {
      showNotification("Cep não encontrado", "WARNING");
    }
  }, [setValue, zipcode]);

  useEffect(() => {
    const zipcodeFormated = zipcode.replace(/[^a-zA-Z0-9]/g, "");
    if (zipcodeFormated.length === 8) {
      getDataFromZipcode();
    }
  }, [zipcode, getDataFromZipcode]);

  useEffect(() => {
    reset(dataForm);
    setThumbnail(dataForm?.companyImage);
  }, [dataForm, reset]);

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <UploadAsset
              accept={{ image: true }}
              destination="COMPANY"
              label="Escolha uma imagem para sua empresa"
              url={thumbnail}
              error={!!errors.companyImage}
              errorMessage={errors.companyImage?.message}
              onRemove={handleRemoveUpload}
              onUploadSuccess={handleUploadSuccess}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="companyName"
              type="text"
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="Defina o nome da sua area"
              InputLabelProps={{ shrink: true }}
              error={!!errors.companyName}
              helperText={!!errors.companyName && errors.companyName.message}
              {...register("companyName")}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              id="tradingName"
              fullWidth
              margin="normal"
              variant="outlined"
              label="Nome Fantasia"
              InputLabelProps={{ shrink: true }}
              {...register("tradingName")}
              error={!!errors.tradingName}
              helperText={!!errors.tradingName && errors.tradingName.message}
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="99.999.999/0001-99"
              value={document}
              onChange={handleChangeDocument}
            >
              <TextField
                id="document"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Esse campo não é obrigatório, CNPJ será usado na nota fiscal, caso fique vazio será usado o seu CPF">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                variant="outlined"
                label="CNPJ"
                InputLabelProps={{ shrink: true }}
                error={!!errors.document}
                helperText={!!errors.document && errors.document.message}
              />
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="country"
              fullWidth
              margin="normal"
              disabled
              variant="outlined"
              label="País"
              InputLabelProps={{ shrink: true }}
              {...register("country")}
              error={!!errors.country}
              helperText={!!errors.country && errors.country.message}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputMask
              mask="99.999-999"
              value={zipcode}
              onChange={handleChangeZipcode}
            >
              <TextField
                id="cep"
                fullWidth
                required
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                label="Cep"
                error={!!errors.zipcode}
                helperText={!!errors.zipcode && errors.zipcode.message}
              />
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="state"
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="Estado"
              InputLabelProps={{ shrink: true }}
              {...register("state")}
              error={!!errors.state}
              helperText={!!errors.state && errors.state.message}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="city"
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="Cidade"
              InputLabelProps={{ shrink: true }}
              {...register("city")}
              error={!!errors.city}
              helperText={!!errors.city && errors.city.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="address"
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="Endereço"
              InputLabelProps={{ shrink: true }}
              {...register("address")}
              error={!!errors.address}
              helperText={!!errors.address && errors.address.message}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="addressNumber"
              fullWidth
              required
              margin="normal"
              variant="outlined"
              label="Número"
              InputLabelProps={{ shrink: true }}
              {...register("addressNumber")}
              error={!!errors.addressNumber}
              helperText={
                !!errors.addressNumber && errors.addressNumber.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              mask="(99) 99999-9999"
              value={phone}
              onChange={handleChangePhone}
            >
              <TextField
                id="phone"
                fullWidth
                required
                margin="normal"
                variant="outlined"
                label="Telefone"
                InputLabelProps={{ shrink: true }}
                error={!!errors.phone}
                helperText={!!errors.phone && errors.phone.message}
              />
            </InputMask>
          </Grid>
        </Grid>
        <Box>{footerActions}</Box>
      </Paper>
    </form>
  );
};

export default CompanyForm;
