import { ILanguageRepository } from "@app/features/Language/data/languageRepository";

import ILanguageModel from "@app/features/Language/domain/models/ILanguageModel";
import ILanguageEntity from "@app/features/Language/data/ILanguageEntity";

const getAvailableLanguagesUseCase = async (
  repository: Pick<ILanguageRepository, "getAvailableLanguages">
): Promise<ILanguageModel[]> => {
  const languagesEntity = await repository.getAvailableLanguages();

  const languagesModel = mapToDomain(languagesEntity);

  return languagesModel;
};

const mapToDomain = (entities: ILanguageEntity[]): ILanguageModel[] => {
  const languagesModel = entities.map((language) => language);
  return languagesModel;
};

export default getAvailableLanguagesUseCase;
