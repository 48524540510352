import React from "react";

type AvatarFormProps = {
  imageSrc?: string;
};

const AvatarForm = ({ imageSrc }: AvatarFormProps) => {
  return (
    <img
      alt="img"
      src={imageSrc}
      style={{
        width: 150,
        height: 150,
        borderRadius: 10,
        marginBottom: 10,
        objectFit: "cover",
      }}
    />
  );
};

export default AvatarForm;
