import React, { useCallback, useEffect } from "react";

import ProfileForm, { TProfileForm } from "@app/features/Profile/view/Form";

import { useEditProfileViewModel } from "@app/features/Profile/view/Edit/editProfileViewModel";
import { useProfileRepository } from "@app/features/Profile/data/profileRepository";

import profileServices from "@app/services/profile";

import StandardTemplate from "@app/components/templates/Standard";
import FooterActions from "@app/components/molecules/FooterActions";
import LoadingLock from "@app/components/molecules/LoadingLock";

const EditProfile = () => {
  const profileRepository = useProfileRepository(profileServices);
  const { editProfile, getProfile, profile, isLoading } =
    useEditProfileViewModel(profileRepository);

  const handleSave = useCallback(
    (data: TProfileForm) => {
      const { email, name, document, photo } = data;
      editProfile({ document, email, name, photo });
    },
    [editProfile]
  );

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <StandardTemplate title="Editar perfil" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <ProfileForm
        dataForm={profile}
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default EditProfile;
