import React, { ReactNode } from "react";

import BaseText, { BaseTextProps } from "../Base";

type TitleProps = {
  children: ReactNode;
} & BaseTextProps;

const Title = ({ children, align, noWrap, color, weight }: TitleProps) => (
  <BaseText
    size="h5"
    align={align}
    noWrap={noWrap}
    color={color}
    weight={weight}
  >
    {children}
  </BaseText>
);

export default Title;
