import React, { ReactNode, useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Grid,
  TextField,
  Paper,
  Box,
  MenuItem,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Card,
  CardContent,
  Typography,
  Checkbox,
} from "@mui/material";
import { USER_CLASSE_STATUS } from "@app/constants/enums";
import SelectUseForm from "@app/components/organisms/SelectUseForm";
import { USER_CLASSE_STATUS_OPTIONS } from "@app/constants/optionsSelect";
import InputMask from "react-input-mask";

export type TStudentForm = {
  name: string;
  email: string;
  birthDate?: string;
  phone?: string;
  expireAt?: string;
  userStatusClasse: USER_CLASSE_STATUS;
};

type StudentFormProps = {
  onValidateSuccess: (data: TStudentForm) => void;
  footerActions: ReactNode;
  dataForm?: TStudentForm;
  edit?: boolean;
};

const StudentForm = ({
  onValidateSuccess,
  footerActions,
  dataForm,
  edit,
}: StudentFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Preencha o campo nome"),
        birthDate: yup.string().optional(),
        phone: yup.string().optional().nullable(),
        email: yup
          .string()
          .required("Preencha o campo email")
          .email("Email inválido"),
        userStatusClasse: yup.mixed().when({
          is: edit,
          then: yup
            .mixed()
            .oneOf([
              USER_CLASSE_STATUS.ACTIVE,
              USER_CLASSE_STATUS.INACTIVE,
              USER_CLASSE_STATUS.INVITED,
            ]),
          otherwise: yup.mixed().optional(),
        }),
        expireAt: yup.string().optional(),
      })
    ),
    defaultValues: {
      name: "",
      email: "",
      birthDate: "",
      phone: "",
      userStatusClasse: "" as USER_CLASSE_STATUS,
      expireAt: "",
    },
    mode: "onSubmit",
  });

  const [expireStudentAccess, setExpireStudentAccess] = useState(false);

  const [birthDate, phone] = watch(["birthDate", "phone"]);

  const handleChangeBirthDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue("birthDate", event.currentTarget.value);
    },
    [setValue]
  );

  const handleChangePhone = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue("phone", event.currentTarget.value);
    },
    [setValue]
  );

  const handleChangeExpireAtCheckBox = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setExpireStudentAccess(checked);
      setValue("expireAt", "");
    },
    [setValue]
  );

  // MARK: - useCallback

  const onSubmit = useCallback(
    (data: TStudentForm) => {
      onValidateSuccess(data);
    },
    [onValidateSuccess]
  );

  // MARK: - useEffect

  useEffect(() => {
    setExpireStudentAccess(!!dataForm?.expireAt);
    reset(dataForm);
    if (dataForm) {
      const { expireAt } = dataForm;
      if (expireAt) {
        setValue("expireAt", new Date(expireAt).toISOString().substring(0, 10));
      }
    }
  }, [dataForm, reset, setValue]);

  // MARK: - view

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={edit ? 4 : 6}>
            <TextField
              id="name"
              type="text"
              fullWidth
              disabled={
                edit &&
                getValues("userStatusClasse") !== USER_CLASSE_STATUS.INACTIVE
              }
              margin="normal"
              variant="outlined"
              label="Nome"
              placeholder="Nome do aluno"
              InputLabelProps={{ shrink: true }}
              error={!!errors.name}
              helperText={!!errors.name && errors.name.message}
              {...register("name")}
            />
          </Grid>
          <Grid item xs={12} sm={edit ? 4 : 6}>
            <TextField
              id="email"
              fullWidth
              disabled={
                edit &&
                getValues("userStatusClasse") !== USER_CLASSE_STATUS.INACTIVE
              }
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Email"
              placeholder="Email do aluno"
              error={!!errors.email}
              helperText={!!errors.email && errors.email.message}
              {...register("email")}
            />
          </Grid>
          {edit && (
            <Grid item xs={12} sm={4}>
              <SelectUseForm
                error={!!errors.userStatusClasse}
                helperText={errors.userStatusClasse?.message}
                control={control}
                disabled={
                  edit &&
                  getValues("userStatusClasse") === USER_CLASSE_STATUS.INVITED
                }
                label="Status do usuário"
                defaultValue={String(getValues("userStatusClasse"))}
                {...register("userStatusClasse")}
              >
                <MenuItem value="">Selecione o status do usuário</MenuItem>
                {USER_CLASSE_STATUS_OPTIONS.map((status) => (
                  <MenuItem value={status.value} key={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </SelectUseForm>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="99/99/9999"
              value={birthDate}
              onChange={handleChangeBirthDate}
              disabled={
                edit &&
                getValues("userStatusClasse") !== USER_CLASSE_STATUS.INACTIVE
              }
            >
              <TextField
                id="email"
                label="Data de nascimento"
                fullWidth
                margin="normal"
                placeholder="Data de nascimento"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                error={!!errors.email}
                helperText={!!errors.email && errors.email.message}
              />
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="(99) 99999-9999"
              value={phone}
              onChange={handleChangePhone}
              disabled={
                edit &&
                getValues("userStatusClasse") !== USER_CLASSE_STATUS.INACTIVE
              }
            >
              <TextField
                id="phone"
                fullWidth
                margin="normal"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                label="Telefone de contato"
                placeholder="(99) 99999-9999"
                error={!!errors.phone}
                helperText={!!errors.phone && errors.phone.message}
              />
            </InputMask>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 3, mt: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <FormLabel component="legend">Configurações adicionais</FormLabel>
            <Grid item xs={6} sm={3}>
              <Card sx={{ minHeight: 260 }}>
                <CardContent>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={expireStudentAccess}
                          onChange={handleChangeExpireAtCheckBox}
                        />
                      }
                      label="Permitir acesso até:"
                    />
                  </FormGroup>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Ao selecionar uma data, após o periódo da data selecionada o
                    aluno perderá o acesso a turma
                  </Typography>
                  <TextField
                    id="expireAt"
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    disabled={!expireStudentAccess}
                    label="Bloquear acesso após:"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.expireAt}
                    helperText={!!errors.expireAt && !!errors.expireAt.message}
                    {...register("expireAt")}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ mt: 10 }}>{footerActions}</Box>
    </form>
  );
};

export default StudentForm;
