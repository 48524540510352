import { Theme } from "@mui/material/styles";

import themeInstance from "@app/config/theme";

const styles = (theme: Theme = themeInstance) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
});

export default styles;
