import ILessonEntity from "@app/features/Lesson/data/ILessonEntity";
import IAnswerResultEntity from "@app/features/Lesson/data/IAnswerResultEntity";

import { TCreateLessonRemoteData } from "@app/services/lesson/remote-types/TCreateLessonRemoteData";
import { TEditLessonRemoteData } from "@app/services/lesson/remote-types/TEditLessonRemoteData";

import api from "@app/config/api";
import API_SERVICES from "@app/services/constants";

export interface ILessonService {
  createLessonService: (data: TCreateLessonRemoteData) => Promise<void>;
  removeLessonService: (classeId: number, lessonId: number) => Promise<void>;
  getLessonService: (lessonId: number) => Promise<ILessonEntity>;
  editLessonService: (data: TEditLessonRemoteData) => Promise<void>;
  getAnswerFromStudentService: (
    studentPageId: number
  ) => Promise<IAnswerResultEntity[]>;
}

const createLessonService = async (
  data: TCreateLessonRemoteData
): Promise<void> =>
  api.post(API_SERVICES.LESSON_SERVICES.CREATE, data).then((res) => res.data);

const removeLessonService = async (lessonId: number): Promise<void> =>
  api
    .delete(API_SERVICES.LESSON_SERVICES.DELETE(lessonId))
    .then((res) => res.data);

const getLessonService = async (lessonId: number): Promise<ILessonEntity> =>
  api.get(API_SERVICES.LESSON_SERVICES.GET(lessonId)).then((res) => res.data);

const editLessonService = async (data: TEditLessonRemoteData): Promise<void> =>
  api
    .put(API_SERVICES.LESSON_SERVICES.EDIT(data.id), data, {})
    .then((res) => res.data);

const getAnswerFromStudentService = async (
  studentPageId: number
): Promise<IAnswerResultEntity[]> =>
  api
    .get(API_SERVICES.LESSON_SERVICES.GET_ANSWERS_FROM_STUDENT(studentPageId))
    .then((res) => res.data);

const lessonService: ILessonService = {
  createLessonService,
  removeLessonService,
  getLessonService,
  editLessonService,
  getAnswerFromStudentService,
};

export default lessonService;
