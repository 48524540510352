import React, { ReactNode } from "react";

import BaseText, { BaseTextProps } from "../Base";

type RegularProps = {
  children: ReactNode;
} & BaseTextProps;

const Regular = ({ children, align, noWrap, color, weight }: RegularProps) => (
  <BaseText
    size="body1"
    align={align}
    noWrap={noWrap}
    color={color}
    weight={weight}
  >
    {children}
  </BaseText>
);

export default Regular;
