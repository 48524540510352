import { ICompanyRepository } from "@app/features/Company/data/companyRepository";
import { TCreateCompanyViewModel } from "@app/features/Company/view/Create/createCompanyViewModel";

const createCompanyUseCase = async (
  repository: Pick<ICompanyRepository, "createCompany">,
  data: TCreateCompanyViewModel
) => {
  const {
    address,
    addressNumber,
    city,
    companyName,
    country,
    document,
    companyImage,
    phone,
    state,
    tradingName,
    zipcode,
    classe,
  } = data;

  await repository.createCompany({
    address,
    addressNumber,
    city,
    companyName,
    country,
    document,
    companyImage,
    phone,
    state,
    tradingName,
    zipcode,
    classe,
  });
};

export default createCompanyUseCase;
