import { Grid } from "@mui/material";
import { lotties } from "@app/assets";
import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(-1);
    }, 3000);
  }, [navigate]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Grid container justifyContent={"center"}>
        <Lottie
          animationData={lotties.pageNotFound}
          loop
          style={{ width: 500, alignSelf: "center" }}
        />
      </Grid>
    </div>
  );
};

export default React.memo(PageNotFound);
