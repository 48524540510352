import React from "react";

import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

import CopyRight from "@app/components/molecules/CopyRight";
import { useUserRepository } from "@app/features/User/data/userRepository";
import { useChangePasswordViewModel } from "@app/features/ForgotPassword/view/ChangePassword/changePasswordViewModel";
import userService from "@app/services/user";
import showNotification from "@app/components/molecules/Toast";
import useStyles from "./styles";

const ChangePassword = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().required("Preencha o campo senha"),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref("password"), null], "Senhas não são iguais.")
          .required("Preencha o campo confirmação de senha"),
      })
    ),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onSubmit",
  });

  const userRepository = useUserRepository(userService);
  const { changePassword, loading } =
    useChangePasswordViewModel(userRepository);

  const onSubmit = (data: { password: string; confirmPassword: string }) => {
    if (token) {
      changePassword({ password: data.password, token });
    } else {
      showNotification(
        "Você não tem permissão para executar esta operação",
        "WARNING"
      );
    }
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={classes.paper}>
        <Avatar sx={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Defina sua nova senha
        </Typography>
        <form style={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label={"Nova senha"}
            autoComplete="email"
            type="password"
            autoFocus
            error={!!errors.password}
            helperText={!!errors.password && errors.password.message}
            {...register("password")}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirm_password"
            label={"Confirmar senha"}
            autoComplete="confirm_password"
            type="password"
            error={!!errors.confirmPassword}
            helperText={
              !!errors.confirmPassword && errors.confirmPassword.message
            }
            {...register("confirmPassword")}
          />

          {loading ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Alterar senha
            </Button>
          )}
        </form>
      </Box>
      <Box mt={8}>
        <CopyRight />
      </Box>
    </Container>
  );
};

export default ChangePassword;
