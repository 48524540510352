import React, { useEffect } from "react";

import UserForm, { TUserForm } from "@app/features/User/view/Form";

import { useEditUserViewModel } from "@app/features/User/view/Edit/editUserViewModel";
import { useUserRepository } from "@app/features/User/data/userRepository";

import userServices from "@app/services/user";

import StandardTemplate from "@app/components/templates/Standard";
import FooterActions from "@app/components/molecules/FooterActions";
import LoadingLock from "@app/components/molecules/LoadingLock";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

const EditUser = () => {
  const { id } = useParams();

  const { currentClasse } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const userRepository = useUserRepository(userServices);
  const { editUserStatus, getUser, user, isLoading } =
    useEditUserViewModel(userRepository);

  const handleSave = (data: TUserForm) => {
    if (user && id && currentClasse && currentCompany) {
      editUserStatus({
        userId: Number(id),
        roleName: data.roleName,
        userStatusClasse: data.userClasseStatus,
        classeId: currentClasse.id,
        companyId: currentCompany.id,
      });
    }
  };

  useEffect(() => {
    if (id) {
      getUser(Number(id));
    }
  }, [getUser, id]);

  return (
    <StandardTemplate title="Editar colaborador" showBackButton>
      <LoadingLock isLoading={isLoading} />
      {user && (
        <UserForm
          edit
          dataForm={{
            email: user.email,
            name: user.name,
            roleName: user.role?.name,
            photo: user.photo,
            userClasseStatus: user.userStatusClasse,
          }}
          onValidateSuccess={handleSave}
          footerActions={<FooterActions />}
        />
      )}
    </StandardTemplate>
  );
};

export default EditUser;
