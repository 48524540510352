import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import handleApplicationError, {
  ApplicationError,
} from "@app/handlers/handleApiError";
import { makeLoginUseCase } from "@app/features/Login/domain/useCases/loginUseCase";
import { IUseLoginRepository } from "@app/features/Login/data/loginRepository";
import { EMAIL_TYPES } from "@app/constants/enums";
import showNotification from "@app/components/molecules/Toast";

export type TLoginViewModel = {
  email: string;
  password: string;
};

const useLoginViewModel = (repository: IUseLoginRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = useCallback(
    async (data: TLoginViewModel) => {
      try {
        setLoading(true);
        await makeLoginUseCase(repository, data);
        navigate("/admin", { replace: true });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (
          handleApplicationError.isApplicationError(error) &&
          (error as ApplicationError).response.data?.error.code ===
            "ERROR_USER_0003"
        ) {
          navigate("/verification-code", {
            state: {
              email: data.email,
              emailType: EMAIL_TYPES.WELCOME,
              title: "Ativação de conta",
            },
          });
        } else if (
          handleApplicationError.isApplicationError(error) &&
          (error as ApplicationError).response.data?.error.code ===
            "ERROR_USER_0004"
        ) {
          showNotification(
            "Você está logando com uma senha temporária. Foi enviado um email para que você possa fazer alteração da sua senha",
            "INFO",
            10000
          );
        } else {
          handleApplicationError.handleError(error);
        }
      }
    },

    [navigate, repository]
  );

  return { login, isLoading };
};

export { useLoginViewModel };
