import React from "react";

import { useClasseRepository } from "@app/features/Classe/data/classeRespository";
import { useCreateUserViewModel } from "@app/features/User/view/Create/createUserViewModel";
import classeService from "@app/services/classe";

import UserForm, { TUserForm } from "@app/features/User/view/Form";

import LoadingLock from "@app/components/molecules/LoadingLock";
import StandardTemplate from "@app/components/templates/Standard";
import FooterActions from "@app/components/molecules/FooterActions";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

const CreateUser = () => {
  const { currentClasse } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const classeRepository = useClasseRepository(classeService);
  const { addUserToClasse, isLoading } =
    useCreateUserViewModel(classeRepository);

  const handleSave = (data: TUserForm) => {
    if (currentClasse && currentCompany) {
      addUserToClasse({
        ...data,
        classeId: currentClasse.id,
        companyId: currentCompany.id,
      });
    }
  };

  return (
    <StandardTemplate title="Adicionar colaborador" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <UserForm
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default CreateUser;
