import { Theme } from "@mui/material/styles";
import themeInstance from "@app/config/theme";

const styles = (theme: Theme = themeInstance) => ({
  root: {
    padding: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputStyle: {
    height: 50,
    fontSize: 50,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: theme.palette.primary.main,
    padding: 2,
    margin: 10,
  },
});

export default styles;
