import React, { useCallback, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { MenuItem } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import {
  TSignUpViewModel,
  useSignUpViewModel,
} from "@app/features/SignUp/view/signUpViewModel";
import { useFormClasseViewModel } from "@app/features/Classe/view/Form/formClasseViewModel";

import { useUserRepository } from "@app/features/User/data/userRepository";
import { useLanguageRepository } from "@app/features/Language/data/languageRepository";

import userService from "@app/services/user";
import languageService from "@app/services/language";
import getCNPJDataService from "@app/services/providers/cnpj";

import Copyright from "@app/components/molecules/CopyRight";
import LoadingLock from "@app/components/molecules/LoadingLock";
import SelectUseForm from "@app/components/organisms/SelectUseForm";

import useStyles from "./styles";
import { images } from "@app/assets";
import Util from "@app/util";

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 18;

const SignUp = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        company: yup.object().shape({
          companyImage: yup.string(),
          companyName: yup.string(),
          tradingName: yup.string(),
          document: yup.string(),
          zipcode: yup.string(),
          state: yup.string(),
          city: yup.string(),
          address: yup.string(),
          addressNumber: yup.string(),
          phone: yup.string(),
        }),
        email: yup
          .string()
          .email("Email inválido")
          .required("Preencha o campo email"),
        password: yup.string().required("Preencha o campo senha"),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref("password"), null], "Senhas devem ser iguais")
          .required("Preencha o campo confirmação de senha"),
        name: yup.string().required("Preencha o campo nome"),
        lastname: yup.string().required("Preencha o campo sobrenome"),
        document: yup.string().required("Preencha o campo documento"),
        birthDate: yup.string().required("Preencha o campo data de nascimento"),
        languageId: yup
          .string()
          .required("Selecione o idioma da sua primeira turma"),
      })
    ),
    defaultValues: {
      company: {
        companyImage: "",
        companyName: "",
        tradingName: "",
        document: "",
        zipcode: "",
        state: "",
        city: "",
        address: "",
        addressNumber: "",
        phone: "",
      },
      name: "",
      lastname: "",
      password: "",
      document: "",
      email: "",
      confirmPassword: "",
      birthDate: "",
      languageId: "",
    },
    mode: "onSubmit",
  });

  const userRepository = useUserRepository(userService);
  const languageRepository = useLanguageRepository(languageService);
  const { createAccount, isLoading } = useSignUpViewModel(userRepository);

  const { getAvailableLanguages, languages } =
    useFormClasseViewModel(languageRepository);

  const onSubmit = (data: TSignUpViewModel) => {
    createAccount(data);
  };

  const handleChangeBirthDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("birthDate", event.currentTarget.value);
  };
  const [birthDate, document] = watch(["birthDate", "document"]);

  const getCNPJData = useCallback(async () => {
    const data = await getCNPJDataService(document);
    if (data) {
      const {
        municipio,
        numero,
        cnpj,
        logradouro,
        cep,
        telefone,
        uf,
        nome_fantasia,
        razao_social,
        bairro,
        tipo_logradouro,
      } = data;
      setValue(
        "company.address",
        `${tipo_logradouro} ${logradouro}, ${bairro}`
      );
      setValue("company.addressNumber", numero);
      setValue("company.city", municipio);
      setValue("company.document", cnpj);
      setValue("company.zipcode", cep);
      setValue("company.phone", telefone);
      setValue("company.state", uf);
      setValue("company.tradingName", nome_fantasia);
      setValue("company.companyName", razao_social);
    }
  }, [document, setValue]);

  useEffect(() => {
    setValue("document", Util.formatCnpjCpf(document));
  }, [document, setValue]);

  useEffect(() => {
    if (document.length === CPF_LENGTH) {
      if (!Util.isValidCPF(document)) {
        setError("document", { message: "CPF inválido" });
      } else {
        clearErrors("document");
      }
    }
    if (document.length === CNPJ_LENGTH) {
      if (!Util.isValidCNPJ(document)) {
        setError("document", { message: "CNPJ inválido" });
      } else {
        clearErrors("document");
        getCNPJData();
      }
    }
  }, [document, setError, clearErrors, getCNPJData]);

  useEffect(() => {
    getAvailableLanguages();
  }, [getAvailableLanguages]);

  const classes = useStyles();

  return (
    <Grid container component="main" sx={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} sx={classes.imagePattern}>
        <Avatar src={images.logoBackground} sx={classes.image} />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <LoadingLock isLoading={isLoading} />
        <Box sx={classes.paper}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Criar conta
          </Typography>
          <form
            style={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label={"Nome"}
                  autoFocus
                  error={!!errors.name}
                  helperText={!!errors.name && errors.name.message}
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastname"
                  label={"Sobrenome"}
                  autoComplete="lname"
                  error={!!errors.lastname}
                  helperText={!!errors.lastname && errors.lastname.message}
                  {...register("lastname")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label={"Email"}
                  autoComplete="email"
                  error={!!errors.email}
                  helperText={!!errors.email && errors.email.message}
                  {...register("email")}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SelectUseForm
                  error={!!errors.languageId}
                  helperText={errors.languageId?.message}
                  control={control}
                  label="Idioma da sua primeira turma"
                  defaultValue={String(getValues("languageId"))}
                  {...register("languageId")}
                >
                  <MenuItem value="">Selecione o idioma da turma</MenuItem>
                  {languages.map((language) => (
                    <MenuItem value={language.id} key={language.key}>
                      {language.name}
                    </MenuItem>
                  ))}
                </SelectUseForm>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={"CPF ou CNPJ"}
                  id="document"
                  autoComplete="current-password"
                  error={!!errors.document}
                  inputProps={{ maxLength: 17 }}
                  helperText={!!errors.document && errors.document.message}
                  {...register("document")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="99/99/9999"
                  value={birthDate}
                  onChange={handleChangeBirthDate}
                >
                  <TextField
                    id="birthDate"
                    label={"Data de nascimento"}
                    variant="outlined"
                    required
                    fullWidth
                    placeholder=""
                    error={!!errors.birthDate}
                    helperText={!!errors.birthDate && errors.birthDate.message}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={"Senha"}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={!!errors.password && errors.password.message}
                  {...register("password")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={"Confirmação de senha"}
                  type="password"
                  id="confirmPassword"
                  autoComplete="current-password"
                  error={!!errors.confirmPassword}
                  helperText={
                    !!errors.confirmPassword && errors.confirmPassword.message
                  }
                  {...register("confirmPassword")}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Se você já tem uma conta, é só fazer seu login aqui
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignUp;
