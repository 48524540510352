import React, { useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import {
  useCreateClassViewModel,
  TUserToInviteViewModel,
} from "@app/features/Classe/view/Create/createClassViewModel";
import { useClasseRepository } from "@app/features/Classe/data/classeRespository";
import { useCompanyRepository } from "@app/features/Company/data/companyRepository";
import ClasseForm, { TFormClasse } from "@app/features/Classe/view/Form";
import classeService from "@app/services/classe";
import companyService from "@app/services/company";

import ListAllUsersFromCompany from "@app/features/Classe/view/Create/ListAllUsersFromCompany";
import StepperTamplete from "@app/components/templates/Stepper";
import FooterActions from "@app/components/molecules/FooterActions";
import LoadingLock from "@app/components/molecules/LoadingLock";

import { RootState } from "@app/config/store";

const CreateClasse = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [classeData, setClasseData] = useState<TFormClasse>();

  const classeRespository = useClasseRepository(classeService);
  const companyRespository = useCompanyRepository(companyService);
  const { createClasse, getAllUsersFromCompany, isLoading, usersFromCompany } =
    useCreateClassViewModel(classeRespository, companyRespository);

  const { currentCompany } = useSelector((state: RootState) => state.company);

  const handleBack = useCallback(() => {
    setCurrentStep((c) => c - 1);
  }, []);

  const handleValidateForm = useCallback(
    (data: TFormClasse) => {
      if (currentCompany) {
        setClasseData(data);
        setCurrentStep((c) => c + 1);
      }
    },
    [currentCompany]
  );

  const handleSave = useCallback(
    (users: TUserToInviteViewModel[]) => {
      if (classeData && currentCompany) {
        const { id } = currentCompany;
        createClasse({ ...classeData, usersToInvite: users, companyId: id });
      }
    },
    [createClasse, classeData, currentCompany]
  );

  useEffect(() => {
    if (currentCompany) {
      const { id } = currentCompany;
      getAllUsersFromCompany(id);
    }
  }, [currentCompany, getAllUsersFromCompany]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      <StepperTamplete
        currentStep={currentStep}
        stepsLabels={[
          "Criar turma",
          "Defina quem terá permissão para acessar essa turma",
        ]}
        stepsScenes={[
          <ClasseForm
            onValidateSuccess={handleValidateForm}
            footerActions={<FooterActions saveLabel="Próximo" />}
          />,
          <ListAllUsersFromCompany
            data={usersFromCompany}
            onPressSave={handleSave}
            onBack={handleBack}
          />,
        ]}
      />
    </>
  );
};

export default CreateClasse;
