import React from "react";

import Lottie from "lottie-react";
import { Box, Button } from "@mui/material";

import { lotties } from "@app/assets";
import { Subtitle } from "@app/components/atoms/Text";

type EmptyStateProps = {
  message?: string;
  textButton?: string;
  lottieAnimation?: unknown;
  onClickButton?: () => void;
};

const EmptyState = ({
  message,
  textButton,
  lottieAnimation,
  onClickButton,
}: EmptyStateProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        pb: 2,
      }}
    >
      <Lottie
        animationData={lottieAnimation || lotties.emptyState}
        loop={false}
        style={{
          width: 400,
          height: 200,
        }}
      />
      {message && (
        <Box sx={{ maxWidth: "50vw" }}>
          <Subtitle weight="600" align="center">
            {message}
          </Subtitle>
        </Box>
      )}
      {textButton && (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ mt: 2 }}
          onClick={onClickButton}
        >
          {textButton}
        </Button>
      )}
    </Box>
  );
};

export default EmptyState;
