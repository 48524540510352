import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";
import IClasseEntity from "@app/features/Classe/data/IClasseEntity";

const getClasseUseCase = async (
  repository: Pick<IClasseRepository, "getClasse">,
  classeId: number
) => {
  const classeEntity = await repository.getClasse(classeId);
  const domain = mapToDomain(classeEntity);
  return domain;
};

const mapToDomain = (enitity: IClasseEntity): IClasseModel => {
  const {
    id,
    description,
    entryCode,
    visibility,
    image,
    language,
    languageId,
    primaryColor,
    name,
    status,
  } = enitity;

  return {
    id,
    description,
    entryCode,
    visibility,
    image,
    language,
    languageId,
    primaryColor,
    name,
    status,
  };
};

export default getClasseUseCase;
