import { useCallback } from "react";

import IDashboardEntity from "@app/features/Dashboard/data/IDashboardEntity";

import { IDashboardService } from "@app/services/dashboard";

export interface IDashboardRepository {
  getDashboard(classeId: number): Promise<IDashboardEntity>;
}

const useDashboardRepository = (
  dashboardService: IDashboardService
): IDashboardRepository => {
  const getDashboard = useCallback(
    async (classeId: number): Promise<IDashboardEntity> => {
      const data = await dashboardService.getDashboardService(classeId);
      return data;
    },
    [dashboardService]
  );

  return {
    getDashboard,
  };
};

export { useDashboardRepository };
