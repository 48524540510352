import React, { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid,
  List,
} from "@mui/material";

import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";

import { setCurrentCompanyAction } from "@app/features/Company/data/companyActions";
import {
  setClassesAction,
  setCurrentClasseAction,
} from "@app/features/Classe/data/classeActions";

import Standard from "@app/components/templates/Standard";
import CardCompany from "@app/components/organisms/CardCompany";
import LoadingLock from "@app/components/molecules/LoadingLock";

import { RootState, dispatchStore } from "@app/config/store";

const ListCompanies = () => {
  const { currentCompany } = useSelector((state: RootState) => state.company);
  const [selectedCompany, setSelectedCompany] = useState<
    ICompanyModel | undefined
  >(currentCompany);

  const navigate = useNavigate();

  const { companies, loading } = useSelector(
    (state: RootState) => state.company
  );

  const handleSetCurrentCompany = useCallback(() => {
    dispatchStore(setClassesAction([]));
    dispatchStore(setCurrentClasseAction(null));
    if (selectedCompany) {
      navigate("/admin/classes");
    }
  }, [navigate, selectedCompany]);

  const handleChangeSelectedCompany = useCallback((company: ICompanyModel) => {
    setSelectedCompany(company);
    dispatchStore(setCurrentCompanyAction(company));
  }, []);

  const handleCreateCompany = useCallback(() => {
    navigate(`/admin/companies/create`);
  }, [navigate]);

  const handleEditCompany = useCallback(
    (company: ICompanyModel) => {
      navigate(`/admin/companies/edit/${company.id}`);
    },
    [navigate]
  );

  return (
    <Standard
      title={"Empresas"}
      primaryButton={{
        textButton: "Criar empresa",
        onClickButton: handleCreateCompany,
        isVisible: !companies.length,
      }}
    >
      {loading && <LoadingLock isLoading={loading} />}
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Grid item xs={12} sm={5}>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Card>
              <Box sx={{ m: 2 }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Selecione uma empresa
                  </FormLabel>
                </FormControl>
              </Box>
              {companies.map((company) => (
                <CardCompany
                  key={company.id}
                  selected={selectedCompany?.id === company.id}
                  company={company}
                  onClickEdit={handleEditCompany}
                  onSelectItem={handleChangeSelectedCompany}
                />
              ))}
            </Card>
          </List>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Button
            variant="contained"
            onClick={handleSetCurrentCompany}
            disabled={!selectedCompany}
          >
            Selecionar empresa
          </Button>
        </Grid>
      </Grid>
    </Standard>
  );
};

export default ListCompanies;
