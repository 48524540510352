import {
  RefObject,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { FiUpload } from "react-icons/fi";

import {
  IUploadEntity,
  deleteAssetService,
  uploadAssetService,
} from "@app/services/upload";

import FileInput, {
  FileInputRef,
  TAcceptedFileType,
} from "@app/components/atoms/FileInput";
import theme from "@app/config/theme";
import { AxiosProgressEvent } from "axios";
import handleApplicationError from "@app/handlers/handleApiError";
import { TAssetPath } from "@app/constants";
import showNotification from "@app/components/molecules/Toast";

export type TUploadPayload = {
  name: string;
  size: number;
} & IUploadEntity;

export type UploadRef = {
  clearField: (url: string) => Promise<string>;
};

type UploadProps = {
  ref?: RefObject<UploadRef>;
  label: string;
  accept: TAcceptedFileType;
  destination: TAssetPath;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  onSelectFile?: (file: File) => void;
  onUploadSuccess: (data: TUploadPayload) => void;
};

const Upload = forwardRef<unknown, UploadProps>(
  (
    {
      label,
      accept,
      destination,
      disabled,
      error,
      errorMessage,
      onSelectFile,
      onUploadSuccess,
    }: UploadProps,
    ref
  ) => {
    const fileInputRef = useRef<FileInputRef>(null);

    const [file, setFile] = useState<File>();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const handleUploadClick = useCallback(() => {
      if (!file && !disabled) {
        fileInputRef.current?.openWindow();
      }
    }, [file, disabled]);

    const getAcceptedFilesExtension = useCallback(() => {
      let result = "";

      if (accept?.all) {
        return ".png .jpg .jpeg .pdf .wa, .mp4 .mpeg .mp3";
      }
      if (accept?.image) {
        result = ".png .jpg .jpeg ";
      }
      if (accept?.audio) {
        result += ".wav .mp4 .mpeg .mp3 ";
      }
      if (accept?.file) {
        result += ".pdf";
      }

      return result;
    }, [accept]);

    const uploadFile = useCallback(
      async (fileData: File) => {
        try {
          setLoading(true);
          const data = new FormData();
          data.append(`data`, fileData, fileData.name);
          const response = await uploadAssetService(
            data,
            destination,
            (progressEvent: AxiosProgressEvent) => {
              if (progressEvent.progress) {
                setProgress(progressEvent.progress * 100);
              }
            }
          );

          let dataPayload: TUploadPayload = {
            ...response,
            name: fileData.name,
            size: fileData.size,
          };

          onUploadSuccess(dataPayload);
          setLoading(false);
          showNotification("Carregamento concluído com sucesso", "SUCCESS");
        } catch (error) {
          setLoading(false);
          handleApplicationError.handleError(error);
        }
      },
      [destination, onUploadSuccess]
    );

    const handleFileChange = useCallback(
      (files: FileList) => {
        if (files) {
          let file = files[0];
          setFile(file);

          if (onSelectFile) {
            onSelectFile(file);
          } else {
            uploadFile(file);
          }
        }
      },
      [onSelectFile, uploadFile]
    );

    const clearField = useCallback(async (url: string) => {
      try {
        await deleteAssetService({ url });
        fileInputRef.current?.clearField();
        setFile(undefined);
        showNotification("Arquivo removido com sucesso.", "SUCCESS");

        return url;
      } catch (error) {
        handleApplicationError.handleError(error);
      }
    }, []);

    useImperativeHandle(ref, () => ({ clearField }), [clearField]);

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
        onClick={handleUploadClick}
      >
        <Paper
          elevation={2}
          sx={{
            display: "flex",
            borderColor: false
              ? theme.palette.error.light
              : theme.palette.grey[500],
            borderWidth: 2,
            height: "20vh",
            minWidth: "20vw",
            borderStyle: "dotted",
            borderRadius: 2,
            cursor: file || disabled ? "default" : "pointer",
            justifyContent: "center",
          }}
        >
          <Stack
            sx={{
              p: 2,
              maxWidth: "20vw",
            }}
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <FiUpload size={26} color={theme.palette.primary.main} />
            <Typography variant="h6" color={"black"} align="center">
              {label}
            </Typography>
            {file ? (
              <Typography
                variant="subtitle1"
                color={"text.secondary"}
                align="center"
              >
                {file?.name}
              </Typography>
            ) : (
              <Typography
                variant="subtitle1"
                color={"text.secondary"}
                align="center"
              >
                {getAcceptedFilesExtension()}
              </Typography>
            )}
            {progress < 100 && loading && (
              <Box
                sx={{
                  display: "flex",
                  p: 2,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  size={30}
                  color="secondary"
                  variant="determinate"
                  value={progress}
                />
                <Typography
                  variant="body2"
                  color={"text.secondary"}
                  align="center"
                >
                  Aguarde {Math.round(progress)}%
                </Typography>
              </Box>
            )}
          </Stack>
        </Paper>
        <FileInput
          ref={fileInputRef}
          multiple={false}
          accept={accept}
          onSelectFiles={handleFileChange}
        />
        {errorMessage && (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flex: 1,
            }}
          >
            <Typography
              variant="body2"
              color="error"
              align="center"
              sx={{ width: 200, p: 1 }}
            >
              {errorMessage}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
);

export default Upload;
