import { IModuleRepository } from "@app/features/Module/data/moduleRepository";
import { TCreateModuleViewModel } from "@app/features/Module/view/Create/createModuleViewModel";

const createModuleUseCase = async (
  repository: Pick<IModuleRepository, "createModule">,
  data: TCreateModuleViewModel
) => {
  await repository.createModule(data);
};

export default createModuleUseCase;
