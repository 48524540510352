import api from "@app/config/api";
import { ILoginDataEntity } from "@app/features/Login/data/ILoginEntity";
import { TDataAuthorizationRemote } from "@app/services/authentication/remoteTypes/TDataAuthenticationRemote";
import API_SERVICES from "../constants";

export type TAuthService = (
  data: TDataAuthorizationRemote
) => Promise<ILoginDataEntity>;

export const authService: TAuthService = async (
  data: TDataAuthorizationRemote
): Promise<ILoginDataEntity> =>
  api
    .post(API_SERVICES.AUTHENTICATION_SERVICES.LOGIN, data)
    .then((res) => res.data);
