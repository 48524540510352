import { IUserRepository } from "@app/features/User/data/userRepository";
import { TVerificationDataViewModel } from "@app/features/VerificationCode/view/verificationCodeViewModel";

const verificationCodeUseCase = async (
  repository: Pick<IUserRepository, "verificationCode">,
  data: TVerificationDataViewModel
) => {
  const { email, verificationCode } = data;

  await repository.verificationCode({ email, code: verificationCode });
};

export { verificationCodeUseCase };
