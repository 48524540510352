import { IProfileRepository } from "@app/features/Profile/data/profileRepository";
import { TEditProfileViewModel } from "@app/features/Profile/view/Edit/editProfileViewModel";

const editProfileUseCase = async (
  repository: Pick<IProfileRepository, "editProfile">,
  data: TEditProfileViewModel
) => {
  await repository.editProfile(data);
};

export default editProfileUseCase;
