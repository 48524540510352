import { useCallback, useState } from "react";

import getDashboardUseCase from "@app/features/Dashboard/domain/use-cases/getDashboardUseCase";
import IDashboardModel from "@app/features/Dashboard/domain/models/IDashboardModel";

import { IDashboardRepository } from "@app/features/Dashboard/data/dashboardRepository";

import handleApplicationError from "@app/handlers/handleApiError";

export type TCreateModuleViewModel = {
  name: string;
  classeId: number;
};

const useDashboardViewModel = (repository: IDashboardRepository) => {
  const [dashboardData, setDashboardData] = useState<IDashboardModel>();
  const [isLoading, setLoading] = useState(false);

  const getDashboard = useCallback(
    async (classeId: number) => {
      try {
        setLoading(true);

        const data = await getDashboardUseCase(
          {
            getDashboard: repository.getDashboard,
          },
          classeId
        );
        setDashboardData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.getDashboard]
  );

  return { isLoading, getDashboard, dashboardData };
};

export { useDashboardViewModel };
