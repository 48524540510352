import { IQuestionRepository } from "@app/features/Question/data/questionRepository";
import { TEditQuestionViewModel } from "@app/features/Question/view/Form/formQuestionViewModel";

const removeQuestionUseCase = async (
  repository: Pick<IQuestionRepository, "editQuestion">,
  data: TEditQuestionViewModel
) => {
  await repository.editQuestion(data);
};

export default removeQuestionUseCase;
