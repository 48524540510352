import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

const deleteResourceUseCase = async (
  repository: Pick<IResourceRepository, "deleteResource">,
  resourceId: number
) => {
  await repository.deleteResource(resourceId);
};

export default deleteResourceUseCase;
