import { IUserRepository } from "@app/features/User/data/userRepository";
import { TSignUpViewModel } from "@app/features/SignUp/view/signUpViewModel";
import pJson from "../../../../../package.json";

const signUpUseCase = async (
  repository: Pick<IUserRepository, "createAccount">,
  data: TSignUpViewModel
) => {
  const {
    name,
    lastname,
    email,
    document,
    password,
    birthDate,
    languageId,
    company,
  } = data;
  await repository.createAccount({
    company,
    name: `${name} ${lastname}`,
    email,
    password,
    document,
    birthDate,
    languageId: Number(languageId),
    platform: "WEB",
    version: pJson.version,
  });
};

export { signUpUseCase };
