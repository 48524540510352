import React, { ReactNode, useCallback, useEffect } from "react";

import axios, { AxiosHeaders } from "axios";
import { useNavigate } from "react-router-dom";

import showNotification from "@app/components/molecules/Toast";
import api from "@app/config/api";

import Storage from "@app/config/storage";
import enviroments from "@app/config/enviroments";
import { store } from "@app/config/store";

export const AxiosInterceptorContext = React.createContext({});

type AxiosInterceptorProviderProps = {
  children: ReactNode;
};

const AxiosInterceptorProvider = ({
  children,
}: AxiosInterceptorProviderProps) => {
  const navigate = useNavigate();

  const logOut = useCallback(() => {
    store.dispatch({ type: "RESET_STATE", payload: null });
    Storage.clear();
    navigate("/login", { replace: true });
  }, [navigate]);

  useEffect(() => {
    const requestInterceptor = api.interceptors.request.use(
      async (config) => {
        const accessToken = Storage.get("@SHARPII:accessToken");
        const { currentClasse } = store.getState().classe;
        const { currentCompany } = store.getState().company;

        config.headers = {
          ...config.headers,
          classeid: currentClasse?.id,
          companyid: currentCompany?.id,
          Authorization: `Bearer ${accessToken}`,
        } as unknown as AxiosHeaders;

        return config;
      },
      (err) => Promise.reject(err)
    );

    return () => api.interceptors.request.eject(requestInterceptor);
  }, []);

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (err): Promise<void> => {
        if (err?.response?.status >= 500) {
          return showNotification("Erro generico", "ERROR");
        }

        if (err?.response?.status === 401) {
          try {
            const accessToken = Storage.get("@SHARPII:accessToken");
            const refreshToken = Storage.get("@SHARPII:refreshToken");
            const body = { accessToken, refreshToken };
            const response = await axios.post(
              `${enviroments.baseURL}/authentication/refresh-token`,
              body
            );
            if (response.status === 200) {
              const { data } = response;
              Storage.set(data.accessToken, "@SHARPII:accessToken");
              Storage.set(data.refreshToken, "@SHARPII:refreshToken");

              const configRetryRequest = {
                ...err.response.config,
                Authorization: `Bearer ${data.accessToken}`,
              };

              return api({ ...configRetryRequest })
                .then((res) => res)
                .catch((error) => error);
            }
          } catch (error) {
            return logOut();
          }
        }
        return Promise.reject(err);
      }
    );

    return () => axios.interceptors.response.eject(responseInterceptor);
  }, [logOut]);

  return (
    <AxiosInterceptorContext.Provider value={{}}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};

export default AxiosInterceptorProvider;
