import * as React from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useUserRepository } from "@app/features/User/data/userRepository";
import { useProfileRepository } from "@app/features/Profile/data/profileRepository";
import { useChangeForcePasswordViewModel } from "@app/features/User/view/ChangeForcePassword/changeForcePasswordViewModel";

import userService from "@app/services/user";
import profileService from "@app/services/profile";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
} from "@mui/material";
import Storage from "@app/config/storage";

type FormChangeTemporaryPasswordForm = {
  password: string;
  confirmPassword: string;
};
type FormChangeTemporaryPasswordDialogProps = {
  open: boolean;
};

const FormChangeTemporaryPasswordDialog = ({
  open,
}: FormChangeTemporaryPasswordDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().required("Preencha o campo senha"),
        confirmPassword: yup
          .string()
          .oneOf([yup.ref("password"), null], "Senhas não são iguais.")
          .required("Preencha o campo confirmação de senha"),
      })
    ),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "onSubmit",
  });

  const userRepository = useUserRepository(userService);
  const profileRepository = useProfileRepository(profileService);
  const { changePassword, loading } = useChangeForcePasswordViewModel(
    userRepository,
    profileRepository
  );

  const onSubmit = (data: FormChangeTemporaryPasswordForm) => {
    const { password } = data;
    const accessToken = Storage.get("@SHARPII:accessToken");

    if (accessToken) {
      changePassword({ password, token: accessToken });
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle>Vamos definir uma senha?</DialogTitle>
        <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Você fez login com uma senha temporária. Para melhorar a segurança
              da sua conta você precisa definir uma nova senha.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Nova senha"
              fullWidth
              disabled={loading}
              type="password"
              variant="outlined"
              error={!!errors.password}
              helperText={!!errors.password && errors.password.message}
              {...register("password")}
            />
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              disabled={loading}
              label="Confirmação de senha"
              type="password"
              fullWidth
              variant="outlined"
              error={!!errors.confirmPassword}
              helperText={
                !!errors.confirmPassword && errors.confirmPassword.message
              }
              {...register("confirmPassword")}
            />
          </DialogContent>
          <DialogActions>
            {loading ? (
              <Box sx={{ mr: 2 }}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <Button type="submit">Alterar senha</Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};
export default FormChangeTemporaryPasswordDialog;
