import Storage from "@app/config/storage";
import { TAuthService } from "@app/services/authentication";
import { TDataAuthorizationRemote } from "@app/services/authentication/remoteTypes/TDataAuthenticationRemote";
import { useCallback } from "react";

export interface IUseLoginRepository {
  login(data: TDataAuthorizationRemote): Promise<void>;
}

const useLoginRepository = (service: TAuthService): IUseLoginRepository => {
  const login = useCallback(
    async (data: TDataAuthorizationRemote) => {
      const response = await service(data);
      const { accessToken } = response;
      const { refreshToken } = response;

      Storage.set(accessToken, "@SHARPII:accessToken");
      Storage.set(refreshToken, "@SHARPII:refreshToken");
    },

    [service]
  );

  return {
    login,
  };
};

export default useLoginRepository;
