import { ICompanyRepository } from "@app/features/Company/data/companyRepository";

import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";
import ICompanyEntity from "@app/features/Company/data/ICompanyEntity";

import { setCurrentCompanyAction } from "@app/features/Company/data/companyActions";
import { dispatchStore } from "@app/config/store";

const getCompanyUseCase = async (
  repository: Pick<ICompanyRepository, "getCompany">,
  companyId: number
): Promise<ICompanyModel> => {
  const companyEntity = await repository.getCompany(companyId);
  const companyModel = mapToDomain(companyEntity);

  dispatchStore(setCurrentCompanyAction(companyModel));

  return companyModel;
};

const mapToDomain = (enitity: ICompanyEntity): ICompanyModel => {
  const {
    id,
    address,
    addressNumber,
    city,
    companyName,
    country,
    document,
    companyImage,
    phone,
    state,
    tradingName,
    zipcode,
  } = enitity;

  return {
    id,
    address,
    addressNumber,
    city,
    companyName,
    country,
    document,
    phone,
    state,
    tradingName,
    companyImage,
    zipcode,
  };
};

export default getCompanyUseCase;
