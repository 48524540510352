import { IUserRepository } from "@app/features/User/data/userRepository";

import IStudentLessonResultEntity from "@app/features/Student/data/IStudentProgressResultEntity";

import IStudentLessonResultModel from "@app/features/Student/domain/models/IStudentProgressResultModel";

const listStudentLessonsResultUseCase = async (
  repository: Pick<IUserRepository, "listUserLessonsResult">,
  userId: number,
  classeId: number
): Promise<IStudentLessonResultModel[]> => {
  const data = await repository.listUserLessonsResult(userId, classeId);
  return mapToDomain(data);
};

const mapToDomain = (
  modules: IStudentLessonResultEntity[]
): IStudentLessonResultModel[] => {
  return modules;
};

export default listStudentLessonsResultUseCase;
