import React, { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import classeService from "@app/services/classe";
import { useEditClassViewModel } from "@app/features/Classe/view/Edit/editClasseViewModel";
import { useClasseRepository } from "@app/features/Classe/data/classeRespository";

import FooterActions from "@app/components/molecules/FooterActions";
import ClasseForm, { TFormClasse } from "@app/features/Classe/view/Form";
import LoadingLock from "@app/components/molecules/LoadingLock";
import StandardTemplate from "@app/components/templates/Standard";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

const EditClasse = () => {
  const { id } = useParams();

  const { currentCompany } = useSelector((state: RootState) => state.company);

  const classeRespository = useClasseRepository(classeService);
  const { editClasse, getClasse, classe, isLoading } =
    useEditClassViewModel(classeRespository);

  const handleValidateForm = useCallback(
    (data: TFormClasse) => {
      if (id && currentCompany) {
        editClasse({ ...data, id: Number(id) }, currentCompany.id);
      }
    },
    [editClasse, id, currentCompany]
  );

  useEffect(() => {
    if (id) {
      getClasse(Number(id));
    }
  }, [id, getClasse]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      <StandardTemplate title="Editar turma">
        <ClasseForm
          dataForm={classe}
          onValidateSuccess={handleValidateForm}
          footerActions={<FooterActions />}
        />
      </StandardTemplate>
    </>
  );
};

export default EditClasse;
