import { CompanyActionTypes, ICompanyStore } from "./companyActionsType";

const INITIAL_STATE: ICompanyStore = {
  loading: true,
  companies: [],
  currentCompany: undefined,
};

const classeReducer = (
  state = INITIAL_STATE,
  action: CompanyActionTypes
): ICompanyStore => {
  switch (action.type) {
    case "LOADING_COMPANY":
      return { ...state, loading: action.payload };

    case "COMPANIES":
      return { ...state, companies: action.payload, loading: false };

    case "SET_CURRENT_COMPANY":
      return { ...state, currentCompany: action.payload, loading: false };

    default:
      return state;
  }
};

export default classeReducer;
