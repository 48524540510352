import { IModuleRepository } from "@app/features/Module/data/moduleRepository";
import { TEditModuleViewModel } from "@app/features/Module/view/List/listModuleViewModel";

const editModuleUseCase = async (
  repository: Pick<IModuleRepository, "deleteModule">,
  data: TEditModuleViewModel
) => {
  await repository.deleteModule(data);
};

export default editModuleUseCase;
