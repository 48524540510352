import React, { useState, useRef, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type CountDownProps = {
  onFinish?: () => void;
  initialValue: number;
};

const CountDown = ({ onFinish, initialValue }: CountDownProps) => {
  const [time, setTime] = useState(initialValue);
  const timerRef = useRef(time);

  useEffect(() => {
    const timerId = setInterval(() => {
      timerRef.current -= 1;
      if (timerRef.current < 0) {
        clearInterval(timerId);
        if (onFinish) onFinish();
      } else {
        setTime(timerRef.current);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [onFinish]);

  const formatToTime = () => {
    const minutes =
      Math.floor(time / 60) > 9
        ? Math.floor(time / 60)
        : `0${Math.floor(time / 60)}`;

    const seconds = time % 60 > 9 ? time % 60 : `0${time % 60}`;

    return `${minutes}:${seconds}`;
  };

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Typography component="p" align="center" variant="caption">
            {formatToTime()}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

CountDown.defaultProps = {
  onFinish: null,
};

export default CountDown;
