import React from "react";
import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";

export interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
}

type EnhancedTableProps = {
  numSelected: number;
  allowSelectAll?: boolean;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  headCells: HeadCell[];
};

const EnhancedTableHead = ({
  numSelected,
  allowSelectAll = true,
  onSelectAllClick,
  rowCount,
  headCells,
}: EnhancedTableProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {allowSelectAll && (
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={"left"} padding={"normal"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
