import { IUserRepository } from "@app/features/User/data/userRepository";
import { TChangePasswordViewModel } from "@app/features/ForgotPassword/view/ChangePassword/changePasswordViewModel";

const changePasswordUseCase = async (
  repository: Pick<IUserRepository, "changePassword">,
  data: TChangePasswordViewModel
) => {
  await repository.changePassword(data);
};

export { changePasswordUseCase };
