import {
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useSelector } from "react-redux";

import { useLanguageRepository } from "@app/features/Language/data/languageRepository";
import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";

import languageService from "@app/services/language";
import resourceService from "@app/services/resource";

import { useFormResourceViewModel } from "@app/features/Resource/views/Form/formResourcesViewModel";

import SelectUseForm from "@app/components/organisms/SelectUseForm";
import ResourceType, {
  OptionType,
} from "@app/components/organisms/ResourceType";
import { RootState } from "@app/config/store";
import YoutubeResourcesForm, {
  YoutubeResourcesFormRef,
} from "./YoutubeResourceForm";
import FileForm, { FileFormRef } from "./FileForm";
import { TAcceptedFileType } from "@app/components/atoms/FileInput";

export type TResourceForm = {
  name: string;
  url: string;
  fileKey?: string;
  size?: number;
  languageId: number;
};

export type TResourceFormRef = {
  validate: () => Promise<TResourceForm[] | undefined>;
};

type ResourcesFormProps = {
  ref?: RefObject<TResourceFormRef>;
  allowSelectLanguage: boolean;
  onValidateSuccess?: (data: TResourceForm[]) => void;
  footerActions: ReactNode;
  predefinedResourceType?: OptionType;
  maxItems?: number;
  acceptedFiles?: TAcceptedFileType;
};

const ResourcesForm = forwardRef<unknown, ResourcesFormProps>(
  (
    {
      predefinedResourceType,
      allowSelectLanguage,
      footerActions,
      onValidateSuccess,
      maxItems,
      acceptedFiles,
    }: ResourcesFormProps,
    ref
  ) => {
    const youtubeResourcesFormRef = useRef<YoutubeResourcesFormRef>();
    const fileFormRef = useRef<FileFormRef>();

    const { currentClasse } = useSelector((state: RootState) => state.classe);

    const [resourceType, setResourceType] = useState<OptionType | undefined>(
      predefinedResourceType
    );

    const {
      register,
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      watch,
      trigger,
      getValues,
    } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          languageId: yup.number().required("Selecione o idioma dos arquivos"),
        })
      ),
      defaultValues: {
        languageId: currentClasse?.languageId ?? NaN,
      },
      mode: "onSubmit",
    });
    const [languageId] = watch(["languageId"]);

    const languageRepository = useLanguageRepository(languageService);
    const resourceRepository = useResourceRepository(resourceService);

    const { getAvailableLanguages, languages } = useFormResourceViewModel(
      languageRepository,
      resourceRepository
    );

    const handleClearLanguage = useCallback(() => {
      setValue("languageId", NaN);
    }, [setValue]);

    const handleChangeResourceType = useCallback((value: OptionType) => {
      setResourceType(value);
    }, []);

    const onSubmit = useCallback(async () => {
      let resourcesData: TResourceForm[] = [];
      switch (resourceType) {
        case "EMBED":
          const youtubeFormValue =
            await youtubeResourcesFormRef.current?.validate();

          resourcesData =
            youtubeFormValue?.resources.map((item): TResourceForm => {
              const { name, url } = item;

              return {
                languageId,
                name,
                url,
              };
            }) || [];

          break;

        default:
          const fileFormValue = await fileFormRef.current?.validate();

          resourcesData =
            fileFormValue?.resources.map((item): TResourceForm => {
              const { name, url, fileKey, size } = item;

              return {
                fileKey,
                size,
                languageId,
                name,
                url,
              };
            }) || [];
          break;
      }
      if (onValidateSuccess) {
        onValidateSuccess(resourcesData);
      }

      return resourcesData;
    }, [resourceType, languageId, onValidateSuccess]);

    const validate = useCallback(async (): Promise<
      TResourceForm[] | undefined
    > => {
      const value = await trigger();
      if (value) {
        const formData = getValues();
        const resources = await onSubmit();
        resources.map((r) => ({ ...r, languageId: formData.languageId }));
        return resources;
      }
    }, [onSubmit, getValues, trigger]);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    const languageData = useMemo(() => {
      const data = languages.find((l) => l.id === languageId);

      return data;
    }, [languageId, languages]);

    useEffect(() => {
      getAvailableLanguages();
    }, [getAvailableLanguages]);

    return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              justifyContent="center"
              alignItems="center"
            >
              {!!languageId ? (
                <Stack direction="row" spacing={1} justifyContent="center">
                  {allowSelectLanguage ? (
                    <Chip
                      avatar={<Avatar src={languageData?.image} />}
                      label={languageData?.name}
                      onDelete={handleClearLanguage}
                    />
                  ) : (
                    <Chip
                      avatar={<Avatar src={languageData?.image} />}
                      label={languageData?.name}
                    />
                  )}
                </Stack>
              ) : (
                <SelectUseForm
                  error={!!errors.languageId}
                  helperText={errors.languageId?.message}
                  control={control}
                  label="Selecione o idioma para os materiais"
                  defaultValue={String(languageId)}
                  {...register("languageId")}
                >
                  <MenuItem value="">
                    Selecione o idioma para os materiais
                  </MenuItem>
                  {languages.map((language) => (
                    <MenuItem value={language.id} key={language.key}>
                      {language.name}
                    </MenuItem>
                  ))}
                </SelectUseForm>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>

            {!!languageId && (
              <>
                {!predefinedResourceType && (
                  <ResourceType
                    maxItems={maxItems}
                    onChange={handleChangeResourceType}
                    selected={resourceType}
                  />
                )}

                {resourceType === "FILE" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FileForm
                      ref={fileFormRef}
                      maxItems={maxItems}
                      acceptedFiles={acceptedFiles}
                    />
                  </Grid>
                )}

                {resourceType === "EMBED" && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <YoutubeResourcesForm
                      ref={youtubeResourcesFormRef}
                      maxItems={maxItems}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Paper>
        <Box sx={{ mt: 10 }}>{footerActions}</Box>
      </form>
    );
  }
);

export default ResourcesForm;
