import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

import IAnswerResultEntity from "@app/features/Lesson/data/IAnswerResultEntity";

import IAnswerResultModel from "@app/features/Lesson/domain/models/IAnswerResultModel";

const getAnswerFromStudentUseCase = async (
  repository: Pick<ILessonRepository, "getAnswerFromStudent">,
  studentPageId: number,
  classeId: number
): Promise<IAnswerResultModel[]> => {
  const data = await repository.getAnswerFromStudent(studentPageId, classeId);
  return mapToDomain(data);
};

const mapToDomain = (answers: IAnswerResultEntity[]): IAnswerResultModel[] => {
  return answers;
};

export default getAnswerFromStudentUseCase;
