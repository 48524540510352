import React, {
  RefObject,
  useState,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useCallback,
} from "react";
import {
  Drawer as DrawerMaterial,
  DrawerProps as DrawerPropsMaterial,
} from "@mui/material";

export type IDrawerRef = {
  openDrawer: () => void;
  closeDrawer: () => void;
};

type DrawerProps = {
  ref?: RefObject<IDrawerRef>;
  drawerPosition: DrawerPropsMaterial["anchor"];
  children: ReactNode;
};

const Drawer = forwardRef<unknown, DrawerProps>(
  ({ children, drawerPosition }: DrawerProps, ref) => {
    const [drawerState, setDrawerState] = useState(false);

    const openDrawer = useCallback(() => {
      setDrawerState(true);
    }, []);

    const closeDrawer = useCallback(() => {
      setDrawerState(false);
    }, []);

    useImperativeHandle(ref, () => ({ openDrawer, closeDrawer }), [
      openDrawer,
      closeDrawer,
    ]);

    return (
      <DrawerMaterial
        anchor={drawerPosition}
        open={drawerState}
        onClose={closeDrawer}
      >
        {children}
      </DrawerMaterial>
    );
  }
);

export default Drawer;
