import { TLoginViewModel } from "@app/features/Login/view/loginViewModel";
import { IUseLoginRepository } from "@app/features/Login/data/loginRepository";

const makeLoginUseCase = async (
  repository: Pick<IUseLoginRepository, "login">,
  data: TLoginViewModel
) => {
  const { email, password } = data;

  await repository.login({ email, password });
};

export { makeLoginUseCase };
