import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type AlertDialogProps = {
  title: string;
  description: string;
  open: boolean;
  disagreeText?: string;
  agreeText?: string;
  onAgree: () => void;
  onDisagree: () => void;
};

const AlertDialog = ({
  description,
  title,
  open,
  agreeText,
  disagreeText,
  onAgree,
  onDisagree,
}: AlertDialogProps) => (
  <div>
    <Dialog
      open={open}
      onClose={() => null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onDisagree}
          color="primary"
          variant="contained"
          autoFocus
        >
          {disagreeText}
        </Button>
        <Button onClick={onAgree} color="primary">
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

AlertDialog.defaultProps = {
  disagreeText: "Cancelar",
  agreeText: "Sim",
};

export default AlertDialog;
