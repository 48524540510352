import { TSignUpRemoteData } from "@app/services/user/remote-types/TSignUpRemoteData";
import { TResendVerificationRemoteData } from "@app/services/user/remote-types/TResendVerificationCodeRemote";
import { TVerificationCodeData } from "@app/services/user/remote-types/TVerificationCodeRemoteData";
import { TChangePasswordRemoteData } from "@app/services/user/remote-types/TChangePasswordRemoteData";
import { TUpdateUserStatusRemoteData } from "@app/services/user/remote-types/TUpdateUserStatusRemoteData";

import IUserEntity from "@app/features/User/data/IUserEntity";
import IStudentProgressResultEntity from "@app/features/Student/data/IStudentProgressResultEntity";

import { IUserService } from "@app/services/user";
import { useCallback } from "react";

export interface IUserRepository {
  createAccount(data: TSignUpRemoteData): Promise<void>;
  resendVerificationCode(data: TResendVerificationRemoteData): Promise<void>;
  verificationCode(data: TVerificationCodeData): Promise<void>;
  forgotPassword(email: string): Promise<void>;
  changePassword(data: TChangePasswordRemoteData): Promise<void>;
  updateUserStatus(data: TUpdateUserStatusRemoteData): Promise<void>;
  getUser(userId: number, classeId: number): Promise<IUserEntity>;
  listUserLessonsResult(
    userId: number,
    classeId: number
  ): Promise<IStudentProgressResultEntity[]>;
}

const useUserRepository = (service: IUserService): IUserRepository => {
  const createAccount = useCallback(
    async (data: TSignUpRemoteData) => {
      await service.signUpService(data);
    },
    [service]
  );

  const resendVerificationCode = useCallback(
    async (data: TResendVerificationRemoteData) => {
      await service.resendEmailService(data);
    },
    [service]
  );

  const verificationCode = useCallback(
    async (data: TVerificationCodeData) => {
      await service.verificationCodeService(data);
    },
    [service]
  );

  const forgotPassword = useCallback(
    async (email: string) => {
      await service.forgotPasswordService({ email });
    },
    [service]
  );

  const changePassword = useCallback(
    async (data: TChangePasswordRemoteData) => {
      await service.changePasswordService(data);
    },
    [service]
  );

  const updateUserStatus = useCallback(
    async (data: TUpdateUserStatusRemoteData) => {
      await service.updateUserStatusService(data);
    },
    [service]
  );

  const getUser = useCallback(
    async (userId: number, classeId: number) => {
      const user = await service.getUserService(classeId, userId);

      return user;
    },
    [service]
  );

  const listUserLessonsResult = useCallback(
    async (
      userId: number,
      classeId: number
    ): Promise<IStudentProgressResultEntity[]> => {
      const data = await service.listUserLessonsResultService(userId, classeId);

      return data;
    },
    [service]
  );

  return {
    createAccount,
    resendVerificationCode,
    verificationCode,
    forgotPassword,
    changePassword,
    updateUserStatus,
    getUser,
    listUserLessonsResult,
  };
};

export { useUserRepository };
