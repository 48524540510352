import api from "@app/config/api";

import IQuestionEntity from "@app/features/Question/data/IQuestionEntity";
import IGeneratedQuestionEntity from "@app/features/Question/data/IGeneratedQuestionEntity";

import { TCreateQuestionRemoteData } from "@app/services/question/remote-types/TCreateQuestionRemoteData";
import { TEditQuestionRemoteData } from "@app/services/question/remote-types/TEditQuestionRemoteData";

import API_SERVICES from "@app/services/constants";
import { AMOUNT_GENERATE_QUESTIONS } from "@app/constants/enums";

export interface IQuestionService {
  addQuestionService: (
    data: TCreateQuestionRemoteData
  ) => Promise<IQuestionEntity>;
  editQuestionService: (data: TEditQuestionRemoteData) => Promise<void>;
  removeQuestionService: (questionId: number) => Promise<void>;
  generateQuestionsService: (
    subject: string,
    amountQuestions: AMOUNT_GENERATE_QUESTIONS,
    classeId: number
  ) => Promise<IGeneratedQuestionEntity[]>;
}

const addQuestionService = async (
  data: TCreateQuestionRemoteData
): Promise<IQuestionEntity> =>
  api.post(API_SERVICES.QUESTION_SERVICES.CREATE, data).then((res) => res.data);

const editQuestionService = async (
  data: TEditQuestionRemoteData
): Promise<void> =>
  api
    .put(API_SERVICES.QUESTION_SERVICES.EDIT(data.id), data)
    .then((res) => res.data);

const removeQuestionService = async (questionId: number): Promise<void> =>
  api
    .delete(API_SERVICES.QUESTION_SERVICES.DELETE(questionId))
    .then((res) => res.data);

const generateQuestionsService = async (
  subject: string,
  amountQuestions: AMOUNT_GENERATE_QUESTIONS
): Promise<IGeneratedQuestionEntity[]> =>
  api
    .get(API_SERVICES.QUESTION_SERVICES.GENERATE, {
      params: { subject, amountQuestions },
    })
    .then((res) => res.data);

const questionService: IQuestionService = {
  addQuestionService,
  editQuestionService,
  removeQuestionService,
  generateQuestionsService,
};

export default questionService;
