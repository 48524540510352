import React, { ReactNode, useEffect, useState } from "react";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

import TabPanel from "@app/components/templates/TabPanel";

type StepperTampleteProps = {
  stepsScenes: ReactNode[];
  stepsLabels: string[];
  currentStep: number;
};

const StepperTamplete = ({
  stepsLabels,
  stepsScenes,
  currentStep,
}: StepperTampleteProps) => {
  const [current, setCurrent] = useState(currentStep);

  useEffect(() => {
    setCurrent(currentStep);
  }, [currentStep]);

  return (
    <>
      <Stepper alternativeLabel activeStep={current}>
        {stepsLabels.map((label, index) => (
          <Step key={label}>
            <StepButton>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      {stepsScenes.map((scene, index) => (
        <TabPanel children={scene} index={index} value={current} />
      ))}
    </>
  );
};

export default StepperTamplete;
