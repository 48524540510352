import { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IClasseModel from "@app/features/Classe/domain/models/IClasseModel";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import { images } from "@app/assets";
import { INVITE_STATUS } from "@app/constants/enums";

type InviteDialogProps = {
  open: boolean;
  classe?: IClasseModel | null;
  onClose: () => void;
  onResult: (classe: IClasseModel, status: INVITE_STATUS) => void;
};

const InviteDialog = ({
  open,
  classe,
  onClose,
  onResult,
}: InviteDialogProps) => {
  const handleOnAgree = useCallback(() => {
    if (classe) {
      onResult(classe, INVITE_STATUS.ACCEPTED);
    }
  }, [classe, onResult]);

  const handleOnRefuse = useCallback(() => {
    if (classe) {
      onResult(classe, INVITE_STATUS.REFUSED);
    }
  }, [classe, onResult]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        Voce recebeu um convite para participar de um nova turma, revise as
        informações abaixo:
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Divider sx={{ mb: 2 }} />
        <Card sx={{ maxWidth: 180, display: "flex", alignSelf: "center" }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="150"
              image={classe?.image || images.logoBackground}
              alt="green iguana"
            />
            <Divider sx={{ mt: 2 }} />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {classe?.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {classe?.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <DialogContentText id="alert-dialog-description">
          Sua permissão para esta turma será:
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {`• ${classe?.role?.name}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Fechar
        </Button>
        <Button onClick={handleOnRefuse} color="error" variant="outlined">
          Recusar
        </Button>
        <Button onClick={handleOnAgree} variant="contained">
          Aceitar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteDialog;
