import { useCallback } from "react";

import ICompanyEntity from "@app/features/Company/data/ICompanyEntity";

import { TCreateCompanyRemoteData } from "@app/services/company/remote-types/TCreateCompanyRemoteData";
import { TEditCompanyRemoteData } from "@app/services/company/remote-types/TEditCompanyRemoteData";

import { ICompanyService } from "@app/services/company";
import IUserModel from "@app/features/User/domain/models/IUserModel";

export interface ICompanyRepository {
  createCompany(data: TCreateCompanyRemoteData): Promise<void>;
  deleteCompany(artworkId: number): Promise<void>;
  getCompany(companyId: number): Promise<ICompanyEntity>;
  editCompany(data: TEditCompanyRemoteData): Promise<void>;
  getCompanies(): Promise<ICompanyEntity[]>;
  listAllUsersFromCompany(companyId: number): Promise<IUserModel[]>;
}

const useCompanyRepository = (
  companyService: ICompanyService
): ICompanyRepository => {
  const createCompany = useCallback(
    async (data: TCreateCompanyRemoteData): Promise<void> => {
      await companyService.createCompanyService(data);
    },
    [companyService]
  );

  const deleteCompany = useCallback(
    async (companyId: number): Promise<void> => {
      await companyService.deleteCompanyService(companyId);
    },
    [companyService]
  );

  const getCompany = useCallback(
    async (companyId: number): Promise<ICompanyEntity> => {
      const company = await companyService.getCompanyService(companyId);
      return company;
    },
    [companyService]
  );

  const editCompany = useCallback(
    async (data: TEditCompanyRemoteData): Promise<void> => {
      await companyService.editCompanyService(data);
    },
    [companyService]
  );

  const getCompanies = useCallback(async (): Promise<ICompanyEntity[]> => {
    const companies = await companyService.getCompaniesService();
    return companies;
  }, [companyService]);

  const listAllUsersFromCompany = useCallback(
    async (companyId: number): Promise<IUserModel[]> => {
      const users = await companyService.listAllUsersFromCompanyService(
        companyId
      );
      return users;
    },
    [companyService]
  );

  return {
    createCompany,
    deleteCompany,
    getCompany,
    editCompany,
    getCompanies,
    listAllUsersFromCompany,
  };
};

export { useCompanyRepository };
