import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import getCompaniesUseCase from "@app/features/Company/domain/use-cases/getCompaniesUseCase";

import { ICompanyRepository } from "@app/features/Company/data/companyRepository";

import handleApplicationError from "@app/handlers/handleApiError";

const useListCompanyViewModel = (repository: ICompanyRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getListCompanies = useCallback(async () => {
    try {
      setLoading(true);

      await getCompaniesUseCase(
        {
          getCompanies: repository.getCompanies,
        },
        () => navigate("/admin/classes")
      );
      setLoading(false);
    } catch (error) {
      handleApplicationError.handleError(error);
    }
  }, [repository.getCompanies, navigate]);

  return { isLoading, getListCompanies };
};

export { useListCompanyViewModel };
