import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FiTrash, FiPlus, FiCheck, FiCast } from "react-icons/fi";
import {
  Grid,
  Button,
  Box,
  TextField,
  MenuItem,
  Paper,
  FormLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import Lottie from "lottie-react";
import { useSelector } from "react-redux";

import SelectUseForm from "@app/components/organisms/SelectUseForm";
import LoadingLock from "@app/components/molecules/LoadingLock";
import AlertDialog from "@app/components/molecules/AlertDialog";
import DrawerResource, {
  DrawerResourceRef,
} from "@app/components/organisms/DrawerResource";
import { Subtitle, Title } from "@app/components/atoms/Text";

import { useFormQuestionViewModel } from "@app/features/Question/view/Form/formQuestionViewModel";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { useQuestionRepository } from "@app/features/Question/data/questionRepository";
import questionService from "@app/services/question";

import GenericForm, {
  TGenericForm,
  GenericFormRef,
} from "@app/features/Question/view/Form/GenericForm";
import MultipleChoiceForm, {
  MultipleChoiceFormRef,
  TMultipleChoiceForm,
} from "@app/features/Question/view/Form/MultipleChoiceForm";

import {
  AMOUNT_GENERATE_QUESTIONS,
  QUESTION_TYPE,
  RESOURCE_TYPE,
} from "@app/constants/enums";
import {
  AMOUNT_GENERATE_QUESTIONS_OPTIONS,
  QUESTION_TYPE_OPTIONS,
} from "@app/constants/optionsSelect";
import { regex } from "@app/constants";
import theme from "@app/config/theme";
import Util from "@app/util";
import { RootState } from "@app/config/store";
import { lotties } from "@app/assets";

export type TQuestion = {
  id?: number;
  description: string;
  resourceMedia?: IResourceModel;
  questionType: QUESTION_TYPE;
  data: TMultipleChoiceForm | TGenericForm;
};

export type TQuestionForm = {
  questions: TQuestion[];
};

type QuestionFormProps = {
  onValidateSuccess: (data: TQuestionForm) => void;
  footerActions?: ReactNode;
  dataForm?: TQuestionForm;
  edit?: boolean;
  pageId?: number;
};

const QuestionForm = ({
  footerActions,
  onValidateSuccess,
  dataForm,
  edit,
  pageId,
}: QuestionFormProps) => {
  const genericFormRef = useRef<GenericFormRef[]>([]);
  const multipleFormRef = useRef<MultipleChoiceFormRef[]>([]);
  const drawerMediaResourceRef = useRef<DrawerResourceRef>();

  const [subject, setSubject] = useState("");
  const [amountQuestions, setAmountQuestions] =
    useState<AMOUNT_GENERATE_QUESTIONS>(AMOUNT_GENERATE_QUESTIONS.TEN);
  const [indexQuestion, setCurrentIndexQuestion] = useState<number>();
  const [openGenerateQuestionsDialog, setOpenGenerateQuestionsDialog] =
    useState(false);

  const { currentClasse } = useSelector((state: RootState) => state.classe);

  const questionRepository = useQuestionRepository(questionService);
  const {
    generateQuestions,
    removeQuestion,
    addQuestion,
    editQuestion,
    isLoading,
    generatedQuestions,
  } = useFormQuestionViewModel(questionRepository);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        questions: yup.array().of(
          yup.object().shape({
            description: yup
              .string()
              .required("Preencha o campo enunciado da pergunta."),
            resourceMedia: yup.object().optional(),
            questionType: yup
              .mixed()
              .oneOf([
                QUESTION_TYPE.MULTIPLE_CHOICE,
                QUESTION_TYPE.ORDERING,
                QUESTION_TYPE.SPEAKING,
                QUESTION_TYPE.TEXT,
              ]),
          })
        ),
      })
    ),
    defaultValues: {
      questions: [] as TQuestion[],
    },
    mode: "onSubmit",
  });

  const [questions] = watch(["questions"]);

  const getValuePosition = useCallback(
    (index: number, key: keyof TQuestion) => questions[index][key],
    [questions]
  );

  const handleGetYoutubeId = (index: number) => {
    const { questions } = getValues();

    let match = questions[index].resourceMedia?.url?.match(regex.youtubeUrl);
    if (match && match[2].length === 11) {
      return match[2];
    }
  };

  const handleAddQuestion = () => {
    const question: TQuestion = {
      description: "",
      resourceMedia: undefined,
      questionType: QUESTION_TYPE.TEXT,
      data: { answer: "" },
    };
    setValue("questions", [...questions, question]);
    if (edit) {
    }
  };

  const validateAnswers = useCallback(
    async (indexQuestion: number): Promise<boolean> => {
      const question = questions[indexQuestion];

      const validAnswers = await Util.asyncEvery(
        [question],
        async (item, _) => {
          switch (item.questionType) {
            case QUESTION_TYPE.MULTIPLE_CHOICE:
              const { answers } = await multipleFormRef.current?.[
                indexQuestion
              ].validate();
              if (answers) {
                question.data = { answers };
              }
              return !!answers;

            case QUESTION_TYPE.ORDERING:
            case QUESTION_TYPE.SPEAKING:
            case QUESTION_TYPE.TEXT:
              const validateGenericForm = await genericFormRef.current?.[
                indexQuestion
              ].validate();
              if (validateGenericForm) {
                question.data = validateGenericForm;
              }
              return !!validateGenericForm;
          }
        }
      );

      return validAnswers;
    },
    [questions]
  );

  const saveNewQuestion = useCallback(
    async (indexQuestion: number) => {
      const question = questions[indexQuestion];

      const validAnswers = await validateAnswers(indexQuestion);
      if (pageId && validAnswers) {
        const questionSaved = await addQuestion({ ...question, pageId });
        questions[indexQuestion].id = questionSaved?.id;
        setValue("questions", questions);
      }
    },
    [addQuestion, setValue, validateAnswers, questions, pageId]
  );

  const handleChangeAmountQuestions = useCallback(
    (event: SelectChangeEvent) => {
      setAmountQuestions(
        event.target.value as unknown as AMOUNT_GENERATE_QUESTIONS
      );
    },
    []
  );

  const hideDialog = useCallback(() => {
    setOpenGenerateQuestionsDialog(false);
  }, []);

  const handleGenerateQuestions = useCallback(() => {
    if (currentClasse) {
      const { id } = currentClasse;
      generateQuestions(subject, amountQuestions, id);
      hideDialog();
    }
  }, [generateQuestions, hideDialog, subject, currentClasse, amountQuestions]);

  const showDialog = useCallback(() => {
    setOpenGenerateQuestionsDialog(true);
  }, []);

  const handleSaveQuestion = useCallback(
    async (index: number) => {
      const question = questions[index];
      const validAnswers = await validateAnswers(index);
      const questionId = question.id;

      if (questionId && validAnswers) {
        editQuestion({ ...question, id: questionId });
      } else {
        saveNewQuestion(index);
      }
    },
    [questions, validateAnswers, editQuestion, saveNewQuestion]
  );

  const handleRemoveQuestion = useCallback(
    (index: number) => {
      const questionsRemoved = questions.splice(index, 1);
      setValue("questions", questions);
      const [question] = questionsRemoved;

      if (edit && question.id) {
        removeQuestion(question.id);
      }
    },
    [questions, setValue, edit, removeQuestion]
  );

  const setCurrentFormData = useCallback(
    (index: number) => {
      const questionType = getValuePosition(index, "questionType");
      switch (questionType) {
        case QUESTION_TYPE.MULTIPLE_CHOICE:
          const multipleChoiceForm = (
            <MultipleChoiceForm
              ref={(ref: MultipleChoiceFormRef) =>
                (multipleFormRef.current[index] = ref)
              }
              edit={edit}
              dataForm={questions[index].data as unknown as TMultipleChoiceForm}
            />
          );
          return multipleChoiceForm;

        case QUESTION_TYPE.ORDERING:
        case QUESTION_TYPE.SPEAKING:
        case QUESTION_TYPE.TEXT:
          const genericForm = (
            <GenericForm
              ref={(ref: GenericFormRef) =>
                (genericFormRef.current[index] = ref)
              }
              edit={edit}
              dataForm={questions[index].data as unknown as TGenericForm}
            />
          );
          return genericForm;
      }
    },
    [getValuePosition, questions, edit]
  );

  const onSubmit = useCallback(
    async (data: TQuestionForm) => {
      const validAnswers = await Util.asyncEvery(
        data.questions,
        async (item, index) => {
          switch (item.questionType) {
            case QUESTION_TYPE.MULTIPLE_CHOICE:
              const validateMultipleForm = await multipleFormRef.current?.[
                index
              ].validate();
              if (validateMultipleForm) {
                data.questions[index].data = validateMultipleForm;
              }
              return !!validateMultipleForm;

            case QUESTION_TYPE.ORDERING:
            case QUESTION_TYPE.SPEAKING:
            case QUESTION_TYPE.TEXT:
              const validateGenericForm = await genericFormRef.current?.[
                index
              ].validate();
              if (validateGenericForm) {
                data.questions[index].data = validateGenericForm;
              }
              return !!validateGenericForm;
          }
        }
      );

      if (validAnswers) {
        onValidateSuccess(data);
      }
    },
    [onValidateSuccess]
  );

  const handleOpenModal = useCallback((index: number) => {
    drawerMediaResourceRef.current?.openDrawer();
    setCurrentIndexQuestion(index);
  }, []);

  const handleCloseModal = useCallback(() => {
    drawerMediaResourceRef.current?.closeDrawer();
    setCurrentIndexQuestion(undefined);
  }, []);

  const removeResourceFromQuestion = useCallback(
    (index: number) => {
      setValue(`questions.${index}.resourceMedia`, undefined);
    },
    [setValue]
  );

  const handleSelectResource = useCallback(
    (data: IResourceModel[]) => {
      if (!!data.length && indexQuestion !== undefined) {
        const item = data[0];
        setValue(`questions.${indexQuestion}.resourceMedia`, item);
      }
    },
    [setValue, indexQuestion]
  );

  useEffect(() => {
    if (generatedQuestions?.questions) {
      setValue("questions", generatedQuestions?.questions);
    }
  }, [generatedQuestions, setValue]);

  useEffect(() => {
    reset(dataForm);
  }, [dataForm, reset]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      <AlertDialog
        open={openGenerateQuestionsDialog}
        onAgree={handleGenerateQuestions}
        onDisagree={hideDialog}
        title={"Atenção!!!"}
        description={`Essa é uma funcionalidade em fase de testes, sempre revise as perguntas e repostas geradas para ter certeza que elas estão no contexto correto para seus alunos e sua turma.`}
      />

      {!questions?.length && (
        <Paper
          elevation={2}
          sx={{
            pl: 3,
            pr: 3,
            pt: 3,
            width: "50vw",
            justifySelf: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title weight="600" align="center" color={theme.palette.primary.main}>
            O Sharpii pode gerar as perguntas para você
          </Title>
          <Lottie
            animationData={lotties.ai}
            loop={false}
            style={{
              height: 150,
            }}
          />

          <Subtitle align="center" weight="600" color={theme.palette.grey[500]}>
            Para gerar as perguntas com a IA(Inteligência artificial) do Sharpii
            você só precisa digitar qual será o tema das perguntas dessa aula.
          </Subtitle>

          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Digite o tema das suas perguntas"
                inputProps={{ maxLength: 30 }}
                variant="outlined"
                onChange={(e) => setSubject(e.target.value)}
                helperText={"0/30"}
                margin="normal"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">
                  Quantidade de perguntas
                </InputLabel>
                <Select
                  value={amountQuestions?.toString()}
                  label="Quantidade de perguntas"
                  onChange={handleChangeAmountQuestions}
                >
                  {AMOUNT_GENERATE_QUESTIONS_OPTIONS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      Gerar {item.label} perguntas
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              onClick={showDialog}
              size="medium"
              disabled={!subject}
            >
              Gerar perguntas com IA
            </Button>
          </Box>
          <Subtitle
            align="center"
            weight="600"
            color={theme.palette.text.primary}
          >
            Ou você pode cadastrar perguntas manualmente
          </Subtitle>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "center",
              pb: 3,
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<FiPlus />}
              onClick={handleAddQuestion}
              size="medium"
            >
              Cadastrar manualmente
            </Button>
          </Box>
        </Paper>
      )}

      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        {questions.map((question, index) => (
          <Paper sx={{ p: 2, m: 2 }} key={index}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Enunciado da pergunta"
                  fullWidth
                  value={getValues(`questions.${index}.description`)}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.questions?.[index]?.description}
                  helperText={!!errors.questions?.[index]?.description?.message}
                  {...register(`questions.${index}.description`)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectUseForm
                  control={control}
                  label="Tipo da pergunta"
                  defaultValue={String(
                    getValues(`questions.${index}.questionType`)
                  )}
                  error={!!errors.questions?.[index]?.questionType}
                  helperText={errors.questions?.[index]?.questionType?.message}
                  {...register(`questions.${index}.questionType`)}
                >
                  {QUESTION_TYPE_OPTIONS.map((option, index, _) => (
                    <MenuItem key={index} value={String(option.value)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </SelectUseForm>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormLabel component="legend" sx={{ mb: 1 }}>
                  Recurso complementares - Máximo de 1 item
                </FormLabel>
                {question.resourceMedia && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      flexDirection: "column",
                      display: "flex",
                    }}
                    alignItems={"flex-start"}
                    flexDirection="column"
                  >
                    {question.resourceMedia?.type === RESOURCE_TYPE.EMBED && (
                      <iframe
                        width="350"
                        height="155"
                        src={`https://www.youtube.com/embed/${handleGetYoutubeId(
                          index
                        )}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        style={{ borderRadius: 5, borderWidth: 0 }}
                      />
                    )}
                    {question.resourceMedia?.type === RESOURCE_TYPE.IMAGE && (
                      <img
                        alt={question.resourceMedia.name}
                        width="350"
                        height="155"
                        src={question.resourceMedia.url}
                        title={question.resourceMedia.name}
                        style={{ borderRadius: 5, borderWidth: 0 }}
                      />
                    )}
                    {question.resourceMedia?.type === RESOURCE_TYPE.AUDIO && (
                      <Box>
                        <audio controls>
                          <source src={question.resourceMedia?.url} />
                          Your browser does not support the audio element.
                        </audio>
                      </Box>
                    )}

                    <Button
                      variant="text"
                      onClick={() => removeResourceFromQuestion(index)}
                      endIcon={<FiTrash color={theme.palette.error.main} />}
                    >
                      Remover mídia
                    </Button>
                  </Grid>
                )}
                {!question.resourceMedia && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleOpenModal(index)}
                    startIcon={<FiCast />}
                  >
                    Adicionar Recurso
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box sx={{ pl: 2, pr: 2 }}>{setCurrentFormData(index)}</Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                color="error"
                variant="outlined"
                startIcon={<FiTrash />}
                onClick={() => handleRemoveQuestion(index)}
              >
                Remover pergunta
              </Button>
              {edit && (
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<FiCheck />}
                  onClick={() => handleSaveQuestion(index)}
                >
                  Salvar pergunta
                </Button>
              )}
            </Grid>
          </Paper>
        ))}
        {(!questions || questions?.length < 20) && questions.length >= 1 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              pt: 5,
              pb: 3,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<FiPlus />}
              onClick={handleAddQuestion}
              size="medium"
            >
              Nova a pergunta
            </Button>
          </Box>
        )}

        <DrawerResource
          ref={drawerMediaResourceRef}
          title="Mídia"
          onAddItems={handleSelectResource}
          onCancel={handleCloseModal}
          maxItems={1}
          acceptedFiles={{ audio: true, image: true }}
          resourcesAllowed={[
            RESOURCE_TYPE.AUDIO,
            RESOURCE_TYPE.EMBED,
            RESOURCE_TYPE.IMAGE,
          ]}
        />
        <Box sx={{ mt: 10 }}>{footerActions}</Box>
      </form>
    </>
  );
};

export default QuestionForm;
