import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";

export const LOADING_COMPANY = "LOADING_COMPANY";
export const COMPANIES = "COMPANIES";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";

export interface ICompanyStore {
  loading: boolean;
  companies: ICompanyModel[];
  currentCompany: ICompanyModel | undefined;
}

interface LoadingCompanyAction {
  type: typeof LOADING_COMPANY;
  payload: boolean;
}

interface SetCurrentCompanyAction {
  type: typeof SET_CURRENT_COMPANY;
  payload: ICompanyModel;
}

interface SetCompaniesAction {
  type: typeof COMPANIES;
  payload: ICompanyModel[];
}

export type CompanyActionTypes =
  | LoadingCompanyAction
  | SetCurrentCompanyAction
  | SetCompaniesAction;
