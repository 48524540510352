import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import { TCreateClasseViewModel } from "@app/features/Classe/view/Create/createClassViewModel";

const createClasseUseCase = async (
  repository: Pick<IClasseRepository, "createClasse">,
  data: TCreateClasseViewModel
): Promise<void> => {
  await repository.createClasse(data);
};

export default createClasseUseCase;
