import React, { ReactNode } from "react";

import BaseText, { BaseTextProps } from "../Base";

type CaptionProps = {
  children: ReactNode;
} & BaseTextProps;

const Caption = ({ children, align, noWrap, color, weight }: CaptionProps) => (
  <BaseText
    size="caption"
    align={align}
    noWrap={noWrap}
    color={color}
    weight={weight}
  >
    {children}
  </BaseText>
);

export default Caption;
