import { Theme } from "@mui/material/styles";

import themeInstance from "@app/config/theme";

const styles = (theme: Theme = themeInstance) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    width: "100%",
    minHeight: "75vh",
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    width: 200,
    position: "relative",
    overflow: "auto",
    maxHeight: "100%",
    borderStyle: "solid",
    borderRightWidth: "2px",
    borderWidth: "0px",
    borderColor: theme.palette.primary.main,
  },
  content: {
    width: "100%",
    padding: theme.spacing(5),
  },
});

export default styles;
