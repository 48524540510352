import { IRoleRepository } from "@app/features/Role/data/roleRepository";

import IRoleModel from "@app/features/Role/domain/models/IRoleModel";
import IRoleEntity from "@app/features/Role/data/IRoleEntity";

const getRolesUseCase = async (
  repository: Pick<IRoleRepository, "getRoles">
): Promise<IRoleEntity[]> => {
  const rolesEntity = await repository.getRoles();

  const roleModel = mapToDomain(rolesEntity);

  return roleModel;
};

const mapToDomain = (entities: IRoleModel[]): IRoleEntity[] => {
  const roleModel = entities.map((role) => role);
  return roleModel;
};

export default getRolesUseCase;
