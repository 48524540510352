import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

import IUsersFromClasseModel from "@app/features/Classe/domain/models/IUsersFromClasseModel";
import IUserFromClasseEntity from "@app/features/Classe/data/IUserFromClasseEntity";

import { TListUsersFromClasseSearchParams } from "@app/services/classe";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { ROLE } from "@app/constants/enums";

const listUsersFromClasseUseCase = async (
  repository: Pick<IClasseRepository, "listUsersFromClasse">,
  classeId: number,
  roles: ROLE[],
  pageConfig: IPageModel,
  filterParams?: TListUsersFromClasseSearchParams
): Promise<IPaginationModel<IUsersFromClasseModel>> => {
  const userEntity = await repository.listUsersFromClasse(
    classeId,
    roles,
    pageConfig,
    filterParams
  );

  const dataDomain = mapToDomain(userEntity.data);

  return dataDomain;
};

const mapToDomain = (
  users: IUserFromClasseEntity[]
): IPaginationModel<IUsersFromClasseModel> => {
  const domain = users.map((user): IUsersFromClasseModel => {
    const { document, email, id, name, photo, role, status, userStatusClasse } =
      user;

    return {
      document,
      email,
      id,
      name,
      photo,
      role,
      status,
      userStatusClasse,
    };
  });

  return {
    count: domain.length,
    data: domain,
  };
};

export default listUsersFromClasseUseCase;
