import { Action, Dispatch } from "redux";

import {
  LOADING_CURRENT_USER,
  SET_CURRENT_USER,
} from "@app/features/Profile/data/profileActionsType";

import IProfileModel from "@app/features/Profile/domain/models/IProfileModel";

export const setCurrentUserAction =
  (user: IProfileModel) => (dispatch: Dispatch<Action>) => {
    dispatch({ type: SET_CURRENT_USER, payload: user });
  };

export const setLoadingCurrentUserAction =
  (value: boolean) =>
  (dispatch: Dispatch<Action>): Action =>
    dispatch({ type: LOADING_CURRENT_USER, payload: value });
