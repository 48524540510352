import { ROLE } from "./enums";

export type OptionsDrawerType = {
  name: string;
  pathRoute: string | ((value: number) => string);
  permissions: ROLE[];
};

const CONFIGS_OPTIONS: OptionsDrawerType[] = [
  {
    name: "Dados da turma ",
    pathRoute: (classeId: number) => `classes/${classeId}`,
    permissions: [ROLE.OWNER, ROLE.ADMIN, ROLE.TEACHER],
  },
];

export default CONFIGS_OPTIONS;
