import IProfileModel from "@app/features/Profile/domain/models/IProfileModel";

export const LOADING_CURRENT_USER = "LOADING_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export interface IProfileStore {
  loading: boolean;
  currentUser: IProfileModel | undefined;
}

interface LoadingCurrenctUserAction {
  type: typeof LOADING_CURRENT_USER;
  payload: boolean;
}

interface SetCurrentUserAction {
  type: typeof SET_CURRENT_USER;
  payload: IProfileModel;
}

export type ProfileActionTypes =
  | LoadingCurrenctUserAction
  | SetCurrentUserAction;
