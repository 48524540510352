import { useCallback, useState } from "react";

import createModuleUseCase from "@app/features/Module/domain/use-cases/createModuleUseCase";

import { IModuleRepository } from "@app/features/Module/data/moduleRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { useNavigate } from "react-router-dom";

export type TCreateModuleViewModel = {
  name: string;
  classeId: number;
};

const useCreateModuleViewModel = (repository: IModuleRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createModule = useCallback(
    async (data: TCreateModuleViewModel) => {
      try {
        setLoading(true);

        await createModuleUseCase(
          {
            createModule: repository.createModule,
          },
          data
        );
        setLoading(false);
        showNotification("Módulo criado com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.createModule, navigate]
  );

  return { isLoading, createModule };
};

export { useCreateModuleViewModel };
