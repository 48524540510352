import { useCallback, useState } from "react";
import { IUserRepository } from "@app/features/User/data/userRepository";
import { signUpUseCase } from "@app/features/SignUp/domain/useCases/signUpUseCase";
import handleApplicationError from "@app/handlers/handleApiError";
import { useNavigate } from "react-router-dom";
import { EMAIL_TYPES } from "@app/constants/enums";

export type TSignUpViewModel = {
  company: {
    companyImage?: string;
    companyName: string;
    tradingName?: string;
    document?: string;
    zipcode?: string;
    state?: string;
    city?: string;
    address?: string;
    addressNumber?: string;
    phone?: string;
  };
  name: string;
  lastname: string;
  password: string;
  document: string;
  email: string;
  confirmPassword: string;
  birthDate: string;
  languageId: string;
};

const useSignUpViewModel = (respository: IUserRepository) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const createAccount = useCallback(
    async (data: TSignUpViewModel) => {
      setIsLoading(true);

      try {
        await signUpUseCase(
          {
            createAccount: respository.createAccount,
          },
          data
        );
        setIsLoading(false);
        navigate("/verification-code", {
          state: {
            email: data.email,
            emailType: EMAIL_TYPES.WELCOME,
            title: "Ativação de conta",
            navigateTo: "/login",
          },
        });
      } catch (error) {
        setIsLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [navigate, respository.createAccount]
  );

  return {
    isLoading,
    createAccount,
  };
};

export { useSignUpViewModel };
