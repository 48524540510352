import React, { useCallback } from "react";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import resourceService from "@app/services/resource";
import {
  TCreateRosourceViewModel,
  useCreateResourceViewModel,
} from "@app/features/Resource/views/Create/createResourceViewModel";

import ResourceForm, {
  TResourceForm,
} from "@app/features/Resource/views/Form/ResourceForm";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";
import StandardTemplate from "@app/components/templates/Standard";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

const CreateResourse = () => {
  const resourceRepository = useResourceRepository(resourceService);
  const { createResource } = useCreateResourceViewModel(resourceRepository);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const handleValidateSuccess = useCallback(
    (data: TResourceForm[]) => {
      const dataFormated: TCreateRosourceViewModel[] = data.map(
        (item): TCreateRosourceViewModel => {
          const { languageId, name, url, fileKey, size } = item;
          return {
            languageId,
            name,
            url,
            folder: false,
            fileKey,
            size,
          };
        }
      );

      if (currentCompany) {
        const { id } = currentCompany;
        createResource(dataFormated, id, true);
      }
    },
    [createResource, currentCompany]
  );

  return (
    <StandardTemplate title="Biblioteca" showBackButton>
      <LoadingLock isLoading={false} />

      <ResourceForm
        allowSelectLanguage
        onValidateSuccess={handleValidateSuccess}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default CreateResourse;
