import React, { ReactNode } from "react";

import BaseText, { BaseTextProps } from "../Base";

type HeadlineProps = {
  children: ReactNode;
} & BaseTextProps;

const Headline = ({
  children,
  align,
  noWrap,
  color,
  weight,
}: HeadlineProps) => (
  <BaseText
    size="h4"
    align={align}
    noWrap={noWrap}
    color={color}
    weight={weight}
  >
    {children}
  </BaseText>
);

export default Headline;
