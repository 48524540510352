import { ProfileActionTypes, IProfileStore } from "./profileActionsType";

const INITIAL_STATE: IProfileStore = {
  loading: true,
  currentUser: undefined,
};

const classeReducer = (
  state = INITIAL_STATE,
  action: ProfileActionTypes
): IProfileStore => {
  switch (action.type) {
    case "LOADING_CURRENT_USER":
      return { ...state, loading: action.payload };

    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload, loading: false };

    default:
      return state;
  }
};

export default classeReducer;
