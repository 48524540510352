import React, {
  forwardRef,
  RefObject,
  useCallback,
  useImperativeHandle,
} from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import UploadMultiple from "@app/components/atoms/UploadMultiple";
import { TUploadPayload } from "@app/components/atoms/Upload";
import { TAcceptedFileType } from "@app/components/atoms/FileInput";

export type TResourceForm = {
  name: string;
  url: string;
  fileKey: string;
  size: number;
};

export type FileFormRef = {
  validate: () => Promise<{ resources: TResourceForm[] }>;
};

type FileFormProps = {
  ref?: RefObject<FileFormRef>;
  maxItems?: number;
  acceptedFiles?: TAcceptedFileType;
};

const FileForm = forwardRef<unknown, FileFormProps>(
  ({ maxItems, acceptedFiles }: FileFormProps, ref) => {
    const {
      formState: { errors },
      getValues,
      setValue,
      watch,
      trigger,
    } = useForm({
      resolver: yupResolver(
        yup.object().shape({
          resources: yup
            .array()
            .of(
              yup.object().shape({
                name: yup.string(),
                size: yup.number(),
                fileKey: yup.string(),
                url: yup.string(),
              })
            )
            .min(1, "Faça o upload de pelo menos 1 arquivo"),
        })
      ),
      defaultValues: {
        resources: [] as TResourceForm[],
      },
      mode: "onSubmit",
    });

    const [resources] = watch(["resources"]);

    const handleUploadSuccess = useCallback(
      (data: TUploadPayload) => {
        const { fileKey, name, size, url } = data;

        resources.push({
          fileKey,
          name,
          size,
          url,
        });
        setValue("resources", resources);
      },
      [resources, setValue]
    );

    const handleRemove = useCallback(
      (index: number) => {
        resources.splice(index, 1);
        setValue("resources", resources);
      },
      [resources, setValue]
    );

    const validate = useCallback(async () => {
      const value = await trigger();
      if (value) {
        return getValues();
      }
    }, [trigger, getValues]);

    useImperativeHandle(ref, () => ({ validate }), [validate]);

    return (
      <UploadMultiple
        acceptedFiles={acceptedFiles}
        onRemove={handleRemove}
        onUploadSuccess={handleUploadSuccess}
        maxItems={maxItems}
        error={!!errors.resources}
        errorMessage={errors.resources?.message}
      />
    );
  }
);

export default FileForm;
