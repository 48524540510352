import React, { useCallback } from "react";

import { useClasseRepository } from "@app/features/Classe/data/classeRespository";
import { useCreateUserViewModel } from "@app/features/User/view/Create/createUserViewModel";
import classeService from "@app/services/classe";

import StudentForm, { TStudentForm } from "@app/features/Student/view/Form";

import LoadingLock from "@app/components/molecules/LoadingLock";
import StandardTemplate from "@app/components/templates/Standard";
import FooterActions from "@app/components/molecules/FooterActions";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";
import { ROLE } from "@app/constants/enums";

const CreateStudent = () => {
  const { currentClasse } = useSelector((state: RootState) => state.classe);
  const { currentCompany } = useSelector((state: RootState) => state.company);

  const classeRepository = useClasseRepository(classeService);
  const { addUserToClasse, isLoading } =
    useCreateUserViewModel(classeRepository);

  const handleSave = useCallback(
    (data: TStudentForm) => {
      if (currentClasse && currentCompany) {
        if (data.birthDate) {
          const [day, month, year] = data.birthDate?.split("/");
          const date = new Date(Number(year), Number(month) - 1, Number(day));

          addUserToClasse({
            ...data,
            classeId: currentClasse.id,
            companyId: currentCompany.id,
            roleName: ROLE.STUDENT,
            birthDate: date,
          });
        } else {
          addUserToClasse({
            ...data,
            classeId: currentClasse.id,
            companyId: currentCompany.id,
            roleName: ROLE.STUDENT,
            birthDate: undefined,
          });
        }
      }
    },
    [addUserToClasse, currentClasse, currentCompany]
  );

  return (
    <StandardTemplate title="Adicionar aluno" showBackButton>
      <LoadingLock isLoading={isLoading} />
      <StudentForm
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </StandardTemplate>
  );
};

export default CreateStudent;
