import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { TCreateRosourceViewModel } from "@app/features/Resource/views/Create/createResourceViewModel";

import IResourceEntity from "@app/features/Resource/data/IResourceEntity";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";

const createResourceUseCase = async (
  repository: Pick<IResourceRepository, "createResources">,
  data: TCreateRosourceViewModel[],
  companyId: number
): Promise<IResourceModel[]> => {
  const entity = await repository.createResources(companyId, data);
  const domain = mapToDomain(entity);
  return domain;
};

const mapToDomain = (resources: IResourceEntity[]): IResourceModel[] => {
  const domain = resources.map((resource): IResourceModel => {
    const { fileKey, id, name, size, type, url, extension, languageId } =
      resource;

    return {
      fileKey,
      id,
      name,
      size,
      type,
      url,
      extension,
      languageId,
    };
  });

  return domain;
};

export default createResourceUseCase;
