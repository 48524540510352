import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import { TInviteClasseViewModel } from "@app/features/Classe/view/List/listClassesViewModel";

const handleInviteClasseUseCase = async (
  repository: Pick<IClasseRepository, "handleInviteClasse">,
  data: TInviteClasseViewModel
): Promise<void> => {
  await repository.handleInviteClasse(data);
};

export default handleInviteClasseUseCase;
