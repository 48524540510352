import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import { TAddUserViewModel } from "@app/features/User/view/Create/createUserViewModel";

const addUserToClasseUseCase = async (
  repository: Pick<IClasseRepository, "addUserToClasse">,
  data: TAddUserViewModel
): Promise<void> => {
  await repository.addUserToClasse(data);
};

export default addUserToClasseUseCase;
