import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import addUserToClasseUseCase from "@app/features/Classe/domain/use-cases/addUserToClasseUseCase";

import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { ROLE } from "@app/constants/enums";

export type TAddUserViewModel = {
  name: string;
  email: string;
  roleName: ROLE;
  phone?: string;
  birthDate?: Date;
  expireAt?: string;
  classeId: number;
  companyId: number;
};

const useCreateUserViewModel = (repository: IClasseRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const addUserToClasse = useCallback(
    async (data: TAddUserViewModel) => {
      try {
        setLoading(true);

        await addUserToClasseUseCase(
          {
            addUserToClasse: repository.addUserToClasse,
          },
          data
        );
        setLoading(false);
        showNotification(
          "Foi enviado um convite para o e-mail do usuário.",
          "SUCCESS"
        );
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.addUserToClasse, navigate]
  );

  return { isLoading, addUserToClasse };
};

export { useCreateUserViewModel };
