import { useCallback, useState } from "react";

import getProfileUseCase from "@app/features/Profile/domain/use-cases/getProfileUseCase";
import editProfileUseCase from "@app/features/Profile/domain/use-cases/editProfileUseCase";

import { IProfileRepository } from "@app/features/Profile/data/profileRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import showNotification from "@app/components/molecules/Toast";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

export type TEditProfileViewModel = {
  name?: string;
  email?: string;
  document?: string;
  photo?: string;
};

const useEditProfileViewModel = (repository: IProfileRepository) => {
  const [isLoading, setLoading] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.profile);

  const getProfile = useCallback(async () => {
    try {
      setLoading(true);

      await getProfileUseCase({
        getProfile: repository.getProfile,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApplicationError.handleError(error);
    }
  }, [repository.getProfile]);

  const editProfile = useCallback(
    async (data: TEditProfileViewModel) => {
      try {
        setLoading(true);

        await editProfileUseCase(
          {
            editProfile: repository.editProfile,
          },
          data
        );
        setLoading(false);
        showNotification("Dados alterados com sucesso", "SUCCESS");
        getProfile();
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.editProfile, getProfile]
  );

  return { profile: currentUser, isLoading, editProfile, getProfile };
};

export { useEditProfileViewModel };
