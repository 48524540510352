import React, { forwardRef, useCallback, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";

import TableHeadSelection, {
  HeadCell,
} from "@app/components/molecules/TableHeadSelection";

import { useResourceRepository } from "@app/features/Resource/data/resourceRepository";
import { useListResourcesFormViewModel } from "@app/features/Lesson/view/Form/listResourcesFormViewModel";
import resourceService, {
  TListResourcesSearchParams,
} from "@app/services/resource";

import { ROWS_PER_PAGE_OPTIONS } from "@app/constants/optionsSelect";
import { RootState } from "@app/config/store";
import { RESOURCE_TYPE } from "@app/constants/enums";
import { IPageModel } from "@app/constants/interfaces";
import Util from "@app/util";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import Lottie from "lottie-react";
import { lotties } from "@app/assets";
import { Subtitle } from "@app/components/atoms/Text";

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    label: "Nome do arquivo",
  },
  {
    id: "type",
    numeric: true,
    label: "Tipo de recurso",
  },
  {
    id: "size",
    numeric: true,
    label: "Tamanho",
  },
];

export type FilListResourcesFormRef = {
  clearField: () => void;
};

type ListResourcesFormProps = {
  resourceTypes: RESOURCE_TYPE[];
  maxItems: number;
  onSelectItems: (data: IResourceModel[]) => void;
  onCancel: () => void;
};

const ListResourcesForm = forwardRef(
  ({
    resourceTypes,
    maxItems,
    onSelectItems,
    onCancel,
  }: ListResourcesFormProps) => {
    const [paginationConfig, setPaginationConfig] = useState<IPageModel>({
      page: 0,
      amount: ROWS_PER_PAGE_OPTIONS.MIN,
    });
    const [selected, setSelected] = useState<IResourceModel[]>([]);

    const { currentCompany } = useSelector((state: RootState) => state.company);
    const { currentClasse } = useSelector((state: RootState) => state.classe);

    const resourceRepository = useResourceRepository(resourceService);
    const { listResources, resources } =
      useListResourcesFormViewModel(resourceRepository);

    const handleChangeRowsPerPage = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let newRowsPerPage = Number(event.target.value);
        setPaginationConfig({ page: 0, amount: newRowsPerPage });
      },
      []
    );

    const handleChangePage = useCallback((_: unknown, newPage: number) => {
      setPaginationConfig((value) => ({ page: newPage, amount: value.amount }));
    }, []);

    const isSelected = useCallback(
      (id: number) => selected.find((item) => item.id === id),
      [selected]
    );

    const handleClick = useCallback(
      (data: IResourceModel) => {
        const itemFound = selected.find((item) => item.id === data.id);
        let newSelected: IResourceModel[] = [];

        if (itemFound) {
          newSelected = selected.filter((item) => item.id !== data.id);
          setSelected(newSelected);
        } else if (!itemFound && selected.length < maxItems) {
          newSelected = [...selected, data];
          setSelected(newSelected);
        }
        onSelectItems(newSelected);
      },
      [selected, maxItems, onSelectItems]
    );

    useEffect(() => {
      if (currentClasse) {
        let filtersData: TListResourcesSearchParams = {
          language: { key: currentClasse.language.key },
          type: resourceTypes,
        };

        if (currentCompany) {
          listResources(currentCompany.id, paginationConfig, filtersData);
        }
      }
    }, [
      listResources,
      currentCompany,
      currentClasse,
      paginationConfig,
      resourceTypes,
    ]);

    return (
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "63vh" }}>
            <Table aria-labelledby="tableTitle">
              <TableHeadSelection
                allowSelectAll={false}
                headCells={headCells}
                numSelected={selected.length}
                rowCount={resources.data.length}
              />
              <TableBody>
                {resources.data.map((row, index) => (
                  <TableRow
                    key={index}
                    hover={selected.length < maxItems}
                    role="checkbox"
                    aria-checked={!!isSelected(row.id)}
                    tabIndex={-1}
                    selected={!!isSelected(row.id)}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleClick(row)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        disabled={
                          selected.length >= maxItems && !isSelected(row.id)
                        }
                        checked={!!isSelected(row.id)}
                        inputProps={{
                          "aria-labelledby": "tes",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={"te"}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">
                      {Util.formatBytes(row.size)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={Object.values(ROWS_PER_PAGE_OPTIONS)}
            component="div"
            sx={{ position: "sticky", bottom: 0 }}
            count={resources.count}
            rowsPerPage={paginationConfig.amount}
            page={paginationConfig.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {!resources.data.length && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                height: "58vh",
              }}
            >
              <Lottie
                animationData={lotties.emptyState}
                loop={true}
                style={{
                  width: 400,
                  height: 200,
                }}
              />
              {<Subtitle>Nenhum material encontrado</Subtitle>}
            </Box>
          )}
        </Paper>
      </Box>
    );
  }
);

export default ListResourcesForm;
