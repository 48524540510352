/* eslint-disable no-shadow */

export enum EMAIL_TYPES {
  WELCOME = "WELCOME",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
}

export enum USER_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  DELETED = "DELETED",
}

export enum USER_CLASSE_STATUS {
  ACTIVE = "ACTIVE",
  INVITED = "INVITED",
  INACTIVE = "INACTIVE",
}

export enum CLASSE_VISIBILITY {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum ROLE {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  TEACHER = "TEACHER",
  STUDENT = "STUDENT",
}

export enum STATUS {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
}

export enum RESOURCE_TYPE {
  AUDIO = "AUDIO",
  EMBED = "EMBED",
  DOC = "DOC",
  IMAGE = "IMAGE",
}

export enum RELEASE_DATE_TYPE {
  NONE = "NONE",
  BY_DATE = "BY_DATE",
  BY_JOIN_USER_DATE = "BY_JOIN_USER_DATE",
}

export enum AMOUNT_GENERATE_QUESTIONS {
  TEN = 10,
  FIFTEEN = 15,
  TWENTY = 20,
}

export enum MEDIA_PAGE_TYPE {
  NONE = "NONE",
  AUDIO = "AUDIO",
  EMBED = "EMBED",
}

export enum MEDIA_QUESTION_TYPE {
  NONE = "NONE",
  AUDIO = "AUDIO",
  EMBED = "EMBED",
  IMAGE = "IMAGE",
}

export enum QUESTION_TYPE {
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  ORDERING = "ORDERING",
  SPEAKING = "SPEAKING",
  TEXT = "TEXT",
}

export enum INVITE_STATUS {
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
}

export enum STUDENT_LESSON_STATUS {
  COMPLETED = "COMPLETED",
  MINIMUM_GRADE_NOT_ACHIEVED = "MINIMUM_GRADE_NOT_ACHIEVED",
}
