import { useCallback, useState } from "react";

import getAvailableLanguagesUseCase from "@app/features/Language/domain/use-cases/getAvailableLanguagesUseCase";
import getYoutubeInfoUseCase from "@app/features/Resource/domain/use-cases/getYoutubeInfoUseCase";

import { ILanguageRepository } from "@app/features/Language/data/languageRepository";
import { IResourceRepository } from "@app/features/Resource/data/resourceRepository";

import handleApplicationError from "@app/handlers/handleApiError";
import ILanguageModel from "@app/features/Language/domain/models/ILanguageModel";

const useFormResourceViewModel = (
  languageRepository: ILanguageRepository,
  resourceRepository: IResourceRepository
) => {
  const [isLoading, setLoading] = useState(false);
  const [languages, setLanguages] = useState<ILanguageModel[]>([]);

  const getAvailableLanguages = useCallback(async () => {
    try {
      setLoading(true);

      const data = await getAvailableLanguagesUseCase({
        getAvailableLanguages: languageRepository.getAvailableLanguages,
      });
      setLanguages(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApplicationError.handleError(error);
    }
  }, [languageRepository.getAvailableLanguages]);

  const getYoutubeInfo = useCallback(
    async (videourl: string) => {
      try {
        setLoading(true);

        return await getYoutubeInfoUseCase(
          {
            getYoutubeInfo: resourceRepository.getYoutubeInfo,
          },
          videourl
        );
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [resourceRepository.getYoutubeInfo]
  );

  return { isLoading, languages, getAvailableLanguages, getYoutubeInfo };
};

export { useFormResourceViewModel };
