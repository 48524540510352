import React, { useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useCompanyRepository } from "@app/features/Company/data/companyRepository";
import companyServices from "@app/services/company";

import { useEditCompanyViewModel } from "@app/features/Company/view/Edit/editCompanyViewModel";
import CompanyForm, { TCompanyForm } from "@app/features/Company/view/Form";

import LoadingLock from "@app/components/molecules/LoadingLock";
import FooterActions from "@app/components/molecules/FooterActions";

const EditCompany = () => {
  const { id } = useParams();

  const companyRepository = useCompanyRepository(companyServices);
  const { editCompany, getCompany, isLoading, company } =
    useEditCompanyViewModel(companyRepository);

  const handleSave = useCallback(
    (data: TCompanyForm) => {
      const companyId = Number(id);
      if (companyId) {
        editCompany({ ...data, companyId });
      }
    },
    [editCompany, id]
  );

  useEffect(() => {
    if (id) {
      getCompany(Number(id));
    }
  }, [getCompany, id]);

  return (
    <>
      <LoadingLock isLoading={isLoading} />
      <CompanyForm
        dataForm={company}
        onValidateSuccess={handleSave}
        footerActions={<FooterActions />}
      />
    </>
  );
};

export default EditCompany;
