import { useCallback } from "react";

import IClasseEntity from "@app/features/Classe/data/IClasseEntity";
import IUserFromClasseEntity from "@app/features/Classe/data/IUserFromClasseEntity";

import {
  IClasseService,
  TListUsersFromClasseSearchParams,
} from "@app/services/classe";

import { TCreateClasseRemoteData } from "@app/services/classe/remote-types/TCreateClasseRemoteData";
import { TEditClasseRemoteData } from "@app/services/classe/remote-types/TEditClasseRemoteData";
import { TAddUserToClasseRemoteData } from "@app/services/classe/remote-types/TAddUserToClasseRemoteData";
import { THandleInviteClasseRemoteData } from "@app/services/classe/remote-types/THandleInviteClasseRemoteData";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { ROLE } from "@app/constants/enums";

export interface IClasseRepository {
  listClasses(companyId: number): Promise<IClasseEntity[]>;
  editClasse(data: TEditClasseRemoteData): Promise<void>;
  getClasse(classeId: number): Promise<IClasseEntity>;
  deleteClasse(classeId: number): Promise<void>;
  createClasse(data: TCreateClasseRemoteData): Promise<void>;
  listUsersFromClasse(
    classeId: number,
    roles: ROLE[],
    pageConfig: IPageModel,
    filterParams?: TListUsersFromClasseSearchParams
  ): Promise<IPaginationModel<IUserFromClasseEntity>>;
  addUserToClasse(data: TAddUserToClasseRemoteData): Promise<void>;
  removeUsersFromClasse(classeId: number, userId: number): Promise<void>;
  handleInviteClasse(data: THandleInviteClasseRemoteData): Promise<void>;
}

const useClasseRepository = (
  classeService: IClasseService
): IClasseRepository => {
  const listClasses = useCallback(
    async (companyId: number): Promise<IClasseEntity[]> => {
      const classes = await classeService.listClassesService(companyId);

      return classes;
    },
    [classeService]
  );

  const listUsersFromClasse = useCallback(
    async (
      classeId: number,
      roles: ROLE[],
      pageConfig: IPageModel,
      filterParams?: TListUsersFromClasseSearchParams
    ): Promise<IPaginationModel<IUserFromClasseEntity>> => {
      const users = await classeService.listUsersFromClasseService(
        classeId,
        roles,
        pageConfig,
        filterParams
      );

      return users;
    },
    [classeService]
  );

  const createClasse = useCallback(
    async (data: TCreateClasseRemoteData): Promise<void> => {
      await classeService.createClasseService(data);
    },
    [classeService]
  );

  const getClasse = useCallback(
    async (classeId: number): Promise<IClasseEntity> => {
      const data = await classeService.getClasseService(classeId);
      return data;
    },
    [classeService]
  );

  const deleteClasse = useCallback(
    async (classeId: number): Promise<void> => {
      await classeService.deleteClasseService(classeId);
    },
    [classeService]
  );

  const editClasse = useCallback(
    async (data: TEditClasseRemoteData): Promise<void> => {
      await classeService.editClasseService(data);
    },
    [classeService]
  );

  const removeUsersFromClasse = useCallback(
    async (classeId: number, userId: number): Promise<void> => {
      await classeService.removeUsersFromClasseService(classeId, userId);
    },
    [classeService]
  );

  const addUserToClasse = useCallback(
    async (data: TAddUserToClasseRemoteData): Promise<void> => {
      await classeService.addUserToClasseService(data);
    },
    [classeService]
  );

  const handleInviteClasse = useCallback(
    async (data: THandleInviteClasseRemoteData): Promise<void> => {
      await classeService.handleInviteClasseService(data);
    },
    [classeService]
  );

  return {
    listClasses,
    getClasse,
    deleteClasse,
    createClasse,
    listUsersFromClasse,
    addUserToClasse,
    removeUsersFromClasse,
    editClasse,
    handleInviteClasse,
  };
};

export { useClasseRepository };
