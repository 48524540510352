import { useCallback } from "react";

import IRoleEntity from "@app/features/Role/data/IRoleEntity";

import { IRoleService } from "@app/services/role";

export interface IRoleRepository {
  getRoles(): Promise<IRoleEntity[]>;
}

const useRoleRepository = (roleService: IRoleService): IRoleRepository => {
  const getRoles = useCallback(async (): Promise<IRoleEntity[]> => {
    const data = await roleService.getRolesService();
    return data;
  }, [roleService]);

  return {
    getRoles,
  };
};

export { useRoleRepository };
