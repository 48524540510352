import { IClasseRepository } from "@app/features/Classe/data/classeRespository";
import { TEditClasseViewModel } from "@app/features/Classe/view/Edit/editClasseViewModel";

const editClasseUseCase = async (
  repository: Pick<IClasseRepository, "editClasse">,
  data: TEditClasseViewModel
) => {
  await repository.editClasse(data);
};

export default editClasseUseCase;
