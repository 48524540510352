import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// NO PRÓXIMO PASSO EU MOSTRO AS TRADUÇÕES
// Buscando as nossas traduções da pasta locales (nome e local da pasta é você quem decide)
import ptTranslation from "./pt-bt.json";

export const defaultNS = "translations";

export const resources = {
  pt: ptTranslation,
};

// Não vou entrar no assunto namespaces, nem em configurações mais complexas
// O objetivo é simplicidade
i18n.use(initReactI18next).use(LanguageDetector).init({
  resources, // resources são as nossas traduções
  fallbackLng: "pt", // fallbackLng é o idioma padrão caso o browser não consiga detectar sozinho
  defaultNS, // defaultNS é o namespace padrão, podemos usar 'translations'
});

export default i18n;
