import API_SERVICES from "@app/services/constants";
import axios from "axios";
import { TCNPJRemoteData } from "./remote-types/TCNPJRemoteData";
import showNotification from "@app/components/molecules/Toast";

const searchCNPJService = async (value: string): Promise<any> =>
  axios.get(API_SERVICES.CNPJ_SERVICES.GET(value)).then((res) => res.data);

const getCNPJDataService = async (value: string) => {
  try {
    value = value.replace(/[^\d]+/g, "");
    const result = await searchCNPJService(value);
    if (result.error) {
      showNotification(result.error, "WARNING");
    }
    let domain: { [key: string]: any } = {};
    Object.keys(result as { [key: string]: any }).forEach(
      (k) =>
        (domain[k.toLowerCase().toLowerCase().replace(/\s+/g, "_")] = result[k])
    );

    return domain as TCNPJRemoteData;
  } catch (error) {
    showNotification(
      "Não foi possível fazer a busca pelo seu cnpj!",
      "WARNING"
    );
  }
};

export default getCNPJDataService;
