import { IClasseRepository } from "@app/features/Classe/data/classeRespository";

import IUserModel from "@app/features/User/domain/models/IUserModel";
import IUserFromClasseEntity from "@app/features/Classe/data/IUserFromClasseEntity";

import { TListUsersFromClasseSearchParams } from "@app/services/classe";

import IPaginationModel, { IPageModel } from "@app/constants/interfaces";
import { ROLE } from "@app/constants/enums";

const listStudentsFromClasseUseCase = async (
  repository: Pick<IClasseRepository, "listUsersFromClasse">,
  classeId: number,
  roles: ROLE[],
  pageConfig: IPageModel,
  filterParams?: TListUsersFromClasseSearchParams
): Promise<IPaginationModel<IUserModel>> => {
  const userEntity = await repository.listUsersFromClasse(
    classeId,
    roles,
    pageConfig,
    filterParams
  );

  const dataDomain = mapToDomain(userEntity.data);

  return dataDomain;
};

const mapToDomain = (
  users: IUserFromClasseEntity[]
): IPaginationModel<IUserModel> => {
  const domain = users.map((user): IUserModel => {
    const { email, id, name, photo, role, status, userStatusClasse, expireAt } =
      user;

    return {
      email,
      id,
      name,
      photo,
      role,
      status,
      userStatusClasse,
      expireAt,
    };
  });

  return {
    count: domain.length,
    data: domain,
  };
};

export default listStudentsFromClasseUseCase;
