type TStorageKeys =
  | "@SHARPII:refreshToken"
  | "@SHARPII:accessToken"
  | "@SHARPII:currentCompany";

export default class Storage {
  static set = (data: string, key: TStorageKeys) => {
    localStorage.setItem(key, data);
  };

  static get = (key: TStorageKeys) => localStorage.getItem(key);

  static clear = () => {
    localStorage.clear();
  };
}
