import React, { useCallback } from "react";

import {
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  IconButton,
  Radio,
} from "@mui/material";
import { images } from "@app/assets";
import { FiEdit } from "react-icons/fi";
import ICompanyModel from "@app/features/Company/domain/models/ICompanyModel";
import { useSelector } from "react-redux";
import { RootState } from "@app/config/store";

type CardCompanyProps = {
  selected: boolean;
  company: ICompanyModel;
  onSelectItem: (company: ICompanyModel) => void;
  onClickEdit: (company: ICompanyModel) => void;
};

const CardCompany = ({
  company,
  selected,
  onSelectItem,
  onClickEdit,
}: CardCompanyProps) => {
  const { currentUser } = useSelector((state: RootState) => state.profile);

  const handleSelectItem = useCallback(() => {
    onSelectItem(company);
  }, [company, onSelectItem]);

  const handleEditItem = useCallback(() => {
    onClickEdit(company);
  }, [company, onClickEdit]);

  return (
    <nav>
      <Divider />
      <ListItem>
        <Radio
          checked={selected}
          onChange={handleSelectItem}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
        <ListItemAvatar>
          <img
            src={company.companyImage ?? images.logoBackground}
            alt="companyImage"
            style={{ height: 45, width: 45, borderRadius: 10 }}
          />
        </ListItemAvatar>
        <ListItemText
          onClick={handleSelectItem}
          primary={company.companyName}
          secondary={company.document || company.user?.document}
        />
        <Box sx={{ ml: 2 }}>
          {company.user?.id === currentUser?.id && (
            <IconButton aria-label="settings" onClick={handleEditItem}>
              <FiEdit />
            </IconButton>
          )}
        </Box>
      </ListItem>
    </nav>
  );
};

export default CardCompany;
