import IRoleEntity from "@app/features/Role/data/IRoleEntity";
import api from "@app/config/api";
import API_SERVICES from "../constants";

export interface IRoleService {
  getRolesService: () => Promise<IRoleEntity[]>;
}

const getRolesService = async (): Promise<IRoleEntity[]> =>
  api.get(API_SERVICES.ROLES_SERVICES.LIST).then((res) => res.data);

const roleService: IRoleService = {
  getRolesService,
};

export default roleService;
