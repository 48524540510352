import { IModuleRepository } from "@app/features/Module/data/moduleRepository";

import IModuleModel from "@app/features/Module/domain/models/IModuleModel";
import IModuleEntity from "@app/features/Module/data/IModuleEntity";

const getModulesUseCase = async (
  repository: Pick<IModuleRepository, "getModules">,
  classeId: number
) => {
  const modulesEntity = await repository.getModules(classeId);
  const domain = mapToDomain(modulesEntity);

  return domain;
};

const mapToDomain = (modulesEntity: IModuleEntity[]): IModuleModel[] => {
  const domain = modulesEntity.map((module): IModuleModel => {
    const { id, name, position } = module;

    return {
      id,
      name,
      position,
    };
  });

  return domain;
};

export default getModulesUseCase;
