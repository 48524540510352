import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import createLessonUseCase from "@app/features/Lesson/domain/use-cases/createLessonUseCase";
import { ILessonRepository } from "@app/features/Lesson/data/lessonRepository";

import { TQuestion } from "@app/features/Question/view/Form";

import showNotification from "@app/components/molecules/Toast";

import handleApplicationError from "@app/handlers/handleApiError";
import IResourceModel from "@app/features/Resource/domain/models/IResourceModel";
import { RELEASE_DATE_TYPE } from "@app/constants/enums";

export type TCreateLessonViewModel = {
  image?: string;
  name: string;
  description?: string;
  resourceMedia?: IResourceModel;
  complementaryMaterials: IResourceModel[];
  publish: boolean;
  minScore: boolean;
  minScoreValue: number;
  canRetake: boolean;
  showResult: boolean;
  randomQuestions: boolean;
  randomAnswers: boolean;
  releaseDateType?: RELEASE_DATE_TYPE;
  releaseAfterDay?: number;
  releaseDate?: string;
  questions?: TQuestion[];
  moduleId: number;
  classeId: number;
};

const useCreateLessonViewModel = (repository: ILessonRepository) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const createLesson = useCallback(
    async (data: TCreateLessonViewModel) => {
      try {
        setLoading(true);

        await createLessonUseCase(
          {
            createLesson: repository.createLesson,
          },
          data
        );

        setLoading(false);
        showNotification("Aula criada com sucesso", "SUCCESS");
        navigate(-1);
      } catch (error) {
        setLoading(false);
        handleApplicationError.handleError(error);
      }
    },
    [repository.createLesson, navigate]
  );

  return { isLoading, createLesson };
};

export { useCreateLessonViewModel };
